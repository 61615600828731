import React from "react";
import "./SmartHelp.scss";
import { translations } from "../../../../translations/texts";
import { useSelector } from "react-redux";

const SmartHelp = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  return (
    <div data-testid="container-help" className="nomad-section-container" id="helpCenter">
      <section>
        <h1 className="nomad-section-title">
          {translations[selectedLanguage].helpCenter.title}
        </h1>
        <p>{translations[selectedLanguage].common.comingSoon}</p>
      </section>
    </div>
  );
};

export default SmartHelp;
