import axios from "axios";
import { errorHandler } from "../utilities/errors";
import { getHeader } from "../utilities/token";

const products = {
  /**
   * List all possible subscription methods (1 month/3 month/...)
   * @returns Object
   */
  getAllSubscriptions: async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/payment/getsubscriptions`,
        getHeader()
      )
      .catch(errorHandler);
  },
};

export default products;
