import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import React from "react";

const SubFAQSection = ({ question, answer }) => {
  return (
    <section data-testid="container-faq-section" className="accordion-wrapper">
      <Accordion className="accordion-inner-wrapper">
        <div className="faq-section-question">
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <div>{question}</div>

            <div className="icons">
              <span role="button" className="open">
                &#x2B;
              </span>
              <span role="button" className="close">
                &times;
              </span>
            </div>
          </AccordionSummary>
        </div>
        <AccordionDetails className="accordian-details">
          <p className="faq-section-answer">{answer}</p>
        </AccordionDetails>
      </Accordion>
    </section>
  );
};

export default SubFAQSection;
