import React from "react";
import Hls from "hls.js";
import { isOnChromium, isOniOS, isOnMobile } from "../../../utilities/device";
import "./HlsContent.scss";
import last from "lodash/last";
import { useEffect } from "react";

const SubHlsContent = ({ videoSrc }) => {
  useEffect(() => {
    initialize(videoSrc, document.getElementById("video"));
  }, []);

  const initialize = (src, element) => {
    if ((isOnChromium() && isOnMobile()) || isOniOS()) {
      loadNewVideoIOS(src, element);
    } else {
      loadNewVideo(src, element);
    }
  };

  const loadNewVideoIOS = (src, element) => {
    if (!document.getElementById("iOSVideoSourceTag")) {
      const source = document.createElement("source");

      source.setAttribute("src", src);
      source.setAttribute("type", "application/x-mpegURL");
      source.setAttribute("id", "iOSVideoSourceTag");
      element.appendChild(source);
      element.play();
    } else {
      element.src = src;
      element.play();
    }
  };

  const loadNewVideo = (src, element) => {
    const hls = new Hls();

    hls.on(Hls.Events.ERROR, (_event, data) => {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            hls.recoverMediaError();
            break;
          default:
            hls.destroy();
            break;
        }
      } else if (
        data.details === "internalException" &&
        data.type === "otherError" &&
        isOnMobile()
      ) {
        // fallback, remove 4k from levels since some Android devices cannot support it with Chrome/Edge
        const level = last(this.getLevels());
        hls.removeLevel(level?.level, level?.urlId);
        hls.startLoad();
      }
    });

    hls.loadSource(src);
    hls.attachMedia(element);
    hls.media.play();
  };

  return (
    <section data-testid="container-hls-content" className="hls-content">
      <video preload="metadata" controls id="video" />
    </section>
  );
};

export default SubHlsContent;
