import axios from "axios";
import { errorHandler } from "../utilities/errors";
import { getHeader } from "../utilities/token";

const account = {
  activate: async (token) => {
    const fetchUrl = `${process.env.REACT_APP_SERVER_HOST}/account/activation?accountToken=${token}`;
    return await axios.get(fetchUrl, getHeader()).catch(errorHandler);
  },

  getProfile: async (token) => {
    const fetchUrl = `${process.env.REACT_APP_SERVER_HOST}/account/getprofile`;

    return await axios.get(fetchUrl, getHeader(token)).catch(errorHandler);
  },


  updateProfile: async (userDataObj) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/account/updateprofile/`,
        {
          email: userDataObj.email,
          lastName: userDataObj.lastName,
          firstName: userDataObj.firstName,
          dateOfBirth: userDataObj.dateOfBirth,
          gender: userDataObj.gender,
        },
        getHeader(userDataObj.token)
      )
      .catch(errorHandler);
  },

  login: async (email, password) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/account/login`,
        {
          email,
          password,
        },
        getHeader()
      )
      .catch(errorHandler);
  },

  googleLogin: async (email, idtoken, name) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/account/googlesignin`,
        {
          email,
          idtoken,
          name
        },
        getHeader()
      )
      .catch(errorHandler);
  },

  signup: async (userDataObj) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/account/signup`,
        userDataObj,
        getHeader()
      )
      .catch(errorHandler);
  },

  checkEmail: async (emailInput) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/account/checkemail/`,
        {
          email: emailInput,
        },
        getHeader()
      )
      .catch(errorHandler);
  },

  /**
   * Not used yet but will be
   * @param {*} newsLetterInput
   * @returns Promise
   */
  newsLetter: async (newsLetterInput) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/newsletter/addemail`,
        {
          newsletter: newsLetterInput,
        },
        getHeader()
      )
      .catch(errorHandler);
  },

  passwordReset: async (email) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/account/sendemailforpwdreset`,
        {
          email,
        },
        getHeader()
      )
      .catch(errorHandler);
  },

  tokenCheck: async (token) => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/account/checktoken`,
        getHeader(token)
      )
      .catch(errorHandler);
  },

  cognitoLogin: async (code) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/account/cognitosignin`,
        {
          code,
        },
        getHeader()
      )
      .catch(errorHandler);
  },

  resetPassword: async (userId, password, token) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/account/resetpassword`,
        {
          userId,
          password,
          token,
        },
        getHeader()
      )
      .catch(errorHandler);
  },

  verifyPasswordToken: async (token) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/account/new-password`,
        {
          token,
        },
        getHeader()
      )
      .catch(errorHandler);
  },

  deleteUserData: async (token) => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/account/deleteuserdata`,
        getHeader(token)
      )
      .catch(errorHandler);
  },

  unlink: async (token, platform) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/account/unlinksmaccount`,
        {
          platform,
        },
        getHeader(token)
      )
      .catch(errorHandler);
  },
  
  getUserSubscription: async (token = getToken()) => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/purchase/getusersubscription`,
        getHeader(token)
      )
      .catch(errorHandler);
  },

};

export default account;
