import axios from "axios";
import { errorHandler } from "../utilities/errors";
import { getHeader } from "../utilities/token";

const episodes = {};

episodes.getAllEpisodes = async () => {
  return await axios
    .get(`${process.env.REACT_APP_SERVER_HOST}/videos/getepisodes`, getHeader())
    .catch(errorHandler);
};

episodes.getUserRentals = async (token) => {
  return await axios
    .get(
      `${process.env.REACT_APP_SERVER_HOST}/videos/getuservalidrentals`,
      getHeader(token)
    )
    .catch(errorHandler);
};

episodes.getUserRentalsHistory = async (token) => {
  return await axios
    .get(
      `${process.env.REACT_APP_SERVER_HOST}/videos/getuserrentals`,
      getHeader(token)
    )
    .catch(errorHandler);
};

export default episodes;
