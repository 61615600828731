export const faq = {
  section1: {
    question: "What is NOMADS?",
    answer:
      "NOMADS is a streaming service for VR (Virtual Reality) content. You can rent VR content or subscribe for unlimited access to everything NOMADS has to offer.",
  },
  section2: {
    question: "Where can I watch NOMADS content?",
    answer:
      "Anywhere you can imagine! NOMADS content can be accessed from any device with a compatible internet browser. Unwind on a beautiful beach from your airplane seat, explore a fiery volcano from the comfort of your home, or take a lunch break to swim with sharks at the office.",
  },
  section3: {
    question: "How can I experience my content in VR (Virtual Reality)?",
    answer:
      "NOMADS is compatible with any VR Headset. To stream your content, visit nomads.live from any compatible browser.",
  },
  section4: {
    question: "What can I find on NOMADS?",
    answer:
      "NOMADS has an ever-expanding library of travel, adventure, concerts, live events, sports, and so much more. New content is added regularly so check back often!",
  },
  section5: {
    question: "Will my internet speed affect my experience?",
    answer:
      "For the best possible NOMADS experience, we recommend a download speed of 20Mb/s, however NOMADS is designed to scale to a wide variety of devices and internet connection speeds.",
  },
};
