import React from "react";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes";
import { translations } from "../../translations/texts";
import SubButton from "../Common/Button/SubButton";
import { useNavigate } from "react-router-dom";
import "./SmartPage404.scss";
import { useSelector } from "react-redux";


const SmartPage404 = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  
  const navigate = useNavigate();

  const goToHomepage = () => {
    navigate(INTERNAL_ROUTES.HOME);
  };

  return (
    <section data-testid="container-page-404" className="form-container">
      <div className="form-card page-404">
        <h1>404</h1>
        <h4>{translations[selectedLanguage].page404.nothingHere}</h4>
        <SubButton
          onClick={goToHomepage}
          label={translations[selectedLanguage].common.homepagebutton}
        />
      </div>
    </section>
  );
};

export default SmartPage404;
