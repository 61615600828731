import React from "react";
import { EXTERNAL_URLS } from "../../../constants/constants";
import { formatString, getLang } from "../../../translations/texts";
import { translations } from "../../../translations/texts";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "./SubCreditCardDisplay.scss";
import creditCardType from "credit-card-type";

const SubCreditCardDisplay = ({ cardObj }) => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  const getCardTypeStr = (number) => {
    const cardType = creditCardType(number)[0];
    return cardType ? cardType.type : translations[selectedLanguage].creditCardDisplay.unknownCardType;
  };


  const getCardTypeIconSrc = (type) => {
    switch (type) {
      case 'visa':
        return EXTERNAL_URLS.VISA_CARD_IMAGE;
      case 'mastercard':
        return EXTERNAL_URLS.MASTER_CARD_IMAGE;
      default:
        return translations[selectedLanguage].creditCardDisplay.unknownCardType;
    }
  };

  const formatExpiry = () => {
    return `${cardObj?.expiry?.slice(0, 2)} / ${cardObj?.expiry?.slice(2)}`;
  }
  
  return (
    <div
      data-testid="container-credit-card-display"
      className="credit-cart-display"
    >
      <div>
        <img
          className="credit-card-icon"
          src={getCardTypeIconSrc(getCardTypeStr(cardObj?.maskedPan))}
          alt={translations[selectedLanguage].common.creditCardIcon}
        />
      </div>
      <div>
        <div className="credit-card-text">
          {formatString(
            translations[selectedLanguage].creditCardDisplay.cardInfo,
            cardObj?.maskedPan.slice(-4),
            // formatExpiry(cardObj?.expiry)
            formatExpiry()
          )}
        </div>
      </div>
    </div>
  );
};

export default SubCreditCardDisplay;
