import NomadsFetchRequests from "../../requests";
import { actionTypes } from "../../constants/actionTypes";
import store from "../store.js";
import { getToken } from "../../utilities/cookie";

export const getStoreData = async () => {
  return new Promise(async (resolve, reject) => {
    const promises = [
      NomadsFetchRequests.categories.getCategories(),
      NomadsFetchRequests.episodes.getAllEpisodes(),
      NomadsFetchRequests.products.getAllSubscriptions(),
      NomadsFetchRequests.recommendation.getTop10Rated(),
      NomadsFetchRequests.recommendation.mostTrending(),
      NomadsFetchRequests.recommendation.recentlyAdded(),
      NomadsFetchRequests.recommendation.becauseYouLike(getToken()),
    ];

    Promise.all(promises)
      .then((result) => {
        const becauseYouLike = result[6].data;
        const categories = result[0].data.categories.concat([
          {
            bgImage: null,
            category: "Top 10 Rated",
            id: "top10rated",
            posterSrc: null,
          },
          {
            bgImage: null,
            category: "Most Trending",
            id: "mosttrending",
            posterSrc: null,
          },
          {
            bgImage: null,
            category: "Recently Added",
            id: "recentlyadded",
            posterSrc: null,
          },
          {
            bgImage: null,
            category: `Because you like ${becauseYouLike?.keyword || ""}`,
            id: "becauseyoulike",
            posterSrc: null,
          },
        ]);

        const episodes = result[1].data.list;
        const top10rated = result[3].data;
        const mostTrending = result[4].data;
        const recentlyAdded = result[5].data;

        episodes.forEach((ep) => {
          top10rated.forEach((top10rated) => {
            if (ep.id === top10rated.videoId) {
              ep.categories.push("top10rated");
            }
          });

          mostTrending.forEach((mostTrending) => {
            if (ep.id === mostTrending.videoId) {
              ep.categories.push("mosttrending");
            }
          });

          if (becauseYouLike?.keyword) {
            becauseYouLike.episodeIds.forEach((id) => {
              if (ep.id === id) {
                ep.categories.push("becauseyoulike");
              }
            });
          }

          if (Array.isArray(recentlyAdded)) {
            recentlyAdded.forEach((recentlyAdded) => {
              if (ep.id === recentlyAdded.videoId) {
                ep.categories.push("recentlyadded");
              }
            });
          }
        });


        store.dispatch({
          type: actionTypes.SET_ALL_NOMADS_DATA,
          payload: {
            categories,
            episodes,
            subscriptions: result[2].subscriptions,
          },
        });

        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};
