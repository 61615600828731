import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { MAX_NAME_LENGTH } from "../../constants/constants.js";
import { COOKIES } from "../../constants/cookies";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes.js";
import fetchRequests from "../../requests/index.js";
import { removeError, setError } from "../../store/actions/errors.js";
import { signOut, updateUser } from "../../store/actions/user";
import { deleteCookie, getCookie, getToken } from "../../utilities/cookie";
import { validName } from "../../validation/validations";
import SubButton from "../Common/Button/SubButton";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading";
import SubLoadingButton from "../Common/Loading/LoadingButton/SubLoadingButton";
import SubUpdateProfileForm from "./UpdateProfileForm/SubUpdateProfileForm";
import { translations } from "../../translations/texts.js";
import "react-datepicker/dist/react-datepicker.css";
import "./SmartUserProfile.scss";
import CONFIG from "../../config.js";
import { useState } from "react";
import { useEffect } from "react";

const SmartUserProfile = () => {
	const [firstName, setFirstName] = useState("");
	const [firstNameError, setFirstNameError] = useState("");
	const [lastName, setLastName] = useState("");
	const [lastNameError, setLastNameError] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [dateOfBirthError, setDateOfBirthError] = useState("");
	const [gender, setGender] = useState("");
	const [email, setEmail] = useState("");
	const [editMode, setEditMode] = useState(false);
	const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
	const [componentLoading, setComponentLoading] = useState(true);
	const [changePasswordLoading, setChangePasswordLoading] = useState(false);
	const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
	const [removeAccountLoading, setRemoveAccountLoading] = useState(false);
	// const [unlinkFacebookLoading, setUnlinkFacebookLoading] = useState(false);
	const [unlinkGoogleLoading, setUnlinkGoogleLoading] = useState(false);

	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const user = useSelector((state) => state.user.userData);
	const isLoggedIn = useSelector((state) => !!state.user.userData.id);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		setUserInfo()
			.catch((error) => {
				dispatch(setError(error));
			})
			.finally(() => {
				setComponentLoading(false);
			});

		return () => {
			dispatch(removeError);
		};
	}, []);

	const dateOfBirthHandler = (e) => {
		setDateOfBirth(e || "");
	};

	const showEditProfile = () => {
		setEditMode(true);
	};

	const setUserInfo = () => {
		return new Promise((resolve, reject) => {
			fetchRequests.account
				.getProfile(getCookie(COOKIES.TOKEN))
				.then((response) => {
					let dateOfBirth;

					if (response.data.dateofbirth && response.data.dateofbirth !== "None") {
						const currentDateOfBirthArr = response.data.dateofbirth.split("-");

						dateOfBirth = new Date(currentDateOfBirthArr[0], parseInt(currentDateOfBirthArr[1]) - 1, currentDateOfBirthArr[2]);
					}

					const result = {
						firstName: response.data.firstName,
						lastName: response.data.lastName,
						email: response.data.email,
						dateOfBirth,
						gender: response.data.gender,
						id: response.data.id,
					};

					dispatch(updateUser(result));

					setFirstName(response.data.firstName);
					setLastName(response.data.lastName);
					setEmail(response.data.email);
					setDateOfBirth(dateOfBirth);
					setGender(response.data.gender);

					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const validateFirstName = () => {
		let valid = true;

		if (!firstName) {
			setFirstNameError(translations[selectedLanguage].common.required);
			valid = false;
		} else if (!validName(firstName)) {
			setFirstNameError(translations[selectedLanguage].common.noNumbers);
			valid = false;
		} else if (firstName.length > MAX_NAME_LENGTH) {
			setFirstNameError(translations[selectedLanguage].common.tooLong);
			valid = false;
		}

		return valid;
	};

	const validateLastName = () => {
		let valid = true;

		if (!lastName) {
			setLastNameError(translations[selectedLanguage].common.required);
			valid = false;
		} else if (!validName(lastName)) {
			setLastNameError(translations[selectedLanguage].common.noNumbers);
			valid = false;
		} else if (lastName.length > MAX_NAME_LENGTH) {
			setLastNameError(translations[selectedLanguage].common.tooLong);
			valid = false;
		}

		return valid;
	};

	const validate = () => {
		const valid1 = validateFirstName();

		if (valid1) {
			setFirstNameError("");
		}

		const valid2 = validateLastName();

		if (valid2) {
			setLastNameError("");
		}

		return valid1 && valid2;
	};

	const submitHandler = async (e) => {
		e.preventDefault();

		const isValid = validate();

		if (isValid) {
			setUpdateProfileLoading(true);
			fetchRequests.account
				.updateProfile({
					lastName,
					firstName,
					dateOfBirth,
					gender,
					token: getCookie(COOKIES.TOKEN),
				})
				.then(async () => {
					setUpdateProfileLoading(false);
					setComponentLoading(true);

					try {
						await setUserInfo();
						setComponentLoading(false);
						setEditMode(false);
					} catch (error) {
						dispatch(setError(error));
					}
				})
				.catch((error) => {
					setUpdateProfileLoading(false);
					dispatch(setError(error));
				});
		}
	};

	const requestChangeOfPassword = () => {
		setChangePasswordLoading(true);
		const host = window.location.host;

		fetchRequests.account
			.passwordReset(user.email, host)
			.then(() => {
				setChangePasswordSuccess(true);
			})
			.catch((error) => {
				dispatch(setError(error));
			})
			.finally(() => {
				setChangePasswordLoading(false);
			});
	};

	const goToHomepage = () => {
		navigate(INTERNAL_ROUTES.HOME);
	};

	const requestRemoveAccount = () => {
        if (confirm(translations[selectedLanguage].userProfile.deleteConfirm) && confirm(translations[selectedLanguage].userProfile.deleteConfirm2)) {
            setRemoveAccountLoading(true);
            fetchRequests.account
                .deleteUserData(getToken())
                .then(() => {
                    deleteCookie(COOKIES.TOKEN, getToken());
                    dispatch(signOut);
                    goToHomepage();
                })
                .catch((error) => {
                    dispatch(setError(error));
                    setRemoveAccountLoading(false);
                });
        }
    };


	// const unlinkFacebook = () => {
	// 	if (confirm(translations[selectedLanguage].userProfile.unlinkFacebookConfirm)) {
	// 		setUnlinkFacebookLoading(true);
	// 		fetchRequests.account
	// 			.unlink(getToken(), "Facebook")
	// 			.then(() => {
	// 				deleteCookie(COOKIES.TOKEN, getToken());
	// 				dispatch(signOut);
	// 				goToHomepage();
	// 			})
	// 			.catch((error) => {
	// 				dispatch(setError(error));
	// 				setUnlinkFacebookLoading(false);
	// 			});
	// 	}
	// };

	const unlinkGoogle = () => {
		if (confirm(translations[selectedLanguage].userProfile.unlinkGoogleConfirm)) {
			setUnlinkGoogleLoading(true);
			fetchRequests.account
				.unlink(getToken(), "Google")
				.then(() => {
					deleteCookie(COOKIES.TOKEN, getToken());
					dispatch(signOut);
					goToHomepage();
				})
				.catch((error) => {
					dispatch(setError(error));
					setUnlinkGoogleLoading(false);
				});
		}
	};

	const renderProfile = () => {
		const currentGender = user.gender || "N/A";

		if (!editMode) {
			return (
				<div className="userprofile-current-profile" id="profile">
					<div className="userprofile-wrap-info">
						<div className="userprofile-card-item userprofile-fullname">{`${user.firstName} ${user.lastName}`}</div>
						<div className="userprofile-card-item">{`${translations[selectedLanguage].common.email}: ${user.email}`}</div>
						{user.dateOfBirth && (
							<div className="userprofile-card-item">
								{`${translations[selectedLanguage].common.dateOfBirth}: ${JSON.parse(user.dateOfBirth)?.substring(0, 10)}`}
							</div>
						)}
						<div className="userprofile-card-item">{`${translations[selectedLanguage].common.gender}: ${currentGender}`}</div>
					</div>
					{CONFIG.profile.enableEditProfile && (
						<SubButton className="userprofile-edit-button" onClick={showEditProfile} label={translations[selectedLanguage].common.edit} />
					)}
					{CONFIG.profile.enableChangePassword && (
						<SubLoadingButton
							isLoading={changePasswordLoading}
							onClick={requestChangeOfPassword}
							label={translations[selectedLanguage].userProfile.changePassword}
						/>
					)}
					<div className="userprofile-change-password-label">
						{changePasswordSuccess && (
							<p className="userprofile-change-password-success">{translations[selectedLanguage].userProfile.changePasswordSuccess}</p>
						)}
					</div>
					<hr className="userprofile-hr" />
					{CONFIG.profile.enableRemoveAccount && (
						<SubLoadingButton
							isLoading={removeAccountLoading}
							onClick={requestRemoveAccount}
							label={translations[selectedLanguage].userProfile.removeAccount}
						/>
					)}
					<div className="userprofile-wrap-unlink-buttons">
						{/* <div className="userprofile-unlink">
							{CONFIG.profile.enableUnlinkFacebook && (
								<SubLoadingButton
									isLoading={unlinkFacebookLoading}
									onClick={unlinkFacebook}
									label={translations[selectedLanguage].userProfile.unlinkFacebook}
								/>
							)}
						</div>
						 */}
						{/* <div className="userprofile-unlink userprofile-unlink-google"> */}
						{CONFIG.profile.enableUnlinkGoogle && (
							<SubLoadingButton
								isLoading={unlinkGoogleLoading}
								onClick={unlinkGoogle}
								label={translations[selectedLanguage].userProfile.unlinkGoogle}
							/>
						)}
						{/* </div> */}
					</div>
				</div>
			);
		}
	};

	const cancelHandler = () => {
		setEditMode(false);
	};

	const firstNameChange = (e) => {
		setFirstName(e.target.value);
	};

	const lastNameChange = (e) => {
		setLastName(e.target.value);
	};

	const genderChange = (e) => {
		setGender(e.target.value);
	};

	const renderEditProfile = () => {
		if (editMode) {
			return (
				<SubUpdateProfileForm
					submitHandler={submitHandler}
					dateOfBirthHandler={dateOfBirthHandler}
					firstName={firstName}
					firstNameChange={firstNameChange}
					lastName={lastName}
					lastNameChange={lastNameChange}
					dateOfBirth={dateOfBirth}
					gender={gender}
					genderChange={genderChange}
					updateProfileLoading={updateProfileLoading}
					cancelHandler={cancelHandler}
					firstNameError={firstNameError}
					lastNameError={lastNameError}
					dateOfBirthError={dateOfBirthError}
				/>
			);
		}
	};

	const renderContent = () => {
		return (
			<>
				<div className="user-profile-section">
					<h2>{translations[selectedLanguage].common.sidebarprofile}</h2>
					{renderProfile()}
					{renderEditProfile()}
				</div>
			</>
		);
	};

	if (!isLoggedIn) {
		return <Navigate to={INTERNAL_ROUTES.HOME} replace />;
	}

	return (
		<section data-testid="container-user-profile" className="userprofile-container">
			<div className="userprofile-card">
				{componentLoading && <SubComponentLoading />}
				{!componentLoading && renderContent()}
			</div>
		</section>
	);
};

export default SmartUserProfile;
