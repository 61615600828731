import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ASSETS_PATH, CURRENCY_CODES } from "../../../../constants/constants";
import { INTERNAL_ROUTES } from "../../../../constants/internalRoutes";
import { addCartItem, removeCartItem } from "../../../../store/actions/cart.js";
import IconCross from "../../../../Svg/IconCross";
import { FaPlay } from "react-icons/fa";
import { translations } from "../../../../translations/texts";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./SubSelectedTileContent.scss";
import SubButton from "../../../Common/Button/SubButton";
import SubHlsContent from "../../HlsContent/HlsContent";
import CONFIG from "../../../../config";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLocaleProp } from "../../../../utilities/strings";
import { getSubsriptionData } from "../../../../store/actions/subscription";
import { removeError, setError } from "../../../../store/actions/errors";


const SubSelectedTileContent = ({ productObj, setShowSelectedTileContent }) => {
	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const cart = useSelector((state) => state.cart);
	const subscriptions = useSelector((state) => state.nomadsDb.subscriptions);
	const user = useSelector((state) => state.user.userData);

	const dispatch = useDispatch();
	const navigate = useNavigate();


	useEffect(() => {
		Promise.all([getSubsriptionData()])
			.catch((error) => {
				dispatch(setError(error));
			})
			.finally(() => {
				// setComponentLoading(false);
			});

		return () => {
			dispatch(removeError);
		};
	}, []);

	const [showModal, setShowModal] = useState(false);

	const renderAddOrRemoveBtn = (product) => {
		if (cart.products?.some((productObj) => productObj.id === product.id) === false) {
			return (
				<SubButton
					label={translations[selectedLanguage].selectedTileContent.addToCart.toUpperCase()}
					className="cart_btns"
					onClick={() => dispatch(addCartItem(product))}
				/>
			);
		} else {
			return (
				<SubButton
					label={translations[selectedLanguage].selectedTileContent.removeFromCart.toUpperCase()}
					className="cart_btns"
					onClick={() => dispatch(removeCartItem(product))}
				/>
			);
		}
	};

	const openPreviewModal = () => {
		setShowModal(true);
	};

	const getSubLabel = () => {
		return (
			<>
				<p className="selected-tile-content-sub-label">
					{user.isSubscribed ? translations[selectedLanguage].common.subscribed : translations[selectedLanguage].common.subscribe}
				</p>
				{user.isSubscribed ? (
					<img
						className="selected-tile-content-checkmark-img"
						src={ASSETS_PATH.CAROUSEL.CHECKMARK}
						alt={translations[selectedLanguage].common.checkmark}
					/>
				) : null}
			</>
		);
	};

	const renderSelectedTileContent = () => {
    const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  
		const product = { ...productObj };
		product.productId = product.episodeId;

		if (!product) {
			return null;
		} else {
			return (
				<div className="selected_tile_content_wrapper">
					<div className="column">
						<h2 className="product_name">{product[getLocaleProp("name")]}</h2>
						<div className="product_description">{product.description || product[getLocaleProp("introduction")]}</div>
						<div className="selected-tile-content-wrap-watch-preview">
							{CONFIG.store.enableWatchPreview && (
								<SubButton
									onClick={openPreviewModal}
									label={
										<div className="selected-tile-content-watch-preview">
											<FaPlay className="selected-tile-content-play-icon" />
											{translations[selectedLanguage].selectedTileContent.watchPreview}
										</div>
									}
								/>
							)}
						</div>
						<div className="product_description">
							{`${translations[selectedLanguage].selectedTileContent.watchLength}: ${product.duration} ${translations[selectedLanguage].common.minutes}`}
						</div>
						<div className="product_description">{`${translations[selectedLanguage].common.price}: $${product.price} ${CURRENCY_CODES.USD}`}</div>
						<div className="product_description">{translations[selectedLanguage].selectedTileContent.rentalPeriod}</div>
						<div>{CONFIG.store.enableAddingAndRemovingItemsInCart && renderAddOrRemoveBtn(product)}</div>
					</div>

					<div className="column ">
						<div className="product_or_text">-&nbsp;{translations[selectedLanguage].common.or}&nbsp;-</div>
					</div>
					<div className="column subscribe_area">
						<div className="selected-tile-content-wrap-info">
							<SubButton
								isDisabled={user.isSubscribed}
								onClick={() => {
									navigate(INTERNAL_ROUTES.USER.SUBSCRIBE);
								}}
								label={getSubLabel()}
								className="background-subscribed"
							/>
							<div className="content__description">
								{user.isSubscribed
									? translations[selectedLanguage].selectedTileContent.alreadySubscribed
									: `${translations[selectedLanguage].selectedTileContent.forFullLib} ($${
											subscriptions.length ? subscriptions[0].price : null
									} ${CURRENCY_CODES.USD} ${translations[selectedLanguage].common.monthly})`}
							</div>
						</div>
					</div>
					<div>
						<div
							className="close_btn"
							onClick={() => {
								setShowSelectedTileContent(false);
							}}
						>
							X
						</div>
					</div>
				</div>
			);
		}
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const showModalFunc = () => {
		return (
			<div className="user-profile-modal-shadow-cloak">
				<div className="watch-preview-content">
					<div onClick={closeModal} className="watch-preview-modal-close-btn">
						<IconCross />
					</div>
					<SubHlsContent videoSrc="https://dggouis4luqok.cloudfront.net/converted-video-assets/andy_dec2020/Andy_fr_full_008.m3u8" />
				</div>
			</div>
		);
	};

	return (
		<div data-testid="container-selected-tile-content" className="selected-tile-container-wrap">
			{renderSelectedTileContent()}
			{showModal ? showModalFunc() : ""}
		</div>
	);
};

export default SubSelectedTileContent;
