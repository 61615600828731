import axios from "axios";
import { getToken } from "../../utilities/cookie.js";
import { errorHandler } from "../../utilities/errors.js";
import { getHeader } from "../../utilities/token.js";

const profile = {
  getSingleProfile: async (token, profileCode) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/payment/getprofile`,
        JSON.stringify({
          profileCode,
        }),
        getHeader(token)
      )
      .catch(errorHandler);
  },
  updateProfile: async (token, userprofile) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/payment/updateprofile`,
        JSON.stringify(userprofile),
        getHeader(token)
      )
      .catch(errorHandler);
  },
  getAllProfiles: async (token = getToken()) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/payment/getallprofiles`,
        JSON.stringify({ currencyCode: "USD" }),
        getHeader(token)
      )
      .catch(errorHandler);
  },

  deleteProfile: async (profileCode, token = getToken()) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/payment/deleteprofile`,
        JSON.stringify({ profileCode }),
        getHeader(token)
      )
      .catch(errorHandler);
  },

  createProfile: async (userProfile) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/payment/createprofile`,
        JSON.stringify(userProfile),
        getHeader(userProfile.session)
      )
      .catch(errorHandler);
  },
};

export default profile;
