import { getStoreState } from "../../index.js";

export const getEpisodesInCategory = (categoryId) => {
  const episodes = getStoreState().nomadsDb.episodes;

  const episodesInCategoryArr = episodes.filter((episodeObj) => {
    return episodeObj.categories.includes(categoryId);
  });

  return episodesInCategoryArr;
};
