import { COOKIES } from "../constants/cookies";


export function setGDPRCookie(name, value, days = 14, extended = false) {
  let expires = "";
  let domain = "";

  if (window.location.protocol.includes("https")) {
    domain = `domain=.${window.location.host};secure;`;
  }

  if (days) {
    if (extended) {
      days *= 10;
    }

    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toUTCString()};`;
  }

  document.cookie = `${name}=${value};${domain}${expires}path=/`;
}

export function getGDPRCookie(name) {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return "";
}


export function setCookie(name, value, days = 14, extended = false) {
  let expires = "";
  let domain = "";

  if (window.location.protocol.includes("https")) {
    domain = `domain=.${window.location.host};secure;`;
  }

  if (days) {
    if (extended) {
      days *= 10;
    }

    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toUTCString()};`;
  }

  document.cookie = `${name}=${value};${domain}${expires}path=/`;
}

export function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
}

export const deleteCookie = (name, value) => {
  let domain = "";

  if (window.location.protocol.includes("https")) {
    domain = `domain=.${window.location.host};secure;`;
  }

  document.cookie = `${name}=${value};${domain}expires=Thu, 01, Jan 1970 00:00:01 GMT;`;
};

export const getToken = () => getCookie(COOKIES.TOKEN) || "";

export const getPurchaseToken = () => getCookie(COOKIES.PURCHASETOKEN) || "";

