import React from "react";
import SmartCart from "../components/Cart/SmartCart.jsx";
import SmartHomepage from "../components/Homepage/SmartHomepage.jsx";
import SmartRegister from "../components/Register/SmartRegister.jsx";
import SmartUserProfile from "../components/UserProfile/SmartUserProfile.jsx";
import SmartRedeemExperience from "../components/RedeemExperience/SmartRedeemExperience.jsx";
import SmartPaymentProfile from "../components/PaymentProfile/SmartPaymentProfile.jsx";
import SmartFAQ from "../components/Footer/FooterLinks/FAQ/SmartFAQ.jsx";
import SmartTerms from "../components/Footer/FooterLinks/Terms/SmartTerms.jsx";
import SmartLogin from "../components/Login/SmartLogin.jsx";
import SmartActivate from "../components/Activate/SmartActivate.jsx";
import SmartLegalNotices from "../components/Footer/FooterLinks/LegalNotices/SmartLegalNotices.jsx";
import SmartJobs from "../components/Footer/FooterLinks/Jobs/SmartJobs.jsx";
import SmartHelpCenter from "../components/Footer/FooterLinks/Help/SmartHelp.jsx";
import SmartContact from "../components/Footer/FooterLinks/Contact/SmartContact.jsx";
import SmartCreditCardInformation from "../components/CreditCardInformation/SmartCreditCardInformation.jsx";
import SmartManagePaymentMethods from "../components/ManagePaymentMethods/SmartManagePaymentMethods.jsx";
import SmartForgotPassword from "../components/ForgotPassword/SmartForgotPassword.jsx";
import SmartWebStore from "../components/WebStore/SmartWebStore.jsx";
import SmartPrivacy from "../components/Footer/FooterLinks/Privacy/SmartPrivacy.jsx";
import SmartNewPassword from "../components/NewPassword/NewPassword.jsx";
import { Route, Routes } from "react-router-dom";
import { INTERNAL_ROUTES } from "../constants/internalRoutes.js";
import SmartSubscriptionDashboard from "../components/SubscriptionDashboard/SmartSubscriptionDashboard.jsx";
import SmartSubscribe from "../components/Subscribe/SmartSubscribe.jsx";
import SmartRentalsDashboard from "../components/RentalsDashboard/SmartRentalsDashboard.jsx";
import SmartPage404 from "../components/404/SmartPage404.jsx";
import CONFIG from "../config.js";
import { translations } from "../translations/texts.js";


const SubRoutes = () => {
  /**
   * Routes that you need to be logged in to see
   * @returns JSX
   */
  const protectedRoutes = () => {
    return (
      <>
        {managePaymentMethodsRoutes()}
        {CONFIG.enableCart && (
          <Route
            exact
            path={INTERNAL_ROUTES.USER.CART}
            element={<SmartCart  />}
          />
        )}
        {CONFIG.profile.enableUserProfile && (
          <Route
            exact
            path={INTERNAL_ROUTES.USER.PROFILE}
            element={<SmartUserProfile  />}
          />
        )}
        {CONFIG.enableRedeem && (
          <Route
            exact
            path={INTERNAL_ROUTES.REDEEM}
            element={<SmartRedeemExperience  />}
          />
        )}
        {CONFIG.enableSubscriptionDashboard && (
          <Route
            exact
            path={INTERNAL_ROUTES.USER.SUBSCRIPTION_DASHBOARD}
            element={<SmartSubscriptionDashboard  />}
          />
        )}
        {CONFIG.enableSubscription && (
          <Route
            exact
            path={INTERNAL_ROUTES.USER.SUBSCRIBE}
            element={<SmartSubscribe  />}
          />
        )}
        {CONFIG.enableRentalDashboard && (
          <Route
            exact
            path={INTERNAL_ROUTES.USER.RENTALS_DASHBOARD}
            element={<SmartRentalsDashboard  />}
          />
        )}
      </>
    );
  };

  const managePaymentMethodsRoutes = () => {
    return (
      <>
        {CONFIG.enableManagePaymentMethods && (
          <Route
            exact
            path={INTERNAL_ROUTES.PAYMENT.MANAGE_PAYMENT_METHODS}
            element={<SmartManagePaymentMethods  />}
          />
        )}
        {CONFIG.enablePaymentProfile && (
          <Route
            exact
            path={INTERNAL_ROUTES.PAYMENT.ADD_PAYMENT_PROFILE}
            element={<SmartPaymentProfile  />}
          />
        )}
        {CONFIG.enableCreditCardInformation && (
          <Route
            exact
            path={INTERNAL_ROUTES.PAYMENT.PAYMENT}
            element={<SmartCreditCardInformation  />}
          />
        )}
        {CONFIG.enablePaymentProfile && (
          <Route
            exact
            path={INTERNAL_ROUTES.PAYMENT.EDIT_PAYMENT_PROFILE}
            element={<SmartPaymentProfile  />}
          />
        )}
      </>
    );
  };

  return (
    <Routes>
      {protectedRoutes()}
      <Route
        exact
        path={INTERNAL_ROUTES.HOME}
        element={<SmartHomepage  />}
      />
      {CONFIG.enableLogin && (
        <Route
          exact
          path={INTERNAL_ROUTES.USER.SIGN_IN}
          element={<SmartLogin  />}
        />
      )}
      {CONFIG.enableRegister && (
        <Route
          exact
          path={INTERNAL_ROUTES.USER.REGISTER}
          element={<SmartRegister  />}
        />
      )}
      {CONFIG.enableForgotPassword && (
        <Route
          exact
          path={INTERNAL_ROUTES.USER.FORGOT_PASSWORD}
          element={<SmartForgotPassword  />}
        />
      )}
      {CONFIG.enableActivation && (
        <Route
          exact
          path={INTERNAL_ROUTES.USER.ACTIVATE}
          element={<SmartActivate  />}
        />
      )}
      {CONFIG.store.enableStore && (
        <Route
          exact
          path={INTERNAL_ROUTES.WEBSTORE}
          element={<SmartWebStore  />}
        />
      )}
      {CONFIG.enableNewPassword && (
        <Route
          exact
          path={INTERNAL_ROUTES.USER.NEW_PASSWORD}
          element={<SmartNewPassword  />}
        />
      )}
      {CONFIG.footer.enablePrivacy && (
        <Route
          exact
          path={INTERNAL_ROUTES.PRIVACY}
          element={<SmartPrivacy  />}
        />
      )}
      {CONFIG.footer.enableTerms && (
        <Route
          exact
          path={INTERNAL_ROUTES.TERMS}
          element={<SmartTerms  />}
        />
      )}
      {CONFIG.footer.enableLegalNotice && (
        <Route
          exact
          path={INTERNAL_ROUTES.LEGAL_NOTICES}
          element={<SmartLegalNotices  />}
        />
      )}
      {CONFIG.footer.enableJobs && (
        <Route
          exact
          path={INTERNAL_ROUTES.JOBS}
          element={<SmartJobs  />}
        />
      )}
      {CONFIG.footer.enableHelpCenter && (
        <Route
          exact
          path={INTERNAL_ROUTES.HELP_CENTER}
          element={<SmartHelpCenter  />}
        />
      )}
      {CONFIG.footer.enableFAQ && (
        <Route
          exact
          path={INTERNAL_ROUTES.FAQ}
          element={<SmartFAQ  />}
        />
      )}
      {CONFIG.footer.enableContact && (
        <Route
          exact
          path={INTERNAL_ROUTES.CONTACT}
          element={<SmartContact  />}
        />
      )}
      <Route path="*" element={<SmartPage404  />} />
    </Routes>
  );
};

export default SubRoutes;
