import { createReducer } from "@reduxjs/toolkit";
import { actionTypes } from "../../constants/actionTypes";

const initialState = {
  quantityInCart: 0,
  cartTotal: 0,
  products: [],
};

const cartReducer = createReducer(initialState, {
  [actionTypes.ADD_CART_ITEM]: (state, action) => {
    state.quantityInCart += 1;
    state.cartTotal = action.payload.price;
    state.products = state.products.concat(action.payload);
  },
  [actionTypes.REMOVE_CART_ITEM]: (state, action) => {
    const productsArr = state.products.slice();
    const indexToBeRemoved = productsArr.findIndex(
      (p) => p.id === action.payload.id
    );

    productsArr.splice(indexToBeRemoved, 1);
    state.products = productsArr;
  },
  [actionTypes.CLEAR_CART]: (state) => {
    state.quantityInCart = 0;
    state.cartTotal = 0;
    state.products = [];

    if (localStorage.getItem("cart")) {
      localStorage.removeItem("cart");
    }
  },
  [actionTypes.ACTUALIZE_CART]: (state) => {
    const products = JSON.parse(localStorage.getItem("cart"));

    if (products) {
      state.quantityInCart = products.length;
      state.cartTotal = products.reduce(
        (previous, current) => previous.price + current.price,
        0
      );
      state.products = products;
    }
  },
});

export default cartReducer;
