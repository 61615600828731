import { actionTypes } from "../../constants/actionTypes";

export const addCartItem = (product) => {
  let cart = JSON.parse(localStorage.getItem("cart"));

  const item = {
    id: product.id,
    name: product.name,
    name_fr: product.name_fr,
    name_de: product.name_de,
    name_sp: product.name_sp,
    price: product.price,
  };

  if (!cart) {
    cart = [item];
  } else {
    cart.push(item);
  }

  localStorage.setItem("cart", JSON.stringify(cart));

  return {
    type: actionTypes.ADD_CART_ITEM,
    payload: product,
  };
};

export const removeCartItem = (product) => {
  const cart = JSON.parse(localStorage.getItem("cart"));

  if (cart) {
    const products = cart.filter((item) => item.id !== product.id);
    localStorage.setItem("cart", JSON.stringify(products));
  }

  return {
    type: actionTypes.REMOVE_CART_ITEM,
    payload: product,
  };
};

export const actualizeCart = {
  type: actionTypes.ACTUALIZE_CART,
};

export const clearCart = {
  type: actionTypes.CLEAR_CART,
};
