import { actionTypes } from "../../constants/actionTypes";


export const setPurchaseToken = (token) => {
    localStorage.setItem("purchaseToken", token);
    return {
        type: actionTypes.SET_PURCHASE_TOKEN,
        payload: token,
    };
};

export const removePurchaseToken = () => {
    localStorage.removeItem("purchaseToken");
    return {
        type: actionTypes.REMOVE_PURCHASE_TOKEN,
    };
};
