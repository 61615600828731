import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchRequests from "../../requests/index";
import { removeError, setError } from "../../store/actions/errors";
import { validEmail } from "../../validation/validations.js";
import SubTextInput from "../Common/Form/TextInput/TextInput.jsx";
import SubLoadingButton from "../Common/Loading/LoadingButton/SubLoadingButton.jsx";
import { translations } from "../../translations/texts";
import "./SmartForgotPassword.scss";

const SmartForgotPassword = () => {
  
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);

  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  const validate = () => {
    const valid = validateEmail();

    if (valid) {
      setEmailErrorMessage("");
    }

    return valid;
  };

  const validateEmail = () => {
    let valid = true;

    if (!email) {
      setEmailErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    } else if (!validEmail(email)) {
      setEmailErrorMessage(translations[selectedLanguage].common.invalid);
      valid = false;
    }

    return valid;
  };

  const handleUserInput = (e) => {
    setEmail(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const valid = validate();

    if (valid) {
      setEmailLoading(true);
      fetchRequests.account
        .checkEmail(email)
        .then(() => {
          fetchRequests.account
            .passwordReset(email)
            .then(() => {
              setEmailSent(true);
            })
            .catch((error) => {
              dispatch(setError(error));
            })
            .finally(() => {
              setEmailLoading(false);
            });
        })
        .catch((error) => {
          dispatch(setError(error));
          setEmailLoading(false);
        });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(removeError);
    };
  }, []);

  if (emailSent) {
    return (
      <div className="form-container">
        <div className="form-card forgot-password-email-sent-card">
          <div className="forgot-password-email-sent">
            <h2>{translations[selectedLanguage].forgotPassword.anEmailSent}</h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section data-testid="container-forgot-password" className="form-container">
      <div className="form-card">
        <h2>{translations[selectedLanguage].common.forgotpassheader}</h2>
        <p>{translations[selectedLanguage].common.forgotpassbody}</p>
        <form onSubmit={submitHandler}>
          <div className="changepassword-container">
            <SubTextInput
              type="email"
              placeholder={translations[selectedLanguage].common.email}
              onChange={handleUserInput}
              id="forgot-password-email"
              errorMessage={emailErrorMessage}
              value={email}
              name="email"
            />
          </div>
          <SubLoadingButton
            type="submit"
            label={translations[selectedLanguage].common.sendEmail}
            isLoading={emailLoading}
          />
        </form>
      </div>
    </section>
  );
};

export default SmartForgotPassword;
