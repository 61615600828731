import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { COOKIES } from "../../constants/cookies.js";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes.js";
import fetchRequests from "../../requests/index";
import { removeError, setError } from "../../store/actions/errors.js";
import { signOut } from "../../store/actions/user";
import { deleteCookie, getToken, getPurchaseToken } from "../../utilities/cookie.js";
import { getUrlParams } from "../../utilities/url.js";
import { validPassword } from "../../validation/validations";
import SubPasswordInput from "../Common/Form/PasswordInput/PasswordInput.jsx";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading.jsx";
import SubLoadingButton from "../Common/Loading/LoadingButton/SubLoadingButton.jsx";
import "./NewPassword.scss";
import { translations } from "../../translations/texts.js";
import { useSelector } from "react-redux";

const SmartNewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [componentLoading, setComponentLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("");

  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    deleteCookie(COOKIES.TOKEN, getToken());
    deleteCookie(COOKIES.PURCHASETOKEN, getToken());
    dispatch(signOut);
  };

  useEffect(() => {
    const token = getUrlParams().token;
    logout();

    fetchRequests.account
      .verifyPasswordToken(token)
      .then((response) => {
        const userId = response.data.userId;
        setToken(token);
        setUserId(userId);
      })
      .catch((error) => {
        dispatch(setError(error));
      })
      .finally(() => {
        setComponentLoading(false);
      });

    return () => {
      dispatch(removeError);
    };
  }, []);

  const validatePassword = () => {
    let valid = true;

    if (!password) {
      setPasswordErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    } else if (password.length < 8) {
      setPasswordErrorMessage(
        translations[selectedLanguage].common.passwordMinimumLength
      );
      valid = false;
    } else if (!validPassword(password)) {
      setPasswordErrorMessage(translations[selectedLanguage].common.passwordError);
      valid = false;
    }

    return valid;
  };

  const validateConfirmPassword = () => {
    let valid = true;

    if (!confirmPassword) {
      setConfirmPasswordErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordErrorMessage(
        translations[selectedLanguage].common.password2err
      );
      valid = false;
    }

    return valid;
  };

  const validate = () => {
    const valid1 = validatePassword();

    if (valid1) {
      setPasswordErrorMessage("");
    }

    const valid2 = validateConfirmPassword();

    if (valid2) {
      setConfirmPasswordErrorMessage("");
    }

    return valid1 && valid2;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const valid = validate();

    if (valid) {
      setPasswordLoading(true);
      fetchRequests.account
        .resetPassword(userId, password, token)
        .then(() => {
          navigate(INTERNAL_ROUTES.USER.SIGN_IN);
        })
        .catch((error) => {
          setPasswordLoading(false);
          dispatch(setError(error));
        });
    }
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const changeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const renderNewPassword = () => {
    return (
      <>
        <h2>{translations[selectedLanguage].common.newpasstitle2}</h2>
        <form onSubmit={submitHandler}>
          <SubPasswordInput
            name="password"
            placeholder={translations[selectedLanguage].common.newpass}
            onChange={changePassword}
            value={password}
            id="new-password"
            errorMessage={passwordErrorMessage}
          />
          <SubPasswordInput
            name="confirmPassword"
            placeholder={translations[selectedLanguage].common.newpass2}
            onChange={changeConfirmPassword}
            value={confirmPassword}
            id="new-password-confirm-password"
            errorMessage={confirmPasswordErrorMessage}
          />
          <SubLoadingButton
            type="submit"
            label={translations[selectedLanguage].common.submit}
            isLoading={passwordLoading}
          />
        </form>
      </>
    );
  };

  return (
    <section data-testid="container-new-password" className="form-container">
      <div className="form-card new-passwword-card">
        <div className="new-password-wrap-content">
          {componentLoading && <SubComponentLoading />}
          {!componentLoading && renderNewPassword()}
        </div>
      </div>
    </section>
  );
};

export default SmartNewPassword;
