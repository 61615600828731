import React from "react";
import { ASSETS_PATH } from "../../../constants/constants";
import "./SubCompatibilities.scss";
import { useSelector } from "react-redux";
import { translations } from "../../../translations/texts";

const SubCompatibilities = () => {
	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

	return (
		<div data-testid="container-compatibilities" className="compatibilities-container">
			<div className="compatibilities-presentation">
				<h1>{translations[selectedLanguage].compatibilities.title}</h1>
				<div className="compatibilities-presentation-content">
					<p>{translations[selectedLanguage].compatibilities.content1}</p>
				</div>
			</div>
			<div className="compatibilities-tools">
				<div className="compatibilities-tools-row">
					<div className="compatibilities-tools-img smaller-img">
						<img src={ASSETS_PATH.HOMEPAGE_COMPATIBILITIES.PICO_VR} alt={translations[selectedLanguage].common.pico}  className="more-right"/>
					</div>
					<div className="compatibilities-tools-img larger-img">
						<img src={ASSETS_PATH.HOMEPAGE_COMPATIBILITIES.META_VR} alt={translations[selectedLanguage].common.meta} />
					</div>
				</div>

				<div className="compatibilities-tools-row">
					<div className="compatibilities-tools-img larger-img">
						<img src={ASSETS_PATH.HOMEPAGE_COMPATIBILITIES.STEAM_VR} alt={translations[selectedLanguage].common.steamVR} />
					</div>
					<div className="compatibilities-tools-img larger-img">
						<img src={ASSETS_PATH.HOMEPAGE_COMPATIBILITIES.APPLE_VR} alt={translations[selectedLanguage].common.apple} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubCompatibilities;
