import React from "react";
let index = 0;

export const terms = {
  title: "to translate",
  intro: "to translate",
  sections: {
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
  },
  lastUpdated: "Dernière mise à jour",
};
