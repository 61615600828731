import { createReducer } from "@reduxjs/toolkit";
import { actionTypes } from "../../constants/actionTypes";

const initialState = {
  errorMessage: "",
};

const errorReducer = createReducer(initialState, {
  [actionTypes.SET_ERROR]: (state, action) => {
    state.errorMessage = action.payload;
  },
  [actionTypes.REMOVE_ERROR]: (state) => {
    state.errorMessage = "";
  },
});

export default errorReducer;
