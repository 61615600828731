import { createReducer } from "@reduxjs/toolkit";
import { actionTypes } from "../../constants/actionTypes";

const initialState = {
  categories: [],
  episodes: [],
  products: [],
  subscriptions: [],
};

const nomadsDbReducer = createReducer(initialState, {
  [actionTypes.SET_ALL_NOMADS_DATA]: (state, action) => {
    if (action.payload.categories) {
      state.categories = action.payload.categories;
    }

    if (action.payload.episodes) {
      state.episodes = action.payload.episodes;
    }

    if (action.payload.products) {
      state.products = action.payload.products;
    }

    if (action.payload.subscriptions) {
      state.subscriptions = action.payload.subscriptions;
    }
  },
});

export default nomadsDbReducer;
