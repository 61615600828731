import React from "react";
import "./SubCircleStep.scss";

const SubCircleStep = ({ disabled, number, clicked, active }) => {
  return (
    <div
      data-testid="container-circle-step"
      onClick={() => (disabled ? null : clicked(number))}
      className={`circle-step ${disabled ? "--disabled" : ""} ${
        active ? "--active" : ""
      }`}
    >
      <div className="circle-step-number">{number}</div>
    </div>
  );
};

export default SubCircleStep;
