import React from "react";
import Recaptcha from "react-recaptcha";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../../../constants/internalRoutes";
import { RECAPTCHA_SITE_KEY } from "../../../../constants/reCAPTCHA";
import { isOnMobile } from "../../../../utilities/device";
import SubInputError from "../../../Common/Form/InputError/InputError";
import SubTextInput from "../../../Common/Form/TextInput/TextInput";
import "./PayProfileForm3.scss";
import { translations } from "../../../../translations/texts";

const PayProfileForm3 = ({
  changePostalCode,
  changePhoneNumber,
  postalCodeInput,
  phoneNumberInput,
  setRecaptchaVerified,
  postalCodeErrorMessage,
  phoneNumberErrorMessage,
  recaptchaErrorMessage,
}) => {
  const isLoggedIn = useSelector((state) => !!state.user.userData.id);
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);


  if (!isLoggedIn) {
    return <Navigate to={INTERNAL_ROUTES.HOME} replace />;
  }

  return (
    <div
      data-testid="container-pay-profile-form-3"
      className="pay-profile-form-3-wrap"
    >
      <SubTextInput
        name="postalCodeInput"
        placeholder={translations[selectedLanguage].payProfileForm3.postalCode}
        onChange={changePostalCode}
        value={postalCodeInput}
        id="pay-profile-form-3-postal-code"
        errorMessage={postalCodeErrorMessage}
      />
      <SubTextInput
        name="phoneNumberInput"
        placeholder={translations[selectedLanguage].payProfileForm3.phoneNumber}
        onChange={changePhoneNumber}
        value={phoneNumberInput}
        id="pay-profile-form-3-phone-number"
        errorMessage={phoneNumberErrorMessage}
      />
      <Recaptcha
        size={isOnMobile() ? "compact" : "normal"}
        sitekey={RECAPTCHA_SITE_KEY}
        onloadCallback={() => {
          setRecaptchaVerified(false);
        }}
        verifyCallback={() => {
          setRecaptchaVerified(true);
        }}
        expiredCallback={() => {
          setRecaptchaVerified(false);
        }}
      />
      <SubInputError message={recaptchaErrorMessage} />
    </div>
  );
};

export default PayProfileForm3;
