import React from "react";
import "./SmartTerms.scss";
import { useSelector } from "react-redux";
import { translations } from "../../../../translations/texts";

const SmartTerms = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  return (
    <div data-testid="container-terms" className="nomad-section-container" id="terms">
      <section>
        <h1 className="nomad-section-title">
          {translations[selectedLanguage].terms.title}
        </h1>
        {translations[selectedLanguage].terms.intro}
        {Object.values(translations[selectedLanguage].terms.sections).map(
          (qa, index) => (
            <span role="section" key={index}>
              {qa.title}
              {qa.content}
            </span>
          )
        )}
        <p>{translations[selectedLanguage].terms.lastUpdated}</p>
      </section>
    </div>
  );
};

export default SmartTerms;
