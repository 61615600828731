import React from "react";
import "./SmartLegalNotices.scss";
import { translations } from "../../../../translations/texts";
import { useSelector } from "react-redux";

const SmartLegalNotices = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  return (
    <div
      data-testid="container-legal-notices"
      className="nomad-section-container"
      id="legalNotices"
    >
      <section>
        <h1 className="nomad-section-title">
          {translations[selectedLanguage].legalNotices.title}
        </h1>
        <p>{translations[selectedLanguage].common.comingSoon}</p>
      </section>
    </div>
  );
};

export default SmartLegalNotices;
