export const sliders = {
  slide1: {
    title: "OÙ QUE VOUS SOYEZ, LES NOMADES SONT LÀ AVEC VOUS",
    content:
      "Accédez facilement à votre contenu VR. Fonctionne partout où vous avez une connexion Internet.",
  },
  slide2: {
    title: "TROUVEZ VOTRE EXPÉRIENCE VR PRÉFÉRÉE",
    content:
      "NOMADS est pour tout le monde. Des concerts aux sports en passant par les voyages à travers le monde, nous avons quelque chose pour vous.",
  },
  slide3: {
    title: "SENTEZ QUE VOUS ÊTES VRAIMENT LÀ",
    content:
      "VR comme vous ne l'avez jamais vu. Une production de haute qualité vous plonge dans chaque expérience.",
  },
  slide4: {
    title: "REGARDEZ-LE ENSEMBLE.",
    content:
      "Diffusez avec vos amis et votre famille. Faites-en l'expérience ensemble, quelle que soit la distance qui vous sépare.",
  },
};
