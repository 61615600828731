export const userProfile = {
	yourSubscription: "Votre abonnement",
	status: "Status",
	monthlyAmount: "Montant par mois",
	expiresOn: "Expire à",
	unsubConfirm: "Êtes-vous sur de vouloir vous désabonner?",
	changePassword: "Changer le mot de passe",
	changePasswordSuccess: "Un email a été envoyé!",
	removeAccount: "Supprimer le compte",
	deleteConfirm:
		"Êtes-vous certain de vouloir enlever votre compte? Cette action va annuler toute abonnement récurrent et enlever toute donnée relié à votre compte.",
	deleteConfirm2: "ATTENTION: C'est permanent!",
	unlinkFacebook: "Séparer Facebook",
	unlinkGoogle: "Séparer Google",
	unlinkFacebookConfirm: "Êtes-vous certain de vouloir séparer Facebook de votre compte?",
	unlinkGoogleConfirm: "Êtes-vous certain de vouloir séparer Google de votre compte?",
};
