export const selectedTileContent = {
  addToCart: "Ajouter au panier",
  removeFromCart: "Enlever du panier",
  watchLength: "Durée de visionnement",
  rentalPeriod:
    "La période de location est de 30 jours pour débuter le visionnement puis 48 heures pour finir le visionnement une fois commencé.",
  alreadySubscribed: "Déjà abonné",
  forFullLib: "Pour un accès complet à la librairie",
  watchPreview: "Visionner l'aperçu",
};
