import React from "react";
import DatePicker from "react-datepicker";
import SubInputError from "../InputError/InputError";
import { subYears } from "date-fns";
import { useSelector } from "react-redux";

const SubDatePickerInput = ({
  onChange,
  name,
  placeholder,
  id,
  errorMessage,
  selected,
}) => {

  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  return (
    <>
      <DatePicker
        locale={selectedLanguage}
        placeholderText={placeholder}
        name={name}
        className="form-input"
        selected={selected}
        onChange={onChange}
        dateFormat="MMMM d, yyyy"
        withPortal
        showYearDropdown
        yearDropdownItemNumber={99}
        maxDate={subYears(new Date(), 13)}
        scrollableYearDropdown
        isClearable
        id={id}
      />
      <SubInputError message={errorMessage} id={id} />
    </>
  );
};

export default SubDatePickerInput;
