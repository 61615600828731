import React from "react";
import "./SmartJobs.scss";
import { translations } from "../../../../translations/texts";
import { useSelector } from "react-redux";

const SmartJobs = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  const email = "kobritzhauserbmad@gmail.com";

  return (
    <div
      data-testid="container-jobs"
      className="nomad-section-container jobs-section"
      id="jobs"
    >
      <section>
        <h1 className="nomad-section-title">
          {translations[selectedLanguage].jobs.title}
        </h1>
        <p>
          {translations[selectedLanguage].jobs.content}:&nbsp;
          <a href={`mailto:${email}`}>{email}</a>
        </p>
      </section>
    </div>
  );
};

export default SmartJobs;
