import React from "react";
import "./SmartContact.scss";
import { translations } from "../../../../translations/texts";
import { useSelector } from "react-redux";
const SmartContact = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  return (
    <div
      data-testid="container-contact"
      className="nomad-section-container contact-section"
      id="contact"
    >
      <section>
        <h1 className="nomad-section-title">
          {translations[selectedLanguage].contact.title}
        </h1>
        <div className="contact-section-division">
          <p>BMAD Technology Inc.</p>
          <p>
            {translations[selectedLanguage].contact.address}: 432 Rue Sainte-Hélène,
            Suite 100 Montréal, QC H2Y 2K7
          </p>
        </div>
        <div className="contact-section-division">
          <p>
            {translations[selectedLanguage].contact.email}:&nbsp;
            <a href="mailto:contact@nomads.live">contact@nomads.live</a>
          </p>
          <p>
            {translations[selectedLanguage].contact.call}:&nbsp;
            <a href="tel:+1514-316-8988">+1 (514) 316-8988</a>
          </p>
        </div>
        <div className="contact-section-division">
          <p>
            {translations[selectedLanguage].contact.forAnyComplaints}{" "}
            <a href="mailto:support@nomads.live">support@nomads.live</a>
          </p>
          <p>
            {translations[selectedLanguage].contact.needSupport}
            {translations[selectedLanguage].emailUsAt}{" "}
            <a href="mailto:support@nomads.live">support@nomads.live</a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default SmartContact;
