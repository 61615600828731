import axios from "axios";
import { getToken } from "../../utilities/cookie";
import { errorHandler } from "../../utilities/errors";
import { getHeader } from "../../utilities/token";

const recurringPayment = {

  startSubscription: async (card, subscriptionId, token = getToken()) => {
    return await axios
    .post(
      `${process.env.REACT_APP_SERVER_HOST}/purchase/startsubscription`,
      { 
        dataKey: card,
        subscriptionId: subscriptionId 
      },
      getHeader(token)
    )
      .catch(errorHandler);
  },

  cancelSubscription: async (token = getToken()) => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/purchase/cancelsubscription`,
        getHeader(token)
      )
      .catch(errorHandler);
  },

  reactivateSubscription: async (accountId, token = getToken()) => {
    return await axios
    .post(
      `${process.env.REACT_APP_SERVER_HOST}/purchase/reactivatesubscription`,
      {
        accountId: accountId
      },
      getHeader(token)
    )
      .catch(errorHandler);
  },
  changeSubscription: async (dataKey, subscriptionId, token = getToken()) => {
    return await axios
    .post(
      `${process.env.REACT_APP_SERVER_HOST}/purchase/updatesubscription`,
      {
        dataKey: dataKey,
        subscriptionId: subscriptionId
      },
      getHeader(token)
    )
      .catch(errorHandler);
  }

};

export default recurringPayment;
