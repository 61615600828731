import React from "react";
import Slider from "react-slick";
import { ASSETS_HOST, ASSETS_PATH } from "../../../constants/constants";
import SubSelectedTileContent from "./SelectedTileContent/SubSelectedTileContent.jsx";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./SubCarouselContainer.scss";
import { useState } from "react";
import { translations } from "../../../translations/texts";
import { getLocaleProp } from "../../../utilities/strings";
import { useSelector } from "react-redux";


const PrevArrow = (props) => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const { onClick } = props;

  return (
    <div className="carousel-arrows previous" onClick={onClick}>
      <img
        src={ASSETS_PATH.CAROUSEL.ARROW_LEFT}
        alt={translations[selectedLanguage].common.arrowLeft}
      />
    </div>
  );
};

const NextArrow = (props) => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const { onClick } = props;

  return (
    <div className="carousel-arrows next" onClick={onClick}>
      <img
        src={ASSETS_PATH.CAROUSEL.ARROW_RIGHT}
        alt={translations[selectedLanguage].common.arrowRight}
      />
    </div>
  );
};

const SubCarouselContainer = ({
  episodesInCategoryArr,
  categoryObj,
}) => {
  const [current, setCurrent] = useState("");
  const [showSelectedTileContent, setShowSelectedTileContent] = useState(false);
  

  const handleClickImage = (e, productObj) => {
    e && e.preventDefault();
    setCurrent(productObj);
    setShowSelectedTileContent(true);
  };

  const renderCarouselTiles = () => {
    const settings = {
      dots: false,
      infinite: episodesInCategoryArr?.length >= 4,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      accessibility: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: episodesInCategoryArr?.length >= 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: episodesInCategoryArr?.length >= 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: episodesInCategoryArr?.length >= 1,
          },
        },
      ],
    };

    return (
      <Slider {...settings} className="carousel-container">
        {episodesInCategoryArr?.map((episodeObj, index) => {

          return (
            <div
              key={index}
              data-testid="carousel-tile"
              onClick={(e) => handleClickImage(e, episodeObj)}
              id={index}
            >
              <img
                className="carousel-container-tile-img"
                src={`${ASSETS_HOST}${episodeObj.posterSrc}`}
                alt={episodeObj[getLocaleProp("introduction")]}
                title={episodeObj[getLocaleProp("name")]}
              />
              <p className="carousel-container-tile-title">
                {episodeObj[getLocaleProp("name")]}
              </p>
            </div>
          );
        })}
      </Slider>
    );
  };

  const setShowSelectedTileContentFunc = (bool) => {
    setShowSelectedTileContent(bool);
  };

  return (
    <div
      className="carousel-container-wrapper"
      data-testid="container-carousel"
    >
      <h1
        className="carousel-container-category-title"
        data-testid="category-name"
      >
        {(categoryObj || {})[getLocaleProp("category")]}
      </h1>

      {renderCarouselTiles()}
      {showSelectedTileContent ? (
        <SubSelectedTileContent
          setShowSelectedTileContent={setShowSelectedTileContentFunc}
          productObj={current}
          
        />
      ) : null}
    </div>
  );
};

export default SubCarouselContainer;
