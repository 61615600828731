import React from "react";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../../../constants/internalRoutes.js";
import { translations } from "../../../../translations/texts.js";
import SubCheckboxInput from "../../../Common/Form/CheckboxInput/CheckboxInput.jsx";
import SubDropdownInput from "../../../Common/Form/DropdownInput/DropdownInput.jsx";
import Recaptcha from "react-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../../constants/reCAPTCHA.js";
import SubInputError from "../../../Common/Form/InputError/InputError.jsx";
import { isOnMobile } from "../../../../utilities/device.js";

const RegisterForm3 = ({
  genderChange,
  gender,
  genderErrorMessage,
  termCondCheckHandler,
  termCondCheck,
  termCondCheckErrorMessage,
  setRecaptchaVerified,
  recaptchaErrorMessage,
}) => {
  const isLoggedIn = useSelector((state) => !!state.user.userData.id);
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  if (isLoggedIn) {
    return <Navigate to={INTERNAL_ROUTES.HOME} replace />;
  }

  return (
    <>
      <SubDropdownInput
        id="gender-input"
        name="gender"
        onChange={genderChange}
        value={gender}
        placeholder={translations[selectedLanguage].common.gender}
        errorMessage={genderErrorMessage}
        options={[
          { value: "Male", label: translations[selectedLanguage].common.male },
          { value: "Female", label: translations[selectedLanguage].common.female },
          {
            value: "Non-binary",
            label: translations[selectedLanguage].common.nonBinary,
          },
        ]}
      />
      <div>
        <Recaptcha
          size={isOnMobile() ? "compact" : "normal"}
          sitekey={RECAPTCHA_SITE_KEY}
          onloadCallback={() => {
            setRecaptchaVerified(false);
          }}
          verifyCallback={() => {
            setRecaptchaVerified(true);
          }}
          expiredCallback={() => {
            setRecaptchaVerified(false);
          }}
        />
        <SubInputError message={recaptchaErrorMessage} />
      </div>
      <SubCheckboxInput
        onChange={termCondCheckHandler}
        id="form-auth-checkbox"
        checked={termCondCheck}
        errorMessage={termCondCheckErrorMessage}
        label={
          <>
            {translations[selectedLanguage].common.checkboxMsg1}{" "}
            <Link target="_blank" to={INTERNAL_ROUTES.TERMS}>
              {translations[selectedLanguage].common.conditionLink}
            </Link>{" "}
            &amp;{" "}
            <Link target="_blank" to={INTERNAL_ROUTES.PRIVACY}>
              {translations[selectedLanguage].common.privacyLink}
            </Link>
          </>
        }
      />
    </>
  );
};

export default RegisterForm3;
