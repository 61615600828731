import React from "react";
import { useState } from "react";
import { FiEye } from "react-icons/fi";
import SubInputError from "../InputError/InputError";
import "./PasswordInput.scss";

const SubPasswordInput = ({
  value,
  onChange,
  name,
  placeholder,
  id,
  errorMessage,
}) => {
  const [show, setShow] = useState(false);

  const togglePasswordVisibility = () => {
    setShow(!show);
  };

  return (
    <>
      <div
        data-testid="container-password-input"
        className="wrap-password-input"
      >
        <input
          value={value}
          onChange={onChange}
          name={name}
          className="form-input"
          placeholder={placeholder}
          id={id}
          type={show ? "text" : "password"}
        />
        <FiEye
          className={`password-eye-icon ${
            show ? "show-password" : "hide-password"
          }`}
          onClick={togglePasswordVisibility}
        />
      </div>
      <SubInputError message={errorMessage} id={id} />
    </>
  );
};

export default SubPasswordInput;
