import React from "react";
let index = 0;

export const faqPage = {
  title: "FAQ",
  questionAndAnswers: {
    [index++]: {
      question: <h2>What is Virtual Reality (VR)?</h2>,
      answer: (
        <p>
          Virtual reality allows you to discover new worlds. By placing your
          virtual reality headset on your head and starting a NOMADS experience,
          you will be immersed in a new place, whether it's a concert, a stadium
          during a match, the heart of a volcano, in the sea surrounded by
          sharks, up in the sky with your parachute, -everything is possible.
          You will see it as if you were right there, living the experience.
        </p>
      ),
    },
    [index++]: {
      question: <h2>I am not a gamer, is VR made for me?</h2>,
      answer: (
        <p>
          Virtual Reality is not only for video games. Our NOMADS experiences
          are suitable for all types of audiences. Whether you want to relax
          during a walk in Italy or get a rush of adrenaline during a free fall
          jump, you will definitely find experiences that meet your needs. If
          you need help, watch our video tutorial available here (Link to the
          video) or contact us for any technical support at support@nomads.live
        </p>
      ),
    },
    [index++]: {
      question: <h2>How can I watch my VR experience?</h2>,
      answer: (
        <p>
          To watch a VR experience from NOMADS, you only need an Internet
          connection and access to a browser. However, to get the most out of
          our virtual reality experiences, you need a virtual reality headset,
          or a smartphone and a cardboard. Any type of headset will do:
          standalone (Oculus Go type), connected to a computer (like HTC Vive)
          or Cardboard. Each experience is also available in 360º if you prefer
          to watch it directly from your browser on your computer or from your
          mobile phone. However, immersion will only be complete by using a
          headset. Cardboard is the most affordable solution and you can get one
          directly from the NOMADS store: [URL CARDBOARD STORE].
        </p>
      ),
    },
    [index++]: {
      question: <h2>Can I use any browser to navigate in NOMADS?</h2>,
      answer: (
        <p>
          In order to offer you the best possible experience, NOMADS uses the
          latest technologies available. Unfortunately, Internet Explorer does
          not yet support all NOMADS technologies, which is why we disabled
          access to NOMADS from this browser. NOMADS does work on most other
          browsers. We strongly recommend using Chrome, Firefox or Safari to
          take advantage of all of our features. Please also make sure your
          browser is up to date and that you are using the latest version.
        </p>
      ),
    },
    [index++]: {
      question: <h2>Which VR helmets are supported by NOMADS?</h2>,
      answer: (
        <p>
          NOMADS is a web VR platform. This means that you can enjoy NOMADS from
          your computer if you wish. However, you will truly gain the best
          possible experience by using an immersive device. NOMADS is compatible
          with all VR headsets or mobile devices, as long as a browser is
          accessible: Smart Phones, Oculus Go, Rift, HTC Vive, Google DayDream,
          and more.
        </p>
      ),
    },
    [index++]: {
      question: <h2>How much does an experience cost?</h2>,
      answer: (
        <p>
          Each experience is unique and has its own price. To get the best
          possible service, some experiences are composed of several episodes.
          In this case, you can purchase each experience separately, or buy the
          complete package at a discounted rate. While content and duration may
          vary from one experience to the next, all NOMADS experiences share the
          same outstanding quality.
        </p>
      ),
    },
    [index++]: {
      question: <h2>Can I watch experiences without a headset?</h2>,
      answer: (
        <>
          <p>
            All of our experiences are available in VR, as well as 360º
            versions. You can watch them at any time from your computer using
            your favorite browser (Chrome, Firefox, IE, Opera, etc.) or from
            your phone using any available browser (Chrome, Safari, etc.).
          </p>
          <h3>Precautions</h3>
          <p>
            In order to enjoy the NOMADS experiences at their full potential, we
            highly recommend sitting comfortably, ideally in a rotating chair.
            This will allow you to operate the 360º environment safely.
            Alternatively, you can enjoy our experiences in another type of
            chair or couch. Some experiences can be destabilizing, especially
            when you are trying Virtual Reality for the very first time. This is
            why the sitting position is the most recommended. If you feel that
            you are losing your balance, do not hesitate to remove your helmet.
            Sometimes just a few seconds are enough to find your balance and
            dive back into the experience!
          </p>
        </>
      ),
    },
    [index++]: {
      question: <h2>How do I use the voice command?</h2>,
      answer: (
        <>
          <p>
            In order to use voice control, you must accept the use of your
            microphone. If you have denied access to your microphone, you can
            reactivate this feature at any time by allowing access again.
          </p>
          <h3>For chrome</h3>
          <ol>
            <li>Open the menu</li>
            <li>Select « Settings »</li>
            <li>Click on « Show advanced settings »</li>
            <li>In the « Privacy » section, click on « Content settings »</li>
            <li>In the « Media » section, click on « Microphone »</li>
            <li>
              Find NOMADS in the « Block section » and click the « Trashcan » to
              delete it
            </li>
            <li>Return to NOMADS.live</li>
            <li>Click « Allow » when prompted</li>
          </ol>
          <h3>For Firefox</h3>
          <ol>
            <li>Open the menu</li>
            <li>Select Settings</li>
            <li>Click on « Privacy and security » section</li>
            <li>Go to « Permissions » section</li>
            <li>Go to « Microphone » and click on « Parameters »</li>
            <li>Find NOMADS and adjust the parameters accordingly</li>
            <li>Return to NOMADS.live</li>
            <li>Click « Allow » when prompted</li>
          </ol>
          <h3>For Internet Explorer</h3>
          <p>Internet Explorer does not support Voice command</p>
          <h3>For Safari</h3>
          <ol>
            <li>Click on « Safari » on top menu</li>
            <li>Click on « Preferences »</li>
            <li>Click on « Web sites »</li>
            <li>Click on « Microphone »</li>
            <li>
              Find NOMADS.live and adjust the parameters accordingly (Ask or
              Allow)
            </li>
            <li>Click « Allow » when prompted</li>
          </ol>
        </>
      ),
    },
    [index++]: {
      question: <h2>I bought an experience, where can I find it?</h2>,
      answer: (
        <>
          <p>
            If you bought an experience and still have valid viewing credits,
            you can find them through the « My Experiences » menu.
          </p>
          <p>
            NB: If you have purchased an experience but have already used all
            your viewing credits, it will no longer be available from the "My
            Experiences" interface. You will have to buy it again to find it in
            this interface.
          </p>
        </>
      ),
    },
    [index++]: {
      question: <h2>How do I start the tutorial?</h2>,
      answer: (
        <p>
          The tutorial starts automatically at startup but you can decide at any
          time to pass it. If you want to reactivate it later, simply log in to
          your account, click on "Settings" and then "Tutorial". It will restart
          automatically.
        </p>
      ),
    },
    [index++]: {
      question: (
        <h2>I bought the wrong experience, how to cancel my purchase?</h2>
      ),
      answer: (
        <>
          <p>
            If you have bought the wrong experience by mistake, you can cancel
            your purchase within 14 days for purchases you haven't started
            streaming or downloading. After 14 days, or once you have started
            streaming or downloading your NOMADS experience, your purchase is
            final.
          </p>
          <p>
            To cancel your purchase, you must contact us by email at
            support@nomads.live and mention your full name and email address
            related to the account on which you made a purchase, as well as the
            title of the experience you wish to cancel. After verification, the
            refund will be made via the same payment method used for the initial
            purchase.
          </p>
        </>
      ),
    },
    [index++]: {
      question: <h2>Is there an age restriction?</h2>,
      answer: (
        <p>
          There are no age restrictions. Nevertheless, virtual reality headsets
          are generally not recommended for children under 13 years of age. Most
          of our content is suitable for all audiences, but you must be 18 years
          old, (or the age of majority in your province, territory or country)
          to make a purchase on the NOMADS platform. Minors may only use the
          service under the supervision of an adult.
        </p>
      ),
    },
    [index++]: {
      question: <h2>How does the payment system work?</h2>,
      answer: (
        <>
          <p>
            NOMADS accept major credit cards and pre-paid credit cards (Visa,
            MasterCard).
          </p>
          <p>
            You only have to enter your payment information once to buy multiple
            experiences. You will only be debited when you buy an experience.
            That's it! There are no registration or hidden fees. You only pay
            for what you want to see.
          </p>
        </>
      ),
    },
    [index++]: {
      question: <h2>How can I delete my account and data?</h2>,
      answer: (
        <p>
          After being logged in, open the user sidebar and go to Profile then
          select "Remove account" and confirm you wish to proceed twice.
        </p>
      ),
    },
  },
};
