import React from "react";
import SubCircleStep from "./CircleStep/SubCircleStep";
import "./SubCircleSteps.scss";

const SubCircleSteps = ({ step, changeStep }) => {
  let stepNumber = 1;

  return (
    <section data-testid="container-circle-steps" className="circle-steps">
      <hr className="circle-steps-hr" />
      <SubCircleStep
        active={step === 1}
        clicked={(number) => changeStep(number)}
        number={stepNumber++}
      />
      <SubCircleStep
        active={step === 2}
        clicked={(number) => changeStep(number)}
        number={stepNumber++}
        disabled={step < 2}
      />
      <SubCircleStep
        active={step === 3}
        clicked={(number) => changeStep(number)}
        number={stepNumber}
        disabled={step < 3}
      />
    </section>
  );
};

export default SubCircleSteps;
