import React from "react";
import SubInputError from "../InputError/InputError";

const SubDropdownInput = ({
  value,
  onChange,
  name,
  placeholder,
  id,
  errorMessage,
  options,
}) => {
  return (
    <>
      <select
        data-testid="container-dropdown-input"
        className="form-input"
        id={id}
        name={name}
        onChange={onChange}
        value={value}
      >
        <option className="default-select-option" value="" defaultValue>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option aria-label={option.label} key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <SubInputError message={errorMessage} id={id} />
    </>
  );
};

export default SubDropdownInput;
