export const subscriptionDashboard = {
  cancelSub: "Annuler mon abonnement",
  changeSub: "Changer mon abonnement",
  planAndPrices: "Forfait & Tarifs",
  type: "Type d'abonnement",
  recurringAmount: "Montant récurrent",
  renewalDate: "Date de renouvellement",
  expiryDate: "Date d'expiration",
  reactivate: "Réactiver l'abonnement"
};
