import React from "react";
import { useSelector } from "react-redux";
import "./SubBanner.scss";

const SubBanner = () => {
  const errorMessage = useSelector((state) => state.error.errorMessage);

  if (!errorMessage) {
    return null;
  }

  return (
    <section data-testid="container-banner" className="wrap-banner">
      <div className="banner-message">{errorMessage}</div>
    </section>
  );
};

export default SubBanner;
