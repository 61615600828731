const ROUTE_SECTIONS = {
  MANAGE_PAYMENT_METHODS: "manage-payment-methods",
  USER: "user",
};

export const INTERNAL_ROUTES = {
  USER: {
    CART: `/${ROUTE_SECTIONS.USER}/cart`,
    CHANGE_PASSWORD: `/${ROUTE_SECTIONS.USER}/change-password`,
    PROFILE: `/${ROUTE_SECTIONS.USER}/profile`,
    SIGN_IN: `/${ROUTE_SECTIONS.USER}/sign-in`,
    FORGOT_PASSWORD: `/${ROUTE_SECTIONS.USER}/forgot-password`,
    ACTIVATE: `/${ROUTE_SECTIONS.USER}/activate`,
    NEW_PASSWORD: `/${ROUTE_SECTIONS.USER}/new-password`,
    REGISTER: `/${ROUTE_SECTIONS.USER}/register`,
    SUBSCRIPTION_DASHBOARD: `/${ROUTE_SECTIONS.USER}/subscription`,
    SUBSCRIBE: `/${ROUTE_SECTIONS.USER}/subscribe`,
    RENTALS_DASHBOARD: `/${ROUTE_SECTIONS.USER}/rentals`,
  },
  PAYMENT: {
    MANAGE_PAYMENT_METHODS: `/${ROUTE_SECTIONS.MANAGE_PAYMENT_METHODS}`,
    ADD_PAYMENT_PROFILE: `/${ROUTE_SECTIONS.MANAGE_PAYMENT_METHODS}/add-payment-profile`,
    PAYMENT: `/${ROUTE_SECTIONS.MANAGE_PAYMENT_METHODS}/payment`,
    PAYMENT_DISABLED_PROMPT: `/${ROUTE_SECTIONS.MANAGE_PAYMENT_METHODS}/payment-disabled-prompt`,
    EDIT_PAYMENT_PROFILE: `/${ROUTE_SECTIONS.MANAGE_PAYMENT_METHODS}/edit-payment`,
  },
  WEBSTORE: "/webstore",
  REDEEM: "/redeem",
  PRIVACY: "/privacy",
  TERMS: "/terms",
  LEGAL_NOTICES: "/legal-notices",
  JOBS: "/jobs",
  HELP_CENTER: "/help-center",
  FAQ: "/faq",
  CONTACT: "/contact",
  HOME: "/",
};
