import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../../../constants/internalRoutes.js";
import { translations } from "../../../../translations/texts.js";
import SubDatePickerInput from "../../../Common/Form/DatePickerInput/DatePickerInput.jsx";
import SubPasswordInput from "../../../Common/Form/PasswordInput/PasswordInput.jsx";
import "react-datepicker/dist/react-datepicker.css";

const RegisterForm2 = ({
  passwordChange,
  confirmPasswordChange,
  password,
  passwordErrorMessage,
  confirmPassword,
  confirmPasswordErrorMessage,
  birthDate,
  birthDateErrorMessage,
  dateChangeHandler,
}) => {
  const isLoggedIn = useSelector((state) => !!state.user.userData.id);
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  if (isLoggedIn) {
    return <Navigate to={INTERNAL_ROUTES.HOME} replace />;
  }

  return (
    <>
      <SubPasswordInput
        name="password"
        placeholder={translations[selectedLanguage].common.password}
        onChange={passwordChange}
        value={password}
        id="register-password-1"
        errorMessage={passwordErrorMessage}
      />
      <SubPasswordInput
        name="confirmPassword"
        placeholder={translations[selectedLanguage].common.password2}
        onChange={confirmPasswordChange}
        value={confirmPassword}
        id="register-password-2"
        errorMessage={confirmPasswordErrorMessage}
      />
      <SubDatePickerInput
        placeholder={translations[selectedLanguage].common.dateOfBirth}
        name="birthDate"
        selected={birthDate}
        onChange={dateChangeHandler}
        id="register-birthday"
        errorMessage={birthDateErrorMessage}
        required
      />
    </>
  );
};

export default RegisterForm2;
