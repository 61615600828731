export const LANG_ABREVIATION = {
  EN: "EN",
  FR: "FR",
  SP: "SP",
  DE: "DE",
};

export const LANGUAGE = {
  ENGLISH: "english",
  FRENCH: "french",
};
