import React from "react";
import "./SmartPrivacy.scss";
import { translations } from "../../../../translations/texts";
import { useSelector, useDispatch } from "react-redux";
import { openWindow } from "../../../../store/reducers/CookiesManager";

const SmartPrivacy = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  const dispatch = useDispatch()

  const handleCookies = () => {
    dispatch(openWindow());
  }

  return (
    <div data-testid="container-privacy" className="nomad-section-container" id="privacy">
      <section>
        <h1 className="nomad-section-title">
          {translations[selectedLanguage].privacy.title}
        </h1>
        <p>{translations[selectedLanguage].privacy.intro}</p>
        <button onClick={handleCookies} className="cookies-button" id="cookiesButton">{translations[selectedLanguage].privacy.cookiesSettings}</button>
        {Object.values(translations[selectedLanguage].privacy.sections).map(
          (qa, index) => (
            <span role="section" key={index}>
              {qa.title}
              {qa.content}
            </span>
          )
        )}
        <p>{translations[selectedLanguage].privacy.lastUpdated}</p>
      </section>
    </div>
  );
};

export default SmartPrivacy;
