import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { CURRENCY_CODES } from "../../constants/constants.js";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes";
import NomadsFetchRequests from "../../requests/index.js";
import { clearCart, removeCartItem } from "../../store/actions/cart";
import { removeError, setError } from "../../store/actions/errors";
import { getSubsriptionData } from "../../store/actions/subscription";
import { getPaymentProfiles } from "../../store/actions/user";
import { getToken } from "../../utilities/cookie.js";
import { validEmail } from "../../validation/validations.js";
import SubTextInput from "../Common/Form/TextInput/TextInput.jsx";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading";
import SubLoadingButton from "../Common/Loading/LoadingButton/SubLoadingButton";
import SubPaymentMethods from "../PaymentMethods/SubPaymentMethods.jsx";
import SubButton from "../Common/Button/SubButton";
import { translations } from "../../translations/texts.js";
import "./SmartCart.scss";
import { useState } from "react";
import { useEffect } from "react";
import { getLocaleProp } from "../../utilities/strings.js";

const SmartCart = () => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const cart = useSelector((state) => state.cart);
  const isLoggedIn = useSelector((state) => !!state.user.userData.id);
  const user = useSelector((state) => state.user.userData);
  const cardsArr = useSelector(
    (state) => state.user.paymentMethods.creditCards
  );

  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [componentLoading, setComponentLoading] = useState(true);
  const [rentForMyself, setRentForMyself] = useState(false);
  const [giftEmail, setGiftEmail] = useState("");
  const [giftEmailError, setGiftEmailError] = useState("");
  const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState("");

  const onMount = () => {
    Promise.all([
      getPaymentProfiles(),
      getSubsriptionData(),
      NomadsFetchRequests.account.getProfile(getToken()),
    ])
      .then((response) => {
        const profile = response[2].data;

        setSubscriptionExpiryDate(profile.subscriptionExpiryDate);
        setRentForMyself(
          canRentForMyself(user.isSubscribed, profile.subscriptionExpiryDate)
        );
      })
      .catch((error) => {
        dispatch(setError(error));
      })
      .finally(() => {
        setComponentLoading(false);
      });
  };

  useEffect(() => {
    onMount();

    return () => {
      dispatch(removeError);
    };
  }, []);

  const canRentForMyself = (isSubbed, subExpDate) => {
    return !isSubbed && (!subExpDate || subExpDate === "None");
  };

  const allowCheckout = () => {
    const userHasSelectedAPaymentMethod = cardsArr.length > 0;
    return cart.products.length > 0 && userHasSelectedAPaymentMethod;
  };

  const renderCartContent = () => {
    if (cart.products.length === 0) {
      return <h4>{translations[selectedLanguage].cart.nothingInCart}</h4>;
    }

    const products = cart.products.map((prod, i, array) => {
      return (
        <tr
          className={array.length === i + 1 ? "cart-page-last-product" : ""}
          key={i}
        >
          <td>{`${prod[getLocaleProp("name")]} (${
            translations[selectedLanguage].cart.rentalTime
          })`}</td>
          <td className="cart-page-product-price">
            ${prod.price} {CURRENCY_CODES.USD}
          </td>
          <td className="cart-page-remove-product">
            <span
              role="button"
              className="cart-page-remove-product-x"
              onClick={() => {
                dispatch(removeCartItem(prod));
              }}
              id="removeCartItemButton"
            >
              X
            </span>
          </td>
        </tr>
      );
    });

    return (
      <table className="cart-page-table">
        <tbody>{products}</tbody>
        <tfoot>
          <tr className="cart-page-table-total">
            <td>{translations[selectedLanguage].common.total}</td>
            <td className="cart-page-product-price">
              ${getCartTotal()} {CURRENCY_CODES.USD}
            </td>
          </tr>
        </tfoot>
      </table>
    );
  };

  const rentForMyselfFunc = async (productIds) => {

    const data = {
      dataKey: cardsArr[selectedCardIndex].dataKey,
      videos: productIds,
    };

    try {
      const response = await NomadsFetchRequests.moneris.buyRentals(getToken(), data);
      setPurchaseSuccess(true);
      setCheckoutLoading(false);
      dispatch(clearCart);
    } catch (error) {
      dispatch(setError(error));
      setCheckoutLoading(false);
    }
  };

  const rentAsAGift = async (productIds) => {
    
    if (validRecipientEmail()) {
      try {
        await NomadsFetchRequests.general.giftRental(
          giftEmail,
          productIds,
          cardsArr[selectedCardIndex].dataKey
        );
          
        setPurchaseSuccess(true);
        setCheckoutLoading(false);
        dispatch(clearCart);
      } catch (error) {
        console.log(error)
        dispatch(setError(error));
        setCheckoutLoading(false);
      }
    } else {
      setCheckoutLoading(false);
    }
  };

  const validRecipientEmail = () => {
    if (!giftEmail) {
      setGiftEmailError(translations[selectedLanguage].common.required);
      return false;
    } else if (!validEmail(giftEmail)) {
      setGiftEmailError(translations[selectedLanguage].common.invalid);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (checkoutLoading) {
      if (cardsArr.length <= 0) {
        navigate(INTERNAL_ROUTES.PAYMENT.ADD_PAYMENT_PROFILE);
        return;
      }

      const productIds = [];

      cart.products.map((x) => productIds.push(x.id));

      if (cart.products.length > 0) {
        if (rentForMyself) {
          rentForMyselfFunc(productIds);
        } else {
          rentAsAGift(productIds);
        }
      }
    }
  }, [checkoutLoading]);

  const cartCheckout = () => {
    setCheckoutLoading(true);
  };

  const getCartTotal = () => {
    let sum = 0;

    cart.products.forEach((productObj) => {
      sum += productObj.price;
    });

    return sum.toFixed(2);
  };

  const changeSelectedCard = (index) => {
    setSelectedCardIndex(index);
  };

  const selectRentForMyself = () => {
    setRentForMyself(true);
  };

  const selectRentAsAGift = () => {
    setRentForMyself(false);
  };

  const renderEmailInput = () => {
    return (
      <div>
        <SubTextInput
          onChange={changeEmailInput}
          value={giftEmail}
          errorMessage={giftEmailError}
          placeholder={translations[selectedLanguage].cart.recipientEmailAddress}
          className="cart-page-email-input"
          id="recipientEmailInput"
        />
      </div>
    );
  };

  const changeEmailInput = (e) => {
    setGiftEmail(e.target.value);
  };

  const renderDestinations = () => {
    return (
      <div className="cart-page-destinations">
        <div className="cart-page-destinations-choices">
          {canRentForMyself(user.isSubscribed, subscriptionExpiryDate) && (
            <div
              onClick={selectRentForMyself}
              className={`cart-page-destinations-choice ${
                rentForMyself ? " --selected" : ""
              }`}
            >
              {translations[selectedLanguage].cart.rentForMyself}
            </div>
          )}
          <div
            onClick={selectRentAsAGift}
            className={`cart-page-destinations-choice ${
              !rentForMyself ? " --selected" : ""
            }`}
            id="rentAsAGiftButton"
          >
            {translations[selectedLanguage].cart.rentAsAGift}
          </div>
        </div>
        {!rentForMyself && renderEmailInput()}
      </div>
    );
  };

  const goToStore = () => {
    navigate(INTERNAL_ROUTES.WEBSTORE);
  };

  const renderCart = () => {
    if (purchaseSuccess) {
      return (
        <div>
          <h2>{translations[selectedLanguage].cart.thankYouForPurchase}</h2>
          <div className="cart-page-wrap-exit-buttons">
            <SubButton
              onClick={goToStore}
              label={translations[selectedLanguage].common.store}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <h2>{translations[selectedLanguage].cart.shoppingCart}</h2>
        {renderCartContent()}
        {renderDestinations()}
        <SubPaymentMethods
          selectedCardIndex={selectedCardIndex}
          changeSelectedCard={changeSelectedCard}
        />
        <SubLoadingButton
          className="cart-page-checkout-button"
          isLoading={checkoutLoading}
          isDisabled={!allowCheckout()}
          onClick={cartCheckout}
          label="Checkout"
        />
        <p className="cart-page-details">
          {translations[selectedLanguage].common.paymentWarning}
        </p>
      </>
    );
  };

  if (!isLoggedIn) {
    return <Navigate to={INTERNAL_ROUTES.USER.SIGN_IN} replace />;
  }

  return (
    <section data-testid="container-cart" className="cart-container" id="cart">
      <div className="cart-card">
        {componentLoading && <SubComponentLoading />}
        {!componentLoading && renderCart()}
      </div>
    </section>
  );
};

export default SmartCart;
