import store from "../store.js";
import { actionTypes } from "../../constants/actionTypes.js";
import NomadsFetchRequests from "../../requests/index.js";

export const getPaymentProfiles = async () => {
  const response = await NomadsFetchRequests.moneris.getUserCards();

  store.dispatch({
    type: actionTypes.GET_CREDIT_CARDS,
    payload: response.data.message,
  });

  return response;
};

export const signIn = (userData) => {
  return {
    type: actionTypes.SIGN_IN,
    payload: userData,
  };
};

export const signOut = {
  type: actionTypes.SIGN_OUT,
};

export const updatePassword = (state) => {
  return {
    type: actionTypes.UPDATE_PASSWORD,
    payload: { newPassword: state.newPassword },
  };
};

export const updateUser = (state) => {
  return {
    type: actionTypes.UPDATE_USER,
    payload: {
      firstName: state.firstName,
      lastName: state.lastName,
      gender: state.gender,
      dateOfBirth: JSON.stringify(state.dateOfBirth),
      email: state.email,
      id: state.id,
    },
  };
};


export const paymentAdress = (billingAddress) => {
  return {
    type: actionTypes.PAYMENT_PROFILE,
    payload: billingAddress
  }
}