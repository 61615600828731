import React from "react";
import { RegionDropdown } from "react-country-region-selector";
import { COUNTRY } from "../../../../constants/constants";
import SubInputError from "../InputError/InputError";
import { translations } from "../../../../translations/texts";
import { useSelector } from "react-redux";

const SubRegionInput = ({
  country,
  value,
  onChange,
  id,
  errorMessage,
}) => {

  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  const defaultOptionLabel = () => {
    switch (country) {
      case COUNTRY.CA: {
        return translations[selectedLanguage].payProfileForm2.selectProvince;
      }
      case COUNTRY.US: {
        return translations[selectedLanguage].payProfileForm2.selectState;
      }
      default: {
        return translations[selectedLanguage].payProfileForm2.selectRegion;
      }
    }
  };

  return (
    <>
      <RegionDropdown
        className="form-input"
        defaultOptionLabel={defaultOptionLabel()}
        country={country}
        value={value}
        countryValueType="short"
        labelType="short"
        valueType="short"
        onChange={onChange}
        id="selectRegion"
      />
      <SubInputError message={errorMessage} id={id} />
    </>
  );
};

export default SubRegionInput;
