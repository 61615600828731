import axios from "axios";
import { getToken } from "../../utilities/cookie";
import { errorHandler } from "../../utilities/errors";
import { getHeader } from "../../utilities/token";

const payment = {
  /**
   * Buy rentals
   * @param {*} profileId
   * @param {*} products
   * @param {*} token
   * @returns
   */
  buyRentals: async (profileId, products, token = getToken()) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/payment/buyrentals`,
        {
          profileId,
          videos: products,
        },
        getHeader(token)
      )
      .catch(errorHandler);
  },
};

export default payment;
