import { REGEX } from "../constants/constants";

/**
 * Luhn algorithm
 * @param {string} value
 * @returns boolean
 */
export function validCreditCard(value) {
  // accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) return false;

  let nCheck = 0;
  let bEven = false;

  value = value.replace(/\D/g, "");

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven) {
      if ((nDigit *= 2) > 9) {
        nDigit -= 9;
      }
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
}

export const isMasterCard = (value) => {
  return REGEX.MASTERCARD.test(value);
};

export const isVisa = (value) => {
  return REGEX.VISA.test(value);
};

export const validEmail = (email) => {
  if (!email) {
    return false;
  }

  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * No numbers allowed
 * @param {string} value
 * @returns Boolean
 */
export const validName = (value) => {
  if (!value) {
    return false;
  }

  return /^([^0-9]*)$/g.test(value);
};

/**
 * Must contain at least one letter, one number, one upper-case letter and 8 chars
 * @param {string} value
 * @returns boolean
 */
export const validPassword = (value) => {
  if (!value) {
    return false;
  }

  return /^(?:(?=.*?[A-Z])(?:(?=.*?[0-9])(?=.*?[-!@#$%^&*()_[\]{},.<>+=])|(?=.*?[a-z])(?:(?=.*?[0-9])|(?=.*?[-!@#$%^&*()_[\]{},.<>+=])))|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-!@#$%^&*()_[\]{},.<>+=]))[A-Za-z0-9!@#$%^&*()_[\]{},.<>+=-]{7,50}$/.test(
    value
  );
};

export const validCity = (value) => {
  if (!value) {
    return false;
  }

  return /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/.test(value);
};

export const validPhoneNumber = (value) => {
  if (!value) {
    return false;
  }

  return /^\d+$/.test(value);
};
