import { actionTypes } from "../../constants/actionTypes";

export const setError = (message) => {
  return {
    type: actionTypes.SET_ERROR,
    payload: message,
  };
};

export const removeError = {
  type: actionTypes.REMOVE_ERROR,
};
