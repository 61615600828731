import React from "react";
import SubFAQSection from "./FAQSection/SubFAQSection.jsx";
import "./SubFAQ.scss";
import { useSelector } from "react-redux";
import { translations } from "../../../translations/texts.js";


const SubFAQ = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  
  return (
    <section data-testid="container-sub-faq" className="sub-faq-container">
      <h1 className="faq-title">{translations[selectedLanguage].common.faq}</h1>
      <div className="accordion-section">
        {Object.entries(translations[selectedLanguage].faq).map((_key, index) => (
          <SubFAQSection
            key={index}
            question={
              translations[selectedLanguage].faq[`section${index + 1}`].question
            }
            answer={translations[selectedLanguage].faq[`section${index + 1}`].answer}
          />
        ))}
      </div>
    </section>
  );
};

export default SubFAQ;
