import { isPast } from "date-fns";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NomadsFetchRequests from "../../requests/index.js";
import { removeError, setError } from "../../store/actions/errors.js";
import { getToken } from "../../utilities/cookie";
import { getLocaleProp } from "../../utilities/strings.js";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading";
import SubRental from "./Rental/SubRental";
import "./SmartRentalsDashboard.scss";
import { translations } from "../../translations/texts.js";


const SmartRentalsDashboard = () => {
  const [componentLoading, setComponentLoading] = useState(true);
  const [rentals, setRentals] = useState([]);
  const [rentalsHistory, setRentalsHistory] = useState([]);
  const dispatch = useDispatch();

  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  useEffect(() => {
    Promise.all([
      NomadsFetchRequests.episodes.getUserRentals(getToken()),
      NomadsFetchRequests.episodes.getUserRentalsHistory(getToken()),
    ])
      .then((response) => {
        const rentals = response[0].data.list;
        const rentalsHistory = response[1].data.rentals;

        setRentals(rentals);
        setRentalsHistory(rentalsHistory);
      })
      .catch((error) => {
        dispatch(setError(error));
      })
      .finally(() => {
        setComponentLoading(false);
      });

    return () => {
      dispatch(removeError);
    };
  }, []);

  const getCurrentlyWatching = () => {
    const rentalsCurrentlyWatching = rentals.filter(
      (rental) => rental.startedWatching && !isExpired(rental.expiry)
    );

    return rentalsCurrentlyWatching;
  };

  const getNotCurrentlyWatching = () => {
    const rentalsNotCurrentlyWatching = rentals.filter(
      (rental) => !rental.startedWatching && !isExpired(rental.expiry)
    );

    return rentalsNotCurrentlyWatching;
  };

  const getExpired = () => {
    const rentalsExpired = rentals.filter((rental) => isExpired(rental.expiry));

    return rentalsExpired;
  };

  const isExpired = (expiry) => {
    const shortDate = expiry.split("T")[0];
    const date = shortDate.split("-");
    const year = date[0];
    const month = date[1];
    const day = date[2];

    return isPast(new Date(year, month, day));
  };

  const renderContent = () => {
  
  
    return (
      <>
        <h2>{translations[selectedLanguage].rentalsDashboard.myRentals}</h2>
        <div>
          {getCurrentlyWatching().length > 0 && (
            <>
              <h3>
                {translations[selectedLanguage].rentalsDashboard.currentlyWatching}
              </h3>
              <div className="rentals-dashboard-rentals">
                {getCurrentlyWatching().map((rental, index) => {
                  return (
                    <div key={index} className="rentals-dashboard-rental">
                      <SubRental
                        img={rental.posterSrc}
                        rentedOn={rental.insertDate}
                        name={rental[getLocaleProp("name")]}
                        showExpiry
                        
                        expiry={rental.expiry}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {getNotCurrentlyWatching().length > 0 && (
            <>
              <h3>{translations[selectedLanguage].rentalsDashboard.startWatching}</h3>
              <div className="rentals-dashboard-rentals">
                {getNotCurrentlyWatching().map((rental, index) => {
                  return (
                    <div key={index} className="rentals-dashboard-rental">
                      <SubRental
                        img={rental.posterSrc}
                        rentedOn={rental.insertDate}
                        name={rental[getLocaleProp("name")]}
                        showExpiry
                        
                        expiry={rental.expiry}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {getExpired().length > 0 && (
            <>
              <h3>{translations[selectedLanguage].rentalsDashboard.expired}</h3>
              <div className="rentals-dashboard-rentals">
                {getExpired().map((rental, index) => {
                  return (
                    <div key={index} className="rentals-dashboard-rental">
                      <SubRental
                        img={rental.posterSrc}
                        rentedOn={rental.insertDate}
                        name={rental[getLocaleProp("name")]}
                        showExpiry
                        
                        expiry={rental.expiry}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {rentalsHistory.length > 0 && (
            <>
              <h3>{translations[selectedLanguage].rentalsDashboard.rented}</h3>
              <div className="rentals-dashboard-rentals">
                {rentalsHistory.map((rental, index) => {
                  return (
                    <div key={index} className="rentals-dashboard-rental">
                      <SubRental
                        img={rental.posterSrc}
                        name={rental[getLocaleProp("name")]}
                        rentedOn={rental.insertDate}
                        
                        expiry={rental.expiry}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <div
      data-testid="container-rentals-dashboard"
      className="rentals-dashboard"
      id="rentals"
    >
      {componentLoading && <SubComponentLoading />}
      {!componentLoading && renderContent()}
    </div>
  );
};

export default SmartRentalsDashboard;
