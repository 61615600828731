import React from "react";
import SubButton from "../Button/SubButton";
import SubLoadingButton from "../Loading/LoadingButton/SubLoadingButton";
import "./StepButtons.scss";
import { useSelector } from "react-redux";
import { translations } from "../../../translations/texts";

const SubStepButtons = ({
  havePrevStep,
  nextStepHandler,
  haveLoadingButton,
  isLoading,
  loadingButtonLabel,
  nextButtonDisabled,
  prevStepHandler,
  haveNextStep,
}) => {
  
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  
  const showNextStep = () => {

  
    if (haveLoadingButton) {
      return (
        <SubLoadingButton
          className={`${havePrevStep ? "step-buttons-next-step" : ""}`}
          isLoading={isLoading}
          onClick={nextStepHandler}
          label={loadingButtonLabel}
          isDisabled={nextButtonDisabled}
        />
      );
    } else {
      return (
        <SubButton
          className={`${havePrevStep ? "step-buttons-next-step" : ""}`}
          onClick={nextStepHandler}
          label={translations[selectedLanguage].common.nextStep}
          isDisabled={nextButtonDisabled}
        />
      );
    }
  };

  return (
    <div
      data-testid="container-step-buttons"
      className="step-buttons-container"
    >
      {havePrevStep && (
        <SubButton
          onClick={prevStepHandler}
          label={translations[selectedLanguage].common.prevStep}
        />
      )}
      {haveNextStep && showNextStep()}
    </div>
  );
};

export default SubStepButtons;
