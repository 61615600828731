import React from "react";
let index = 0;

export const privacy = {
  title: "Privacy",
  intro:
    "The following Privacy Policy explains our practices, including your choices, regarding the collection, use, and disclosure of certain information, including your personal information, by NOMADS ('NOMADS').",
  cookiesSettings: "Cookie settings",
    sections: {
    [index++]: {
      title: <h2>Contacting Us</h2>,
      content: (
        <p>
          If you have general questions about your account or how to contact
          Customer Service for assistance, please visit our FAQ. For questions
          specifically about this Privacy Statement, or our use of your personal
          information, cookies or similar technologies, please contact our Data
          Protection Officer/Privacy Office by email at privacy@nomads.live. The
          data controller of your personal information is NOMADS. Please note
          that if you contact us to assist you, for your safety and ours we may
          need to authenticate your identity before fulfilling your request.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Collection of Information</h2>,
      content: (
        <>
          <p>We receive and store information about you such as:</p>
          <p>
            Information you provide to us: We collect information you provide to
            us which includes:
          </p>
          <p>
            your last name, first name, email address, payment method(s), and
            telephone number. We collect this information in a number of ways,
            including when you enter it while using our service, interact with
            our Customer Service, or participate in surveys or marketing
            promotions;
          </p>
          <p>
            information when you choose to provide reviews or ratings, taste
            preferences, account settings, (including preferences set in the
            "Account" section of our website), or otherwise provide information
            to us through our service or elsewhere.
          </p>
          <p>
            Information we collect automatically: We collect information about
            you and your use of our service, your interactions with us, as well
            as information regarding your computer or other devices used to
            access our service (such as gaming systems, smart TVs, mobile
            devices and virtual reality headsets). This information includes:
          </p>
          <p>
            your activity on the NOMADS service, such as title selections, watch
            history and search queries;
          </p>
          <p>your interactions with our email;</p>
          <p>
            details of your interactions with Customer Service, such as the
            date, time and reason for contacting us and a copy of exchanged
            emails;
          </p>
          <p>device IDs or other unique identifiers;</p>
          <p>
            advertising identifiers, such as those on mobile devices, tablets,
            and streaming media devices that include such identifiers (see the
            "Cookies and Internet Advertising" section below for more
            information);
          </p>
          <p>
            device and software characteristics (such as type and
            configuration), connection information, statistics on page views,
            referring source (for example, referral URLs), IP address (which may
            tell us your general location), browser and standard web server log
            information;
          </p>
          <p>
            information collected via the use of cookies, web beacons and other
            technologies, including ad data (such as information on the
            availability and delivery of ads, the site URL, as well as the date
            and time). (See our "Cookies and Internet Advertising" section for
            more details.)
          </p>
          <p>
            Information from other sources: We also obtain information from
            other sources. We protect this information according to the
            practices described in this Privacy Statement, plus any additional
            restrictions imposed by the source of the data. These sources vary
            over time, but could include:
          </p>
          <p>
            service providers that help us determine a location based on your IP
            address in order to customize our service and for other uses
            consistent with this Privacy Statement;
          </p>
          <p>
            select partners that make our service available on their device or
            with which we offer co-branded services or engage in joint marketing
            activities;
          </p>
          <p>
            payment service providers who provide us with updated payment method
            information based on their relationship with you;
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>Use of Information</h2>,
      content: (
        <>
          <p>
            We use information to provide, analyze, administer, enhance and
            personalize our services and marketing efforts, to process your
            registration, your orders and your payments, and to communicate with
            you on these and other topics. For example, we use information to:
          </p>
          <p>
            determine your general geographic location, provide localized
            content, provide you with customized and personalized viewing
            recommendations for content we think will be of interest to you,
            determine your Internet service provider, and help us quickly and
            efficiently respond to inquiries and requests;
          </p>
          <p>
            prevent, detect and investigate potentially prohibited or illegal
            activities, including fraud, and enforcing our terms (such as
            determining free trial eligibility);
          </p>
          <p>
            analyze and understand our audience, improve our service (including
            our user interface experiences) and optimize content selection,
            recommendation algorithms and delivery;
          </p>
          <p>
            communicate with you concerning our service (for example by email),
            so that we can send you news about NOMADS, details about new
            features and content available on NOMADS, and special offers,
            promotional announcements, and consumer surveys, and to assist you
            with operational requests such as password reset requests. Please
            see the "Your Choices" section of this Privacy Statement to learn
            how to set or change your communications preferences.
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>Disclosure of Information</h2>,
      content: (
        <>
          <p>
            We disclose your information for certain purposes and to third
            parties, as described below:
          </p>
          <p>
            NOMADS: We share your information among the different NOMADS
            departments as needed for: data processing and storage; providing
            you with access to our services; providing customer support; making
            decisions about service improvements; content development; and for
            other purposes described in the Use of Information section of this
            Privacy Statement.
          </p>
          <p>
            Service Providers: We use other companies, agents or contractors
            ("Service Providers") to perform services on our behalf or to assist
            us with the provision of services to you. For example, we engage
            Service Providers to provide marketing, advertising, communications,
            infrastructure and IT services, to personalize and optimize our
            service, to process credit card transactions or other payment
            methods, to provide customer service, to collect debts, to analyze
            and enhance data (including data about users' interactions with our
            service), and to process and administer consumer surveys. In the
            course of providing such services, these Service Providers may have
            access to your personal or other information. We do not authorize
            them to use or disclose your personal information except in
            connection with providing their services.
          </p>
          <p>
            Promotional offers: We may offer joint promotions or programs that,
            in order for your participation, will require us to share your
            information with third parties. In fulfilling these types of
            promotions, we may share your name and other information in
            connection with fulfilling the incentive. Please note that these
            third parties are responsible for their own privacy practices.
          </p>
          <p>
            Protection of NOMADS and others: NOMADS and its Service Providers
            may disclose and otherwise use your personal and other information
            where we or they reasonably believe such disclosure is needed to (a)
            satisfy any applicable law, regulation, legal process, or
            governmental request, (b) enforce applicable terms of use, including
            investigation of potential violations thereof, (c) detect, prevent,
            or otherwise address illegal or suspected illegal activities
            (including payment fraud), security or technical issues, or (d)
            protect against harm to the rights, property or safety of NOMADS,
            its users or the public, as required or permitted by law.
          </p>
          <p>
            Business transfers: In connection with any reorganization,
            restructuring, merger or sale, or other transfer of assets, we will
            transfer information, including personal information, provided that
            the receiving party agrees to respect your personal information in a
            manner that is consistent with our Privacy Statement.
          </p>
          <p>
            Whenever in the course of sharing information we transfer personal
            information to countries outside of the European Economic Area and
            other regions with comprehensive data protection laws, we will
            ensure that the information is transferred in accordance with this
            Privacy Statement and as permitted by the applicable laws on data
            protection.
          </p>
          <p>
            You may also choose to disclose your information in the following
            ways:
          </p>
          <p>
            while using the NOMADS service you will have opportunities to post
            reviews or other information publicly, and third parties could use
            the information you disclose;
          </p>
          <p>
            certain portions of our service may contain a tool which gives you
            the option to share information by email, text message and social or
            other sharing applications, using the clients and applications on
            your smart device;
          </p>
          <p>
            social plugins and similar technologies allow you to share
            information.
          </p>
          <p>
            Social plugins and social applications are operated by the social
            networks themselves, and are subject to their terms of use and
            privacy policies.
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>Access to Account and Profiles</h2>,
      content: (
        <>
          <p>
            For ease of access to your account, you can use the "Remember me on
            this device" function when you log in to the website. This function
            uses technology that enables us to provide direct account access and
            to help administer the NOMADS service without requiring reentry of
            any password or other user identification when your browser revisits
            the service.
          </p>
          <p>
            To remove access to your NOMADS account from your devices: (a) visit
            the "Account" section of our website, choose "Sign out" and follow
            the instructions to deactivate your devices (note, deactivation may
            not occur immediately) or (b) delete your NOMADS settings from your
            device (steps vary by device and the option is not available on all
            devices). Where possible, users of public or shared devices should
            log out at the completion of each visit. If you sell or return a
            computer or NOMADS ready device, you should log out and deactivate
            the device before doing so. If you do not maintain the security of
            your password or device, or fail to log out or deactivate your
            device, subsequent users may be able to access your account,
            including your personal information.
          </p>
          <p>
            If you share or otherwise allow others to have access to your
            account, they will be able to see your information (including in
            some cases personal information) such as your watch history,
            ratings, reviews and account information (including your email
            address, the chosen payment system or other information in the
            "Account" area of our website).
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>Your Choices</h2>,
      content: (
        <p>
          Email. If you no longer want to receive certain communications from us
          via email, simply access the "Communications Settings" option in the
          "Account" section of our website and uncheck those items to
          unsubscribe. Alternatively, click the "unsubscribe" link in the email.
          Please note that you cannot unsubscribe from service-related
          correspondence from us, such as messages relating to your account
          transactions. Interest-Based Ads. Interest-based ads are online ads
          that are tailored to your likely interests based on your use of
          various apps and websites across the Internet. If you are using a
          browser, then cookies and web beacons can be used to collect
          information to help determine your likely interests. If you are using
          a mobile device, tablet, or streaming media device that includes an
          advertising identifier, then that identifier can be used to help
          determine your likely interests. For your choices about interest-based
          ads from NOMADS, please see the "Cookies and Internet Advertising"
          section (below).
        </p>
      ),
    },
    [index++]: {
      title: <h2>Your Information and Rights</h2>,
      content: (
        <>
          <p>
            You can request access to your personal information, or correct or
            update out-of-date or inaccurate personal information we hold about
            you.
          </p>
          <p>
            You can most easily do this by visiting the "Account" portion of our
            website, where you have the ability to access and update a broad
            range of information about your account, including your contact
            information, your NOMADS payment information, and various related
            information about your account (such as the content you have viewed
            and rated, and your reviews). You must be signed in to access the
            "Account" section. You may also request that we delete personal
            information that we hold about you.
          </p>
          <p>
            To make requests, or if you have any other question regarding our
            privacy practices, please contact our Data Protection
            Officer/Privacy Office at privacy@nomads.live. We respond to all
            requests we receive from individuals wishing to exercise their data
            protection rights in accordance with applicable data protection
            laws. Please also see the "Your Choices" section of this Privacy
            Statement for additional choices regarding your information.
          </p>
          <p>
            We may reject requests that are unreasonable or not required by law,
            including those that would be extremely impractical, could require
            disproportionate technical effort, or could expose us to operational
            risks such as free trial fraud. We may retain information as
            required or permitted by applicable laws and regulations, including
            to honor your choices, for our billing or records purposes and to
            fulfill the purposes described in this Privacy Statement. We take
            reasonable measures to destroy or de-identify personal information
            in a secure manner when it is no longer required.
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>Security</h2>,
      content: (
        <p>
          We use reasonable administrative, logical, physical and managerial
          measures to safeguard your personal information against loss, theft
          and unauthorized access, use and modification. These measures are
          designed to provide a level of security appropriate to the risks of
          processing your personal information.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Other Websites, Platforms and Applications</h2>,
      content: (
        <p>
          The NOMADS service may be provided through and/or utilize features
          (such as voice controls) operated by third party platforms, or contain
          links to sites operated by third parties whose policies regarding the
          handling of information may differ from ours. For example, you may be
          able to access the NOMADS service through platforms such as gaming
          systems, smart TVs, mobile devices, virtual reality devices and a
          number of other Internet connected devices. These websites and
          platforms have separate and independent privacy or data policies,
          privacy statements, notices and terms of use, which we recommend you
          read carefully. In addition, you may encounter third party
          applications that interact with the NOMADS service.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Children</h2>,
      content: (
        <p>
          You must be 18 years of age or older to subscribe to the NOMADS
          service. In certain jurisdictions, the age of majority may be older
          than 18, in which case, you must satisfy that age in order to become a
          member. While individuals under the age of 18 may utilize the service,
          they may do so only with the involvement, supervision, and approval of
          a parent or legal guardian.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Changes to This Privacy Statement</h2>,
      content: (
        <p>
          We will update this Privacy Statement from time to time in response to
          changing legal, regulatory or operational requirements. We will
          provide notice of any such changes (including when they will take
          effect) in accordance with the law. Your continued use of NOMADS
          services after any such updates will constitute acknowledgement and
          (as applicable) acceptance of those changes. If you do not wish to
          acknowledge or accept any updates to this Privacy Statement, you may
          cancel your use of the NOMADS service.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Cookies and Internet Advertising</h2>,
      content: (
        <p>
          We and our Service Providers use cookies and other technologies (such
          as web beacons) for various reasons. For example, we use these
          technologies to make it easy to access our services by remembering you
          when you return, to provide and analyze our services. We also use
          cookies to learn more about our users and their likely interests, and
          to deliver and tailor marketing or advertising. We want you to be
          informed about our use of these technologies, so this section explains
          the types of technologies we use, what they do and your choices
          regarding their use.
        </p>
      ),
    },
    [index++]: {
      title: <h2>What are cookies?</h2>,
      content: (
        <>
          <p>
            Cookies are small data files that are commonly stored on your device
            when you browse and use websites and online services. They are
            widely used to make websites work, or to work more efficiently, as
            well as to provide reporting information and assist with service or
            advertising personalization.
          </p>
          <p>
            Cookies are not the only types of technology that enable this
            functionality; we also use other, similar types of technologies. See
            below for more information and examples.
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>What are advertising identifiers?</h2>,
      content: (
        <p>
          Advertising identifiers are similar to cookies and are found on many
          mobile devices and tablets (for example, the "Identifier for
          Advertisers" (or IDFA) on Apple iOS devices and the "Google
          Advertising ID" on Android devices), and certain streaming media
          devices. Like cookies, advertising identifiers are used to make online
          advertising more relevant.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Why does NOMADS use cookies and advertising identifiers?</h2>,
      content: (
        <>
          <p>
            Essential cookies: These cookies are strictly necessary to provide
            our website or online service. For example, we and our Service
            Providers may use these cookies to authenticate and identify our
            members when they use our websites and applications so we can
            provide our service to them. They also help us to enforce our Terms
            of Use, prevent fraud and maintain the security of our service.
          </p>
          <p>
            Performance and functionality cookies: These cookies are not
            essential, but help us to personalize and enhance your online
            experience with NOMADS. For example, they help us to remember your
            preferences and prevent you from needing to re-enter information you
            previously provided (for example, during member sign up). We also
            use these cookies to collect information (such as popular pages,
            conversion rates, viewing patterns, click-through and other
            information) about our visitors' use of the NOMADS service so that
            we can enhance and personalize our website and service, as well as
            conduct market research. Deletion of these types of cookies may
            result in limited functionality of our service.
          </p>
          <p>
            Advertising cookies and advertising identifiers: These cookies and
            advertising identifiers use information about your use of this and
            other websites and apps, your response to ads and emails, and to
            deliver ads that are more relevant to you. These types of ads are
            called "interest-based advertising." Many of the advertising cookies
            associated with our service belong to our Service Providers.
          </p>
        </>
      ),
    },
    [index++]: {
      title: (
        <h2>
          How can I exercise choice regarding cookies and advertising
          identifiers?
        </h2>
      ),
      content: (
        <>
          <p>
            For more information about cookies set through our website, as well
            as other types of online tracking (including the collection of
            information by third parties about your online activities over time
            and across third-party Web sites or online services for online
            interest based advertising), and to exercise choices regarding them,
            click here.
          </p>
          <p>
            To opt out of interest-based ads from NOMADS in connection with an
            advertising identifier on a mobile device, tablet, or streaming
            media devices, please configure the appropriate setting on your
            device (usually found under "privacy" or "ads" in your device's
            settings). You may still see NOMADS ads on your device, but they
            will not be tailored to your likely interests. NOMADS supports the
            following self-regulatory programs, which provide additional privacy
            choices for interest-based advertising:
          </p>
          <p>In the US: Digital Advertising Alliance (DAA)</p>
          <p>
            In Europe: European Interactive Digital Advertising Alliance (EDAA)
          </p>
          <p>
            In Canada: Ad Choices: Digital Advertising Alliance of Canada (DAAC)
            / Choix de Pub: l'Alliance de la publicité numérique du Canada
            (DAAC)
          </p>
          <p>
            At this time, we do not respond to Web browser "do not track"
            signals.
          </p>
          <p>How Does NOMADS use Web Beacons and other Technologies?</p>
          <p>
            Web beacons (also known as clear gifs or pixel tags) often work in
            conjunction with cookies. We and our Service Providers may use them
            for similar purposes as cookies, such as to understand and enhance
            the use of our service, improve site performance, monitor visitor
            traffic and actions on our site, and understand interactions with
            our marketing (including email and online ads on third party sites).
            Because web beacons often work in conjunction with cookies, in many
            cases, declining cookies will impair the effectiveness of web
            beacons associated with those cookies.
          </p>
          <p>
            We use other technologies that are similar to cookies, such as
            browser storage and plugins (e.g., HTML5, IndexedDB, and WebSQL).
            Like cookies, some of these technologies may store small amounts of
            data on your device. We may use these and various other technologies
            for similar purposes as cookies, such as to enforce our terms,
            prevent fraud, and analyze the use of our service. There are a
            number of ways to exercise choice regarding these technologies. For
            example, many popular browsers provide the ability to clear browser
            storage, commonly in the settings or preferences area; see your
            browser's help function or support area to learn more. Other
            technologies, such as Silverlight storage, may be cleared from
            within the application.
          </p>
        </>
      ),
    },
  },
  lastUpdated: "Last updated: 1 March 2021",
};
