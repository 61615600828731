import React from "react";
import { EXTERNAL_URLS } from "../../../constants/constants";
import { CREDIT_CARD_NAME } from "../../../constants/creditCards";
import SubMaskedInput from "../../Common/Form/MaskedInput/MaskedInput";
import SubLoadingButton from "../../Common/Loading/LoadingButton/SubLoadingButton";
import "./SubCreditCardForm.scss";
import { translations } from "../../../translations/texts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CreditCardForm = ({
	submit,
	cardNumber,
	cardNumberChange,
	cardNumberError,
	cardCVV,
	cardCVVChange,
	cardCVVError,
	cardExpiration,
	cardExpirationChange,
	cardExpirationError,
	updateCreditCardLoading,
	goBack
}) => {
	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

	return (
		<div data-testid="container-credit-card-form" className="credit-card-container">
			<div className="credit-card-form-wrap">
				<h2>{translations[selectedLanguage].creditCardForm.title}</h2>
				<div className="payment-card-icons">
					<div className="visa-card">
						<img src={EXTERNAL_URLS.VISA_CARD_IMAGE} alt={CREDIT_CARD_NAME.VISA} />
					</div>
					<div className="mastercard">
						<img src={EXTERNAL_URLS.MASTER_CARD_IMAGE} alt={CREDIT_CARD_NAME.MASTERCARD} />
					</div>
				</div>
				<form className="credit-card-form" onSubmit={submit}>
					<div className="inputs">
						<SubMaskedInput
							mask="9999 9999 9999 9999"
							value={cardNumber}
							onChange={cardNumberChange}
							name="cardNumber"
							placeholder={translations[selectedLanguage].creditCardForm.cardNumber}
							errorMessage={cardNumberError}
							id="card-number"
						/>
						<SubMaskedInput
							mask="999"
							value={cardCVV}
							onChange={cardCVVChange}
							name="cardCVV"
							placeholder="CVV"
							errorMessage={cardCVVError}
							id="card-cvv"
						/>
						<SubMaskedInput
							mask="99 / 99"
							value={cardExpiration}
							onChange={cardExpirationChange}
							name="cardExpiration"
							placeholder={translations[selectedLanguage].creditCardForm.monthAndYear}
							id="card-expiration"
							errorMessage={cardExpirationError}
						/>
					</div>
					<div className="sidebyside">
					<SubLoadingButton onClick={goBack} type="button" label={translations[selectedLanguage].common.goBack} className="apart"/>
					<SubLoadingButton isLoading={updateCreditCardLoading} type="submit" label={translations[selectedLanguage].common.submit} className="apart"/>
					</div>
				</form>
			</div>
			<div className="security-terms">
				<p>{translations[selectedLanguage].common.paymentWarning}</p>
			</div>
		</div>
	);
};

export default CreditCardForm;
