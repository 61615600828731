

export const CookiesBanner = {
	title: "Préférences Des Cookies",
	intro: "En utilisant ce site web, vous acceptez notre utilisation de cookies et de technologies similaires utilisées pour le bon fonctionnement du site web. Refuser peut entraîner un dysfonctionnement du site web.",
	functionalCookies: "Cookies Fonctionnels",
	rejectAll: "Tout Refuser",
	saveAll: "Tout Sauvegarder"
};

