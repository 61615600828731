import { GENDER } from "../../../constants/constants";
import SubButton from "../../Common/Button/SubButton";
import SubDatePickerInput from "../../Common/Form/DatePickerInput/DatePickerInput";
import SubDropdownInput from "../../Common/Form/DropdownInput/DropdownInput";
import SubTextInput from "../../Common/Form/TextInput/TextInput";
import SubLoadingButton from "../../Common/Loading/LoadingButton/SubLoadingButton";
import { translations } from "../../../translations/texts";
import { useSelector } from "react-redux";
import "./SubUpdateProfileForm.scss";

const SubUpdateProfileForm = ({
  gender,
  genderChange,
  updateProfileLoading,
  cancelHandler,
  dateOfBirth,
  dateOfBirthError,
  dateOfBirthHandler,
  submitHandler,
  firstName,
  firstNameChange,
  firstNameError,
  lastName,
  lastNameChange,
  lastNameError,
}) => {

  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  return (
    <div
      data-testid="container-update-profile-form"
      className="update-profile-form"
  
    >
      <form onSubmit={submitHandler}>
        <div className="update-profile-form-inputs">
          <SubTextInput
            placeholder={translations[selectedLanguage].common.firstName}
            value={firstName}
            onChange={firstNameChange}
            id="user-profile-first-name"
            name="firstName"
            errorMessage={firstNameError}
          />
          <SubTextInput
            placeholder={translations[selectedLanguage].common.lastName}
            value={lastName}
            onChange={lastNameChange}
            id="user-profile-last-name"
            name="lastName"
            errorMessage={lastNameError}
          />
          <SubDatePickerInput
            placeholder={translations[selectedLanguage].common.dateOfBirth}
            selected={dateOfBirth}
            onChange={dateOfBirthHandler}
            id="user-profile-birthday"
            name="dateOfBirth"
            errorMessage={dateOfBirthError}
          />
          <SubDropdownInput
            id="user-profile-gender-input"
            onChange={genderChange}
            value={gender}
            placeholder={translations[selectedLanguage].common.gender}
            name="gender"
            errorMessage=""
            options={[
              {
                value: GENDER.MALE,
                label: translations[selectedLanguage].common.male,
              },
              {
                value: GENDER.FEMALE,
                label: translations[selectedLanguage].common.female,
              },
              {
                value: GENDER.NB,
                label: translations[selectedLanguage].common.nonBinary,
              },
            ]}
          />
        </div>
        <div className="update-profile-form-wrap-edit-profile-buttons">
          <SubButton
            isDisabled={updateProfileLoading}
            label={translations[selectedLanguage].common.cancel}
            onClick={cancelHandler}
          />
          <SubLoadingButton
            isLoading={updateProfileLoading}
            type="submit"
            label={translations[selectedLanguage].common.submitButton}
            className="update-profile-form-submit-button"
          />
        </div>
      </form>
    </div>
  );
};

export default SubUpdateProfileForm;
