export const cart = {
  rentalTime: "48 hours rental",
  subsriptionMonthlyFee: "Subscription Monthly Fee",
  fullLibraryAccess: "Full library access",
  nothingInCart: "Nothing in cart",
  transactionFailed: "Transaction failed. Please try another payment method",
  paymentMethods: "Payment Methods",
  userHasNoCreditCards: "User has no registered credit cards",
  addNewCreditCard: "Add a new Credit Card",
  alreadySubscribed: "You are already subscribed",
  shoppingCart: "Shopping Cart",
  thankYouForPurchase: "Thank you for your purchase",
  rentForMyself: "Rent for myself",
  rentAsAGift: "Rent as a gift",
  recipientEmailAddress: "Recipient's email address",
};
