export const faq = {
  section1: {
    question: "Qu'est-ce que les nomades ?",
    answer:
      "NOMADS est un service de streaming de contenu VR (Virtual Reality). Vous pouvez acheter du contenu VR à la demande ou vous abonner pour un accès illimité à tout ce que NOMADS a à offrir.",
  },
  section2: {
    question: "Où puis-je regarder du contenu NOMADS ?",
    answer:
      "Partout où vous pouvez imaginer! Le contenu NOMADS est accessible depuis n'importe quel appareil doté d'un navigateur Internet compatible. Détendez-vous sur une belle plage depuis votre siège d'avion, explorez un volcan enflammé dans le confort de votre maison ou faites une pause déjeuner pour nager avec les requins au bureau.",
  },
  section3: {
    question:
      "Comment puis-je expérimenter mon contenu en VR (Virtual Reality) ?",
    answer:
      "NOMADS est compatible avec n'importe quel casque VR. Pour diffuser votre contenu, visitez nomads.live depuis n'importe quel navigateur compatible et appuyez sur le bouton VR pour commencer.",
  },
  section4: {
    question: "Que puis-je trouver sur NOMADS?",
    answer:
      "NOMADS possède une bibliothèque en constante expansion de voyages, d'aventures, de concerts, d'événements en direct, de sports et bien plus encore. Du nouveau contenu est ajouté régulièrement, alors revenez souvent!",
  },
  section5: {
    question: "Ma vitesse Internet affectera-t-elle mon expérience?",
    answer:
      "Pour la meilleure expérience NOMADS possible, nous recommandons une vitesse de téléchargement de 20 Mb/s, cependant NOMADS est conçu pour s'adapter à une grande variété d'appareils et de vitesses de connexion Internet.",
  },
};
