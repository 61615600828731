import React from "react";
import "./SubLoadingAnimation.scss";

const SubLoadingAnimation = () => {
  return (
    <section data-testid="container-loading-animation" className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </section>
  );
};

export default SubLoadingAnimation;
