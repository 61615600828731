export const sliders = {
  slide1: {
    title: "WHEREVER YOU ARE, NOMADS IS THERE WITH YOU",
    content:
      "Have easy access to your VR content. Works anywhere you have an internet connection.",
  },
  slide2: {
    title: "FIND YOUR FAVORITE VR EXPERIENCE",
    content:
      "NOMADS is for everyone. From concerts and sports to travel around the world, we have something for you.",
  },
  slide3: {
    title: "FEEL LIKE YOU’RE REALLY THERE",
    content:
      "VR like you’ve never seen. High quality production immerses you in every experience.",
  },
  slide4: {
    title: "WATCH IT TOGETHER",
    content:
      "Stream with friends and family. Experience it together, no matter how far apart you may be.",
  },
};
