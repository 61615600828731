import React from "react";
import { COUNTRY } from "../../../../constants/constants";
import SubInputError from "../InputError/InputError";
import Select from "react-select";
import { allCountryCodes } from "../../../../constants/countryList";
import { useSelector } from "react-redux";
import { placeHolderfr } from "./CountryInput.lang.fr";
import { placeHolderen } from "./CountryInput.lang.en";

const countries = [COUNTRY.CA, COUNTRY.US, COUNTRY.GB];

const SubCountryInput = ({ value, onChange, id, errorMessage }) => {
	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

	const countryForLanguage = allCountryCodes[selectedLanguage];

	const labels = Object.entries(countryForLanguage).map(([label, value]) => ({
		label,
		value,
	}));

	const customStyles = {
		control: (provided) => ({
			...provided,
			color: "white",
			backgroundColor: "black",
			display: "flex",
			border: "none",
		}),
		placeholder: (provided) => ({
			...provided,
			color: "white",
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: "black",
			color: "white",
		}),
		option: (provided, state) => ({
			...provided,
			transition: "background-color 0.3s ease-in-out",
			color: state.isSelected ? "white" : "white",
			backgroundColor: state.isFocused ? "#444" : "black",
			"&:hover": {
				backgroundColor: "#444",
			},
		}),
		singleValue: (provided) => ({
			...provided,
			color: "white",
		}),
	};

	const placeHolder = selectedLanguage === "fr" ? placeHolderfr.placeHolder : placeHolderen.placeHolder;

	return (
		<>
			<Select
				options={labels}
				value={labels.find((option) => option.value === value)}
				onChange={onChange}
				isSearchable
				placeholder={placeHolder}
				styles={customStyles}
				id={id}
			/>
			<SubInputError message={errorMessage} id={id} />
		</>
	);
};

export default SubCountryInput;
