import React from "react";
import {
  FaBars,
  FaUserAlt,
  FaPlay,
  FaCreditCard,
  FaGift,
  FaSignOutAlt,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ASSETS_PATH } from "../../constants/constants.js";
import { COOKIES } from "../../constants/cookies.js";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes.js";
import { signOut } from "../../store/actions/user";
import IconCross from "../../Svg/IconCross.js";
import { deleteCookie, getToken, getPurchaseToken } from "../../utilities/cookie.js";
import SubNavbarLinks from "./NavbarLinks/SubNavbarLinks.jsx";
import { MdSubscriptions } from "react-icons/md";
import { translations } from "../../translations/texts.js";
import "./SmartNavbar.scss";
import { useState } from "react";
import { googleLogout } from '@react-oauth/google';

const SmartNavbar = () => {
  const [showUserSidebar, setShowUserSidebar] = useState("");
  const [showMenu, setShowMenu] = useState("");

  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const isLoggedIn = useSelector((state) => !!state.user.userData.id);
  const cartItems = useSelector((state) => state.cart.products);
  const user = useSelector((state) => state.user.userData);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openSidebar = () => {
    setShowUserSidebar("--show");
  };

  const closeSidebar = () => {
    setShowUserSidebar("");
  };

  const toggleMenu = () => {
    if (showMenu === "--show") {
      setShowMenu("");
    } else {
      setShowMenu("--show");
      closeSidebar();
    }
  };

  const closeMenu = () => {
    setShowMenu("");
  };

  const logoutUser = () => {
    deleteCookie(COOKIES.TOKEN, getToken());
    deleteCookie(COOKIES.PURCHASETOKEN, getPurchaseToken());
    localStorage.removeItem("cart");
    localStorage.removeItem("_grecaptcha");
    googleLogout();

    dispatch(signOut);
    closeSidebar();
    navigate(INTERNAL_ROUTES.HOME);
  };

  return (
    <header data-testid="container-navbar" className="navbar-container">
      {showMenu && (
        <div className="hamburger-menu-container">
          <SubNavbarLinks
            isLoggedIn={isLoggedIn}
            
            cartItems={cartItems}
            user={user}
            openSidebar={openSidebar}
            closeMenu={closeMenu}
          />
        </div>
      )}
      <div className="navbar-logo-container-mobile">
        <FaBars onClick={() => toggleMenu()} />
        <Link onClick={() => closeMenu()} to={INTERNAL_ROUTES.HOME}>
          <img
            className="pngLogo"
            src={ASSETS_PATH.NOMADS_LOGOS.TEXT_AND_LOGO}
            alt="Logo"
          />
        </Link>
      </div>
      <nav className="normal-menu-container">
        <div className="navbar-logo-container-normal">
          <Link to={INTERNAL_ROUTES.HOME}>
            <img
              className="pngLogo"
              src={ASSETS_PATH.NOMADS_LOGOS.TEXT_AND_LOGO}
              alt="Logo"
            />
          </Link>
        </div>
        <SubNavbarLinks
          isLoggedIn={isLoggedIn}
          
          cartItems={cartItems}
          user={user}
          openSidebar={openSidebar}
          closeMenu={closeMenu}
        />
      </nav>
      {isLoggedIn && (
        <nav className={"usersidebar-container " + showUserSidebar}>
          <div onClick={closeSidebar} className="usersidebar-close-btn">
            <IconCross />
          </div>
          <Link
            className="usersidebar-item"
            to={INTERNAL_ROUTES.USER.PROFILE}
            onClick={closeSidebar}
            id="sidebarProfileButton"
          >
            <div className="usersidebar-item-link">
              <FaUserAlt />
              {translations[selectedLanguage].common.sidebarprofile}
            </div>
          </Link>
          <Link
            className="usersidebar-item"
            to={INTERNAL_ROUTES.USER.SUBSCRIPTION_DASHBOARD}
            onClick={closeSidebar}
            id="subscriptionDashboardButton"
          >
            <div className="usersidebar-item-link">
              <MdSubscriptions />
              {translations[selectedLanguage].common.sidebarsubscriptiondashboard}
            </div>
          </Link>
          <Link
            className="usersidebar-item"
            to={INTERNAL_ROUTES.USER.RENTALS_DASHBOARD}
            onClick={closeSidebar}
            id="rentalsDashboardButton"
          >
            <div className="usersidebar-item-link">
              <FaPlay />
              {translations[selectedLanguage].common.sidebarrentalsdashboard}
            </div>
          </Link>
          <Link
            className="usersidebar-item"
            to={INTERNAL_ROUTES.PAYMENT.MANAGE_PAYMENT_METHODS}
            onClick={closeSidebar}
            id="managePaymentMethodsButton"
          >
            <div className="usersidebar-item-link">
              <FaCreditCard />
              {translations[selectedLanguage].common.updatepaymentprofile}
            </div>
          </Link>
          <Link
            className="usersidebar-item"
            to="/redeem"
            onClick={closeSidebar}
          >
            <div className="usersidebar-item-link">
              <FaGift />
              {translations[selectedLanguage].common.sidebarredeem}
            </div>
          </Link>
          <div className="usersidebar-item" onClick={logoutUser}>
            <div className="usersidebar-item-link">
              <FaSignOutAlt />
              {translations[selectedLanguage].common.sidebarlogout}
            </div>
          </div>
        </nav>
      )}
    </header>
  );
};

export default SmartNavbar;
