export const common = {
  paymentWarning:
    "Toute information sur les cartes de credit sont protégé et crypté par Bambora, Secure Session Token (SST) et Secure Sockets Layer (SSL).",
  remove: "Enlever",
  isSelected: "Choisi",
  select: "Sélectionner",
  month: "Mois",
  total: "Total",
  store: "Magasin",
  cart: "Panier",
  comingSoon: "À venir",
  companyName: "NOMADS VR Inc.",
  emailAddress: "Email Address",
  currency: "Devise",
  enterValidAddress: "Entrez une adresse valide",
  enterValidCity: "Entrez une ville valide",
  pleaseSelectRegion: "Vous devez sélectionner une région",
  pleaseSelectCountry: "Vous devez sélectionner un pays",
  enterValidPhoneNumber: "Entrez un numéro de téléphone valide",
  enterValidFirstName: "Entrez un prénom valide",
  enterValidLastName: "Entrez un nom valide",
  enterValidEmail: "Entrez un email valide",
  enterValidPassword:
    "Le mot de passe doit être 8 charactères, incluant 1 chiffre, 1 lettre et 1 lettre en majuscule",
  step: "Étape",
  active: "Actif",
  inactive: "Inactif",
  cancel: "Annuler",
  confirm: "Confirmer",
  edit: "Modifier",
  email: "Adresse de courriel",
  price: "Prix",
  or: "Ou",
  subscribed: "Abonné",
  subscribe: "S'abonner",
  monthly: "mois",
  faq: "Questions Fréquentes",
  rememberMe: "Garder en mémoire",
  errorNoToken: "Erreur: Pas de token",
  errorNotABambooraProfile: "Erreur: Le profile n'est pas du type bamboora",
  error: "Erreur",
  errorNoResponseFromServer: "Erreur: Pas de réponse du serveur",
  errorWrongRequestSetup: "Erreur: Requête mal construite",
  noNumbers: "Pas de nombres",
  tooLong: "Trop long",
  invalid: "Invalide",
  required: "Requis",
  submit: "Soumettre",
  goBack: "Retourner",
  tooShort: "Trop court",
  passwordMatchError: "Les mots de passes doivent être similaire",
  months: "Mois",
  subscription: "Abonnement",
  unknown: "Inconnu",
  on: "sur",
  minutes: "minutes",
  successRedeem: "Cadeau réclamé avec succès!",
  loginLink: "Connexion",
  loginTitle: "Connexion",
  email: "Courriel",
  password: "Mot de passe",
  loginButton: "Connexion",
  updatepaymentprofile: "Update Payment Profile",
  regTitle: "Inscrivez-vous",
  firstName: "Prénom",
  lastName: "Nom",
  emailAddress: "Addresse courriel",
  password2: "Confirmation du mot de passe",
  gender: "Genre (optionnel)",
  male: "Homme",
  female: "Femme",
  nonBinary: "Non-binaire",
  dateOfBirth: "Date de naissance",
  conditionLink: "conditions d'utilisation",
  privacyLink: "déclaration de confidentialité",
  submitButton: "Soumettre",
  checkboxMsg1: "J'ai lu et j'accepte les",
  nextStep: "Suivant",
  prevStep: "Précédent",
  password2err: "Mots de passe ne correspondent pas",
  redeem: "Réclamer un cadeau",
  verifyemail: "Confirmez votre courriel",
  emailsent1: "Le courriel a été envoyé au",
  emailsent2: "Vérifiez votre boîte de réception et activez votre compte",
  forgotpassheader: "Mot de passe oublié?",
  forgotpassbody:
    "Entrez votre adresse courriel dans le champ ci-dessous. Nous vous enverrons un lien pour réinitialiser votre mot de passe.",
  sendEmail: "Envoyer",
  newpass: "Nouveau mot de passe",
  newpass2: "Nouveau mot de passe confirmation",
  newpasstitle2: "Nouveau mot de passe",
  sidebarprofile: "Profil",
  sidebarredeem: "Utiliser un code",
  sidebarlogout: "Déconnexion",
  sidebarsubscriptiondashboard: "Mon Abonnement",
  redeemheader: "Utiliser un code",
  redeemcode: "Code d'accès",
  homepagebutton: "Aller à la page d'accueil",
  activatingAccount: "le compte est en cours d'activation",
  accountactivated: "Vos compte a été activé",
  sessionoraccountactivated:
    "Cette session est expiré ou vos compte est déjà activaté",
  otherLoginMethods: "Autres méthodes de connexion",
  signUpText: "Nouveau chez NOMADS?",
  signUpLink: "Inscrivez-vous",
  creditCardIcon: "Icone de carte de credit",
  windowsMixedReality: "Windows mixed reality",
  chrome: "Chrome",
  occulus: "Occulus",
  placeholder: "Espace reserve",
  vive: "Vive",
  steamVR: "Steam VR",
  cardboard: "Papier carton",
  stockImage: "Image de stock",
  rentalPoster: "Affiche de location",
  subImg: "Image d'abonnement",
  arrowLeft: "Fleche gauche",
  arrowRight: "Fleche droite",
  checkmark: "Coche",
  sidebarrentalsdashboard: "Mes locations",
  card: "Carte",
  search: "Rechercher",
};
