import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { CURRENCY_CODES } from "../../constants/constants";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes";
import NomadsFetchRequests from "../../requests/index.js";
import { removeError, setError } from "../../store/actions/errors";
import { getSubsriptionData } from "../../store/actions/subscription";
import { getPaymentProfiles } from "../../store/actions/user";
import { getLang, translations } from "../../translations/texts";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading";
import SubStepButtons from "../Common/StepButtons/StepButtons";
import SubPaymentMethods from "../PaymentMethods/SubPaymentMethods";

import "./SmartSubscribe.scss";

const SmartSubscribe = () => {
	const [selectedCardIndex, setSelectedCardIndex] = useState(0);
	const [componentLoading, setComponentLoading] = useState(true);
	const [subLoading, setSubLoading] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const cardsArr = useSelector((state) => state.user.paymentMethods.creditCards);

	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const isLoggedIn = useSelector((state) => !!state.user.userData.id);
	const subscriptions = useSelector((state) => state.nomadsDb.subscriptions);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		Promise.all([getPaymentProfiles(), getSubsriptionData()])
			.catch((error) => {
				dispatch(setError(error));
			})
			.finally(() => {
				setComponentLoading(false);
			});

		return () => {
			dispatch(removeError);
		};
	}, []);

	const changeSelectedCard = (index) => {
		setSelectedCardIndex(index);
	};

	const handleSubSelect = (index) => {
		setSelectedIndex(index);
	};


	const checkout = () => {
		setSubLoading(true);
		const card = cardsArr[selectedCardIndex].dataKey;

		NomadsFetchRequests.recurringPayment
			.startSubscription(card, subscriptions[selectedIndex].id)
			.then((response) => {
				if (response.data.code === 400) {
					dispatch(setError(response.data.message[selectedLanguage]));
					setSubLoading(false);
				} else {
					navigate(INTERNAL_ROUTES.USER.RENTALS_DASHBOARD);
				// }
			}})
			.catch((error) => {
				dispatch(setError(error));
				setSubLoading(false);
			});
	};

	const disableCheckout = () => {
		return !(cardsArr.length > 0);
	};

	const renderContent = () => {
		return (
			<>
				<SubPaymentMethods selectedCardIndex={selectedCardIndex} changeSelectedCard={changeSelectedCard} />
				<SubStepButtons
					haveNextStep
					haveLoadingButton
					loadingButtonLabel={translations[selectedLanguage].common.subscribe}
					nextButtonDisabled={disableCheckout()}
					isLoading={subLoading}
					havePrevStep={false}
					nextStepHandler={checkout}
				/>
			</>
		);
	};

	const renderWrapContent = () => {
		return (
			<div>
				<h2>{translations[selectedLanguage].subscribeToPlan.title}</h2>
				{subscriptions.map((subscription, index) => (
					<div onClick={() => handleSubSelect(index)} className={`subscriptionOption ${selectedIndex === index ? "selectedSubscribe" : ""}`} key={index}>
						<span className="firstSpan">
							{subscription.paymentPeriodMonths} {translations[selectedLanguage].common.monthly}
						</span>
						<span className="secondSpan">{translations[selectedLanguage].cart.fullLibraryAccess}</span>
						<span className="thirdSpan">
							${subscription.price} {CURRENCY_CODES.USD}
						</span>
					</div>
				))}
				{renderContent()}
			</div>
		);
	};

	if (!isLoggedIn) {
		return <Navigate to={INTERNAL_ROUTES.USER.SIGN_IN} replace />;
	}

	return (
		<section data-testid="container-subscribe" className="form-container">
			<div className="form-card subscribe-to-plan">
				{componentLoading && <SubComponentLoading />}
				{!componentLoading && renderWrapContent()}
			</div>
		</section>
	);
};

export default SmartSubscribe;
