import axios from "axios";
import { errorHandler } from "../utilities/errors";
import { getHeader } from "../utilities/token";

const categories = {};

categories.getCategories = async () => {
  return await axios
    .get(
      `${process.env.REACT_APP_SERVER_HOST}/videos/getcategorysubcategory`,
      getHeader()
    )
    .catch(errorHandler);
};

export default categories;
