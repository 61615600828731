import React from "react";
import "./SubContentAnimation.scss";

const SubContentAnimation = () => {
  return (
    <div
      data-testid="container-content-animation"
      className="content-animation"
    >
      <div className="content-animation-container">
        <div className="content-animation-carousel">
          <div className="content-animation-gradient content-animation-title"></div>
          <div className="content-animation-row">
            <div className="content-animation-gradient content-animation-product"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
          </div>
        </div>
        <div className="content-animation-carousel">
          <div className="content-animation-gradient content-animation-title"></div>
          <div className="content-animation-row">
            <div className="content-animation-gradient content-animation-product"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
          </div>
        </div>
        <div className="content-animation-carousel">
          <div className="content-animation-gradient content-animation-title"></div>
          <div className="content-animation-row">
            <div className="content-animation-gradient content-animation-product"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
          </div>
        </div>
        <div className="content-animation-carousel">
          <div className="content-animation-gradient content-animation-title"></div>
          <div className="content-animation-row">
            <div className="content-animation-gradient content-animation-product"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
            <div className="content-animation-gradient content-animation-product --invisible-on-mobile"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubContentAnimation;
