import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes";
import { removeError, setError } from "../../store/actions/errors";
import { getPaymentProfiles } from "../../store/actions/user.js";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading";
import SubButton from "../Common/Button/SubButton.jsx";
import "react-datepicker/dist/react-datepicker.css";
import "./SmartManagePaymentMethods.scss";
import { capitalizeFirstLetter } from "../../utilities/strings.js";
import { useState } from "react";
import { useEffect } from "react";
import NomadsFetchRequests from "../../requests";
import { translations } from "../../translations/texts";
import { getToken } from "../../utilities/cookie.js";
import SubManageCreditCard from "./ManageCreditCard/SubManageCreditCard.jsx";

const SmartManagePaymentMethods = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const isLoggedIn = useSelector((state) => !!state.user.userData.id);
	const cardsArr = useSelector((state) => state.user.paymentMethods.creditCards);

	const [componentLoading, setComponentLoading] = useState(true);

	useEffect(() => {
		loadProfiles();

		return () => {
			dispatch(removeError);
		};
	}, []);

	const loadProfiles = async () => {
		try {
			await getPaymentProfiles();
		} catch (error) {
			dispatch(setError(error));
		} finally {
			setComponentLoading(false);
		}
	};

	const deleteCreditCard = async (card) => {
		if (confirm(`${translations[selectedLanguage].common.remove} ${translations[selectedLanguage].common.card} ${card.number}?`)) {
			setComponentLoading(true);
			try {
				await NomadsFetchRequests.moneris.deleteCard(getToken(), card.dataKey);
				loadProfiles();
			} catch (error) {
				dispatch(setError(error.message));
			} finally {
				setComponentLoading(false);
			}
		}
	};

	const renderCards = () => {
		if (cardsArr.length > 0) {
			return [].concat(
				cardsArr.map((profileObj) => {
					let cardObj = profileObj;

					return (
						<SubManageCreditCard
							key={`profile-num-${cardObj.dataKey}`}
							cardObj={cardObj}
							deleteCreditCard={(card) => {
								deleteCreditCard(card, cardObj.dataKey);
							}}
						/>
					);
				})
			);
		} else {
			return (
				<tr>
					<td>{translations[selectedLanguage].cart.userHasNoCreditCards}</td>
				</tr>
			);
		}
	};

	const goToAddPaymentProfile = () => {
		navigate(INTERNAL_ROUTES.PAYMENT.ADD_PAYMENT_PROFILE);
	};

	const renderContent = () => {
		return (
			<>
				<h2 className="manage-payment-methods-title">{translations[selectedLanguage].managePaymentMethods.title}</h2>
				<div className="manage-payment-methods-wrapper">
					<table>
						<thead>
							<tr>
								<th>{capitalizeFirstLetter(translations[selectedLanguage].common.card)}</th>
								<th>{translations[selectedLanguage].managePaymentMethods.expires}</th>
								<th />
							</tr>
						</thead>
						<tbody>{renderCards()}</tbody>
					</table>
				</div>
				<div>
					<SubButton onClick={goToAddPaymentProfile} label={translations[selectedLanguage].managePaymentMethods.addCard} />
				</div>
			</>
		);
	};

	if (!isLoggedIn) {
		return <Navigate to={INTERNAL_ROUTES.USER.SIGN_IN} replace />;
	}

	return (
		<div data-testid="container-manage-payment-methods" className="form-container" id="paymentMethods">
			<div className="form-card managePaymentMethods-wrapper">
				{componentLoading && <SubComponentLoading />}
				{!componentLoading && renderContent()}
			</div>
		</div>
	);
};

export default SmartManagePaymentMethods;
