import React from "react";
import "./SubButton.scss";

const SubButton = ({ label, isDisabled, onClick, className }) => {


  let id = null

  if (label === "Next") {
    id = "nextButton";
  } else if (label === "Previous") {
    id = "previousButton";
  } else if (label === "Add Card") {
    id = "addCard";
  } else {
    id = label
  }
  return (
    <button
      aria-label={label}
      data-testid="container-button"
      onClick={onClick}
      disabled={isDisabled || false}
      type="button"
      className={`common-button ${className || ""}`}
      id={id}
    >
      <span className="common-button-label">{label}</span>
    </button>
  );
};

export default SubButton;
