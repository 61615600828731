import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../../../constants/internalRoutes.js";
import { translations } from "../../../../translations/texts.js";
import SubTextInput from "../../../Common/Form/TextInput/TextInput";

const RegisterForm1 = ({
  firstNameChange,
  lastNameChange,
  emailChange,
  firstName,
  firstNameErrorMessage,
  lastName,
  lastNameErrorMessage,
  email,
  emailErrorMessage,
}) => {
  const isLoggedIn = useSelector((state) => !!state.user.userData.id);
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  

  if (isLoggedIn) {
    return <Navigate to={INTERNAL_ROUTES.HOME} replace />;
  }

  return (
    <>
      <SubTextInput
        name="firstName"
        placeholder={translations[selectedLanguage].common.firstName}
        onChange={firstNameChange}
        value={firstName}
        id="register-first-name"
        errorMessage={firstNameErrorMessage}
      />
      <SubTextInput
        name="lastName"
        placeholder={translations[selectedLanguage].common.lastName}
        onChange={lastNameChange}
        value={lastName}
        id="register-last-name"
        errorMessage={lastNameErrorMessage}
      />
      <SubTextInput
        type="email"
        name="email"
        placeholder={translations[selectedLanguage].common.emailAddress}
        onChange={emailChange}
        value={email}
        id="register-email"
        errorMessage={emailErrorMessage}
      />
    </>
  );
};

export default RegisterForm1;
