import React from "react";
import { ASSETS_PATH } from "../../../constants/constants";
import SubSliderSection from "./SliderSection/SubSliderSection";
import { useSelector } from "react-redux";
import { translations } from "../../../translations/texts";

const SubSliders = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  
  return (
    <div
      data-testid="container-sliders"
      className="homepage-info-sliders-container"
    >
      {ASSETS_PATH.HOMEPAGE_SLIDERS.map((images, index) => (
        <SubSliderSection
          key={index}
          images={images}
          textPosition={index % 2 === 1 ? "--left" : "--right"}
          title={translations[selectedLanguage].sliders[`slide${index + 1}`].title}
          content={translations[selectedLanguage].sliders[`slide${index + 1}`].content}
        />
      ))}
    </div>
  );
};

export default SubSliders;
