import React from "react";
import { translations } from "../../../../translations/texts";
import { useSelector } from "react-redux";

const SmartFAQ = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  return (
    <div data-testid="container-faq" className="nomad-section-container" id="faq">
      <section>
        <h1 className="nomad-section-title">
          {translations[selectedLanguage].faqPage.title}
        </h1>
        {Object.values(translations[selectedLanguage].faqPage.questionAndAnswers).map(
          (qa, index) => (
            <span role="section" key={index}>
              {qa.question}
              {qa.answer}
            </span>
          )
        )}
      </section>
    </div>
  );
};

export default SmartFAQ;
