import { LANG_ABREVIATION } from "../constants/language";
import { getLang } from "../translations/texts";

export const capitalizeFirstLetter = (string) => {
  return (string || "").charAt(0).toUpperCase() + (string || "").slice(1);
};

const getSuffix = () => {
  switch (getLang().toUpperCase()) {
    case LANG_ABREVIATION.DE: {
      return "_de";
    }

    case LANG_ABREVIATION.FR: {
      return "_fr";
    }

    case LANG_ABREVIATION.SP: {
      return "_sp";
    }

    default: {
      return "";
    }
  }
};

export const getLocaleProp = (prop) => {
  return `${prop}${getSuffix()}`;
};
