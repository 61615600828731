import React from "react";
import { ASSETS_HOST } from "../../../constants/constants";
import "./SubRental.scss";
import { translations } from "../../../translations/texts";
import { useSelector } from "react-redux";



const SubRental = ({ expiry, expired, rentedOn, showExpiry, name, img }) => {
  	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	
	return (
		<section data-testid="container-rental" className="rental">
			<div className="rental-poster">
				<img src={`${ASSETS_HOST}${img}`} alt={translations[selectedLanguage].common.rentalPoster} />
			</div>
			<div className="rental-info">
				<p className="rental-info-name">{name || translations[selectedLanguage].rental.unknownName}</p>
				<p className={`rental-info-rented-on ${!showExpiry ? "--with-space" : ""}`}>
					{translations[selectedLanguage].rental.rentedOn}:&nbsp;
					{rentedOn ? rentedOn.split("T")[0] : translations[selectedLanguage].common.unknown}
				</p>
				{showExpiry && (
					<p>
						{expired
							? translations[selectedLanguage].rentalDashboard.expired
							: `${translations[selectedLanguage].rental.expires} ${translations[selectedLanguage].common.on}`}
						:&nbsp;
						{expiry.split("T")[0]}
					</p>
				)}
			</div>
		</section>
	);
};

export default SubRental;
