import React from "react";
let index = 0;

export const privacy = {
  title: "to translate",
  intro: "to translate",
  cookiesSettings: "to translate",
  sections: {
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
    [index++]: {
      title: <h2>to translate</h2>,
      content: "to translate",
    },
  },
  lastUpdated: "to translate",
};
