import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes";
import fetchRequests from "../../requests/index.js";
import { removeError, setError } from "../../store/actions/errors";
import { getUrlParams } from "../../utilities/url";
import SubButton from "../Common/Button/SubButton";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading";
import { translations } from "../../translations/texts";
import "./SmartActivate.scss";

const SmartActivate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [doneChecking, setDoneChecking] = useState(false);
  const [activationSuccessful, setActivationSuccessful] = useState(false);

  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  const onMount = async () => {
    const activationTokenKey = getUrlParams().account_token;

    if (activationTokenKey) {
      try {
        await fetchRequests.account.activate(activationTokenKey);
        setDoneChecking(true);
        setActivationSuccessful(true);
      } catch (error) {
        dispatch(setError(error));
      } finally {
        setDoneChecking(true);
      }
    } else {
      setDoneChecking(true);
    }
  };

  useEffect(() => {
    onMount();

    return () => {
      dispatch(removeError);
    };
  }, []);

  const goToLogin = () => {
    navigate(INTERNAL_ROUTES.USER.SIGN_IN);
  };

  const renderActivatingAccount = () => {
    return (
      <>
        <h2>{translations[selectedLanguage].common.activatingAccount}...</h2>
        <SubComponentLoading />
      </>
    );
  };

  const goToHomepage = () => {
    navigate(INTERNAL_ROUTES.HOME);
  };

  const renderContent = () => {
    if (!doneChecking) {
      return renderActivatingAccount();
    } else if (activationSuccessful) {
      return (
        <div className="activate-page-account-activated">
          <h2>{translations[selectedLanguage].common.accountactivated}</h2>
          <FiCheckCircle className="activate-page-fi-circle-check" />
          <SubButton
            onClick={goToLogin}
            label={translations[selectedLanguage].common.loginLink}
          />
        </div>
      );
    } else {
      return (
        <div className="activate-page-invalid">
          <h2>{translations[selectedLanguage].common.sessionoraccountactivated}</h2>
          <SubButton
            onClick={goToHomepage}
            label={translations[selectedLanguage].common.homepagebutton}
          />
        </div>
      );
    }
  };

  return (
    <section data-testid="container-activate" className="form-container">
      <div className="form-card activate-page">
        <div>{renderContent()}</div>
      </div>
    </section>
  );
};

export default SmartActivate;
