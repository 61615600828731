import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { translations } from "../../../../translations/texts";
import "./SubSliderSection.scss";
import { useSelector } from "react-redux";

const SubSliderSection = ({ images, textPosition, title, content }) => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div
      data-testid="container-slider-section"
      className="homepage-info-container"
    >
      <div className="homepage-info-image">
        <Carousel
          selectedItem={currentIndex}
          infiniteLoop
          showThumbs={false}
          showArrows={false}
          showIndicators={false}
        >
          {images?.map((src, index) => {
            return (
              <img
                key={index}
                src={src}
                className="card-img-top"
                alt={translations[selectedLanguage].common.stockImage}
              />
            );
          })}
        </Carousel>
      </div>
      <div className={`homepage-info-text ${textPosition}`}>
        <h1>{title}</h1>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default SubSliderSection;
