import { LANG_ABREVIATION } from "../../constants/language";


const SET_LANGUAGE = "SET_LANGUAGE";

const getInitialState = () => {
	
	const storedLanguage = localStorage.getItem("lang");

	return {
		selectedLanguage: (storedLanguage || LANG_ABREVIATION.EN).toLowerCase(),
	};
};

const languageReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case SET_LANGUAGE:
			localStorage.setItem("lang", action.payload);
			return {
				...state,
				selectedLanguage: action.payload,
			};
		default:
			return state;
	}
};

export const setLanguage = (language) => {
	return {
		type: SET_LANGUAGE,
		payload: language.toLowerCase(),
	};
};

export default languageReducer;
