import React from "react";
import SubLoadingAnimation from "../LoadingAnimation/SubLoadingAnimation";
import "./SubComponentLoading.scss";

const SubComponentLoading = () => {
  return (
    <div
      data-testid="container-component-loading"
      className="component-loading"
    >
      <SubLoadingAnimation />
    </div>
  );
};

export default SubComponentLoading;
