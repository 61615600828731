import axios from "axios";
import { errorHandler } from "../utilities/errors";
import { getHeader } from "../utilities/token";

const recommendation = {
  getTop10Rated: async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/recommendation/top10rated`,
        getHeader()
      )
      .catch(errorHandler);
  },

  mostTrending: async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/recommendation/mosttrending`,
        getHeader()
      )
      .catch(errorHandler);
  },

  recentlyAdded: async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/recommendation/recentlyadded`,
        getHeader()
      )
      .catch(errorHandler);
  },

  becauseYouLike: async (token) => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/recommendation/becauseyoulike`,
        getHeader(token)
      )
      .catch(errorHandler);
  },
};

export default recommendation;
