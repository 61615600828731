export const subscriptionDashboard = {
  cancelSub: "Cancel my subscription",
  changeSub: "Change my subscription",
  planAndPrices: "Subscribe",
  type: "Subscription Type",
  recurringAmount: "Recurring amount",
  renewalDate: "Renewal date",
  expiryDate: "Expiry date",
  reactivate: "Reactivate subscription"
};
