export function getHeader(
  token = null,
  xapikey = process.env.REACT_APP_API_KEY
) {
  const headers = {
    "X-Api-Key": xapikey,
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return { headers };
}
