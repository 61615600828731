export const actionTypes = {
  ADD_CART_ITEM: "ADD_CART_ITEM",
  REMOVE_CART_ITEM: "REMOVE_CART_ITEM",
  CLEAR_CART: "CLEAR_CART",
  SET_ALL_NOMADS_DATA: "SET_ALL_NOMADS_DATA",
  SIGN_IN: "SIGN_IN",
  UPDATE_USER: "UPDATE_USER",
  SIGN_OUT: "SIGN_OUT",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  GET_CREDIT_CARDS: "GET_CREDIT_CARDS",
  SET_ERROR: "SET_ERROR",
  REMOVE_ERROR: "REMOVE_ERROR",
  ACTUALIZE_CART: "ACTUALIZE_CART",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  COOKIES_SETTINGS: "COOKIES_SETTINGS",
  WINDOW_POPUP: "WINDOW_POPUP",
  OPEN_WINDOW: "OPEN_WINDOW",
  CLOSE_WINDOW: "CLOSE_WINDOW",
  ADD_COOKIES: "ADD_COOKIES",
  ADD_FUNCTIONAL_COOKIES: "ADD_INITIAL_COOKIES",
  REMOVE_FUNCTIONAL_COOKIES: "REMOVE INITIAL_COOKIES",
  SET_PURCHASE_TOKEN: "SET_PURCHASE_TOKEN",
  REMOVE_PURCHASE_TOKEN: "REMOVE_PURCHASE_TOKEN",
  PAYMENT_PROFILE: "PAYMENT_PROFILE",
};
