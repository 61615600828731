import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../../../constants/internalRoutes";
import SubCountryInput from "../../../Common/Form/CountryInput/CountryInput";
import SubRegionInput from "../../../Common/Form/RegionInput/RegionInput";
import SubTextInput from "../../../Common/Form/TextInput/TextInput";
import "./PayProfileForm2.scss";
import { translations } from "../../../../translations/texts";


const PayProfileForm2 = ({
  changeCity,
  selectRegion,
  selectCountry,
  cityInput,
  regionInput,
  countryInput,
  cityErrorMessage,
  regionErrorMessage,
  countryErrorMessage,
  showRegion,
}) => {
  const isLoggedIn = useSelector((state) => !!state.user.userData.id);
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  if (!isLoggedIn) {
    return <Navigate to={INTERNAL_ROUTES.HOME} replace />;
  }

  return (
    <div
      data-testid="container-pay-profile-form-2"
      className="pay-profile-form-2-wrap"
    >
      <SubTextInput
        name="cityInput"
        placeholder={translations[selectedLanguage].payProfileForm2.city}
        onChange={changeCity}
        value={cityInput}
        id="pay-profile-form-2-city"
        errorMessage={cityErrorMessage}
      />
      <SubCountryInput
        name="countryInput"
        value={countryInput}
        onChange={(selectedOption) => selectCountry(selectedOption.value)}
        id="pay-profile-form-2-country"
        errorMessage={countryErrorMessage}
      />
      {showRegion && (
        <SubRegionInput
          name="regionInput"
          
          country={countryInput}
          value={regionInput}
          onChange={selectRegion}
          id="pay-profile-form-2-region"
          errorMessage={regionErrorMessage}
        />
      )}
    </div>
  );
};

export default PayProfileForm2;
