export const cart = {
  rentalTime: "location pour 48 heures",
  subsriptionMonthlyFee: "Frais d'abonnement mensuel",
  fullLibraryAccess: "Accès complet",
  nothingInCart: "Rien dans le panier",
  transactionFailed:
    "La transaction a échoué. S'il vous plait essayez avec une autre méthode de paiement",
  paymentMethods: "Méthodes de paiement",
  userHasNoCreditCards: "L'utilisateur n'a pas de carte de crédit ajoutée",
  addNewCreditCard: "Ajouter une nouvelle carte de crédit",
  alreadySubscribed: "Vous êtes déjà abonné",
  shoppingCart: "Panier d'achat",
  thankYouForPurchase: "Merci de votre achat",
  rentForMyself: "Louer pour moi-même",
  rentAsAGift: "Donner en cadeau",
  recipientEmailAddress: "Email du destinataire",
};
