import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
// import * as serviceWorker from "./serviceWorker";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./components/App.jsx";
import store from "./store/store.js";

export function getStoreState() {
  return store.getState();
}

const rootElement =
  document.getElementById("root") || document.createElement("div");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_KEY}>
    <App />
    </GoogleOAuthProvider>
  </Provider>
);

// serviceWorker.unregister();
