import React from "react";
import SubInputError from "../InputError/InputError";
import "./CheckboxInput.scss";

const SubCheckboxInput = ({
  onChange,
  id,
  errorMessage,
  checked,
  label,
  name,
}) => {
  return (
    <>
      <div
        data-testid="container-checkbox-input"
        className="wrap-checkbox-input"
      >
        <input
          name={name}
          onChange={onChange}
          type="checkbox"
          id={id}
          checked={checked}
        />
        <label aria-label={label} htmlFor={id} className="checkbox-input-label">
          {label}
        </label>
      </div>
      <SubInputError message={errorMessage} id={id} />
    </>
  );
};

export default SubCheckboxInput;
