import account from "./account";
import general from "./general";
import moneris from "./moneris/moneris";
import categories from "./categories";
import episodes from "./episodes";
import products from "./productsDb";
import recommendation from "./recommendation";
import payment from "./moneris/payment"
import profile from "./moneris/profile"
import recurringPayment from "./moneris/recurringPayment";

const NomadsFetchRequests = {
  account,
  general,
  moneris,
  payment,
  profile,
  recurringPayment,
  episodes,
  categories,
  products,
  recommendation,
};

export default NomadsFetchRequests;
