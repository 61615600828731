import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { MAX_NAME_LENGTH } from "../../constants/constants.js";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes.js";
import fetchRequests from "../../requests/index.js";
import { removeError, setError } from "../../store/actions/errors.js";
import {
  validEmail,
  validName,
  validPassword,
} from "../../validation/validations.js";
import SubStepButtons from "../Common/StepButtons/StepButtons.jsx";
import RegForm1 from "./Forms/RegisterForm1/SubRegisterForm1.jsx";
import RegForm2 from "./Forms/RegisterForm2/SubRegisterForm2.jsx";
import RegForm3 from "./Forms/RegisterForm3/SubRegisterForm3.jsx";
import { translations } from "../../translations/texts.js";
import "react-datepicker/dist/react-datepicker.css";
import SubCircleSteps from "../Common/CircleSteps/SubCircleSteps.jsx";
import "./SmartRegister.scss";
import { useEffect } from "react";

const SmartRegister = () => {
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthDateErrorMessage, setBirthDateErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const [gender, setGender] = useState("");
  const [termCondCheck, setTermCondCheck] = useState(false);
  const [termCondCheckErrorMessage, setTermCondCheckErrorMessage] =
    useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [recaptchaErrorMessage, setRecaptchaErrorMessage] = useState("");
  const isLoggedIn = useSelector((state) => !!state.user.userData.id)

  const dispatch = useDispatch();

  const nextStep = () => {

    
    let valid;

    switch (step) {
      case 1: {
        valid = step1Valid();
        break;
      }
      case 2: {
        valid = step2Valid();
        break;
      }
      case 3: {
        valid = step3Valid();
        break;
      }
    }

    if (valid && step < 3) {
      setStep(step + 1);
    }

    return valid;
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const setRecaptchaVerifiedFunc = (recaptchaVerified) => {
    setRecaptchaVerified(recaptchaVerified);
  };

  const firstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const lastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const emailChange = (e) => {
    setEmail(e.target.value);
  };

  const passwordChange = (e) => {
    setPassword(e.target.value);
  };

  const confirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const genderChange = (e) => {
    setGender(e.target.value);
  };

  const showStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="register-inputs">
              <RegForm1
                firstName={firstName}
                firstNameChange={firstNameChange}
                firstNameErrorMessage={firstNameErrorMessage}
                lastName={lastName}
                lastNameChange={lastNameChange}
                lastNameErrorMessage={lastNameErrorMessage}
                email={email}
                emailChange={emailChange}
                emailErrorMessage={emailErrorMessage}
              />
            </div>
            <SubStepButtons
              
              haveNextStep
              nextStepHandler={nextStep}
            />
          </>
        );

      case 2:
        return (
          <>
            <div className="register-inputs">
              <RegForm2
                dateChangeHandler={birthDateHandler}
                password={password}
                passwordChange={passwordChange}
                passwordErrorMessage={passwordErrorMessage}
                confirmPassword={confirmPassword}
                confirmPasswordChange={confirmPasswordChange}
                confirmPasswordErrorMessage={confirmPasswordErrorMessage}
                birthDate={birthDate}
                birthDateErrorMessage={birthDateErrorMessage}
              />
            </div>
            <SubStepButtons
              
              havePrevStep
              haveNextStep
              nextStepHandler={nextStep}
              prevStepHandler={prevStep}
            />
          </>
        );

      case 3:
        return (
          <>
            <div className="register-inputs">
              <RegForm3
                termCondCheckHandler={termCondCheckHandler}
                termCondCheckErrorMessage={termCondCheckErrorMessage}
                gender={gender}
                genderChange={genderChange}
                termCondCheck={termCondCheck}
                setRecaptchaVerified={setRecaptchaVerifiedFunc}
                recaptchaErrorMessage={recaptchaErrorMessage}
              />
            </div>
            <SubStepButtons
              
              havePrevStep
              haveNextStep
              nextStepHandler={submitHandler}
              prevStepHandler={prevStep}
              haveLoadingButton
              loadingButtonLabel={translations[selectedLanguage].common.regTitle}
              isLoading={registerLoading}
            />
          </>
        );
    }

    return <Navigate to={INTERNAL_ROUTES.HOME} replace />;
  };

  const validateFirstName = () => {
    
    let valid = true;

    if (!firstName) {
      setFirstNameErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    } else if (!validName(firstName)) {
      setFirstNameErrorMessage(translations[selectedLanguage].common.noNumbers);
      valid = false;
    } else if (firstName.length > MAX_NAME_LENGTH) {
      setFirstNameErrorMessage(translations[selectedLanguage].common.tooLong);
      valid = false;
    }

    return valid;
  };

  const validateLastName = () => {
    let valid = true;

    if (!lastName) {
      setLastNameErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    } else if (!validName(lastName)) {
      setLastNameErrorMessage(translations[selectedLanguage].common.noNumbers);
      valid = false;
    } else if (lastName.length > MAX_NAME_LENGTH) {
      setLastNameErrorMessage(translations[selectedLanguage].common.tooLong);
      valid = false;
    }

    return valid;
  };

  const validateEmail = () => {
    let valid = true;

    if (!email) {
      setEmailErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    } else if (!validEmail(email)) {
      setEmailErrorMessage(translations[selectedLanguage].common.invalid);
      valid = false;
    }

    return valid;
  };

  const validatePassword = () => {
    let valid = true;

    if (!password) {
      setPasswordErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    } else if (password.length < 8) {
      setPasswordErrorMessage(
        translations[selectedLanguage].common.passwordMinimumLength
      );
      valid = false;
    } else if (!validPassword(password)) {
      setPasswordErrorMessage(translations[selectedLanguage].common.passwordError);
      valid = false;
    }

    return valid;
  };

  const validateConfirmPassword = () => {
    let valid = true;

    if (!confirmPassword) {
      setConfirmPasswordErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    } else if (confirmPassword !== password) {
      setConfirmPasswordErrorMessage(
        translations[selectedLanguage].common.passwordMatchError
      );
      valid = false;
    }

    return valid;
  };

  const validateBirthdate = () => {
    let valid = true;

    if (!birthDate) {
      setBirthDateErrorMessage(translations[selectedLanguage].common.dateOfBirthErrorMessage);
      valid = false;
    }

    return valid;
  };

  const validateTermCond = () => {
    let valid = true;

    if (!termCondCheck) {
      setTermCondCheckErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    }

    return valid;
  };

  const birthDateHandler = (e) => {
    setBirthDate(e || "");
  };

  const termCondCheckHandler = (e) => {
    setTermCondCheck(e.target.checked);
  };

  const submitHandler = (e) => {
    
    e.preventDefault();

    if (nextStep()) {
      setRegisterLoading(true);
      fetchRequests.account
        .signup({
          firstName,
          lastName,
          email,
          password,
          password2: confirmPassword,
          gender,
          dateOfBirth: birthDate,
        })
        .then(() => {
          setRegistrationSuccess(true);
        })
        .catch((error) => {
          dispatch(setError(error));
        })
        .finally(() => {
          setRegisterLoading(false);
        });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(removeError);
    };
  }, []);

  const step1Valid = () => {
    const valid1 = validateFirstName();

    if (valid1) {
      setFirstNameErrorMessage("");
    }

    const valid2 = validateLastName();

    if (valid2) {
      setLastNameErrorMessage("");
    }

    const valid3 = validateEmail();

    if (valid3) {
      setEmailErrorMessage("");
    }

    return valid1 && valid2 && valid3;
  };

  const step2Valid = () => {
    const valid1 = validatePassword();

    if (valid1) {
      setPasswordErrorMessage("");
    }

    const valid2 = validateConfirmPassword();

    if (valid2) {
      setConfirmPasswordErrorMessage("");
    }

    const valid3 = validateBirthdate();

    if (valid3) {
      setConfirmPasswordErrorMessage("");
    }




    return valid1 && valid2 & valid3;
  };

  const step3Valid = () => {
    const valid1 = validateTermCond();

    if (valid1) {
      setTermCondCheckErrorMessage("");
    }

    const valid2 = validateRecaptcha();

    if (valid2) {
      setRecaptchaErrorMessage("");
    }

    return valid1 && valid2;
  };

  const validateRecaptcha = () => {
    let valid = true;

    if (!recaptchaVerified) {
      setRecaptchaErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    }

    return valid;
  };

  const jumpToStep = (step) => {
    setStep(step);
  };

  if (isLoggedIn) {
    return <Navigate to={INTERNAL_ROUTES.HOME} replace />;
  }
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  
  if (registrationSuccess) {
    return (
      <div className="form-container">
        <div className="form-card">
          <h2>{translations[selectedLanguage].common.verifyemail}</h2>
          {`${translations[selectedLanguage].common.emailsent1}${email}. ${
            translations[selectedLanguage].common.emailsent2
          }.`}
        </div>
      </div>
    );
  }

  return (
    <section
      data-testid="container-register"
      className="form-container register"
    >
      <div className="form-card">
        <div className="register-wrap-info">
          <h2>{translations[selectedLanguage].common.regTitle}</h2>
          <SubCircleSteps
            step={step}
            changeStep={(number) => jumpToStep(number)}
          />
        </div>
        {showStep()}
      </div>
    </section>
  );
};

export default SmartRegister;
