import React from "react";
import SubLoadingAnimation from "../LoadingAnimation/SubLoadingAnimation";
import "./SubLoadingButton.scss";

const SubLoadingButton = ({
  isLoading,
  label,
  type,
  isDisabled,
  onClick,
  className,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <SubLoadingAnimation />;
    } else {
      return <p className="common-button-label">{label}</p>;
    }
  };

  return (
    <button
      data-testid="container-loading-button"
      onClick={onClick}
      disabled={isDisabled || isLoading}
      type={type || "button"}
      className={`common-button ${className}`}
      aria-label="Loading"
      id="loading-button"
    >
      {renderContent()}
    </button>
  );
};

export default SubLoadingButton;
