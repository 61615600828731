import React from "react";
import FooterLinks from "./FooterLinks/FooterLinks";
import "./SubFooter.scss";
import { useSelector } from "react-redux";
import { translations } from "../../translations/texts";
const SubFooter = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  return (
    <footer
      data-testid="container-footer"
      className="footer-container footer-container-links"
    >
      <div className="footer-container-inner-links">
        <div className="footer-parent-link-container">
          <FooterLinks />
        </div>
        <div className="footer-company-name">
          {translations[selectedLanguage].common.companyName}
        </div>
      </div>
    </footer>
  );
};

export default SubFooter;
