export const userProfile = {
  yourSubscription: "Your Subscription",
  status: "Status",
  monthlyAmount: "Monthly Amount",
  expiresOn: "Expires on",
  unsubConfirm: "Are you sure you would like to unsubscribe?",
  changePassword: "Change password",
  changePasswordSuccess: "An email has been sent!",
  removeAccount: "Remove account",
  deleteConfirm:
    "Are you sure you would like to remove your account? This will cancel any on-going subscription along with removing any data related to your account.",
  deleteConfirm2: "WARNING: This is permanent!",
  unlinkFacebook: "Unlink Facebook",
  unlinkGoogle: "Unlink Google",
  unlinkFacebookConfirm: "Are you sure you would like to unlink Facebook?",
  unlinkGoogleConfirm: "Are you sure you would like to unlink Google?",
};
