import React from "react";
import InputMask from "react-input-mask";
import SubInputError from "../InputError/InputError";

const SubMaskedInput = ({
  mask,
  value,
  onChange,
  name,
  placeholder,
  id,
  errorMessage,
}) => {
  return (
    <>
      <InputMask
        mask={mask}
        value={value}
        onChange={onChange}
        name={name}
        className="form-input"
        placeholder={placeholder}
        id={id}
      />
      <SubInputError message={errorMessage} id={id} />
    </>
  );
};

export default SubMaskedInput;
