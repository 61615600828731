import { configureStore } from "@reduxjs/toolkit";
import cart from "./reducers/cart";
import user from "./reducers/user";
import nomadsDb from "./reducers/nomadsDb";
import error from "./reducers/errors";
import language from "./reducers/language";
import CookiesManagerReducer from "./reducers/CookiesManager";
import purchaseTokenReducer from "./reducers/purchaseToken";

const store = configureStore({
  reducer: {
    cart,
    user,
    nomadsDb,
    error,
    language,
    CookiesManager: CookiesManagerReducer,
    purchaseToken: purchaseTokenReducer,
  },
});

export default store;
