import React from "react";
import "./InputError.scss";

const SubInputError = ({ message, id }) => {
  return (
    <>
      {message ? (
        <label
          data-testid="input-error"
          className="input-error-message"
          htmlFor={id}
        >
          {message}
        </label>
      ) : (
        <div data-testid="empty-input-error" className="empty-input-error" />
      )}
    </>
  );
};

export default SubInputError;
