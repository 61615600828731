export const common = {
  paymentWarning:
    "Payment card details are protected and encrypted using Bambora, Secure Session Token (SST) and Secure Sockets Layer (SSL).",
  remove: "Remove",
  isSelected: "Selected",
  select: "Select",
  month: "Month",
  total: "Total",
  store: "Store",
  cart: "Cart",
  comingSoon: "Coming Soon",
  companyName: "NOMADS VR Inc.",
  emailAddress: "Email Address",
  currency: "Currency",
  enterValidAddress: "Enter a valid address",
  enterValidCity: "Enter a valid city",
  pleaseSelectRegion: "You must select a region",
  pleaseSelectCountry: "You must select a country",
  enterValidPhoneNumber: "Enter a valid phone number",
  enterValidFirstName: "Enter a valid first name",
  enterValidLastName: "Enter a valid last name",
  enterValidEmail: "Enter a valid email address",
  enterValidPassword:
    "Password must be 8 chars. Including 1 number, 1 letter and 1 upper-case letter",
  step: "Step",
  active: "Active",
  inactive: "Inactive",
  cancel: "Cancel",
  confirm: "Confirm",
  edit: "Edit",
  email: "Email",
  price: "Price",
  or: "Or",
  subscribed: "Subscribed",
  subscribe: "Subscribe",
  monthly: "month",
  faq: "Frequently Asked Questions",
  rememberMe: "Remember me",
  errorNoToken: "Error: No token",
  errorNotABambooraProfile: "Error: Not a bamboora profile",
  error: "Error",
  errorNoResponseFromServer: "Error: No response from server",
  errorWrongRequestSetup: "Error: Wrong request setup",
  noNumbers: "No numbers",
  tooLong: "Too long",
  invalid: "Invalid",
  submit: "Submit",
  goBack: "Go Back",
  required: "Required",
  tooShort: "Too short",
  passwordError:
    "Password must contain at least one letter, one number and one upper-case letter",
  passwordMinimumLength: "Password must be at least 8 characters",
  passwordMatchError: "Passwords must match",
  months: "Months",
  subscription: "Subscription",
  unknown: "Unknown",
  on: "on",
  card: "card",
  minutes: "minutes",
  successRedeem: "Gift code successfully redeemed!",
  loginLink: "Sign in",
  loginTitle: "Sign In",
  email: "Email",
  password: "Password",
  loginButton: "Sign In",
  updatepaymentprofile: "Update Payment Profile",
  regTitle: "Register",
  firstName: "First name",
  lastName: "Last name",
  emailAddress: "Email Address",
  password2: "Password Confirmation",
  gender: "Gender (optional)",
  male: "Male",
  female: "Female",
  nonBinary: "Non-binary",
  dateOfBirth: "Date of Birth",
  dateOfBirthErrorMessage: "please enter a date of birth",
  conditionLink: "terms of service",
  privacyLink: "privacy policy",
  submitButton: "Submit",
  checkboxMsg1: "I have read and accept the",
  nextStep: "Next",
  prevStep: "Previous",
  password2err: "Passwords do not match",
  redeem: "Redeem",
  verifyemail: "Verify your email",
  emailsent1: "An email was successfully sent to ",
  emailsent2: "Check your inbox and activate your account",
  forgotpassheader: "Forgot Password",
  forgotpassbody:
    "Type your email address in the field below. We will send you a link to reset your password.",
  sendEmail: "Submit",
  newpass: "New password",
  newpass2: "New password confirmation",
  newpasstitle2: "Setup new password",
  sidebarprofile: "Profile",
  sidebarredeem: "Redeem Experience",
  sidebarlogout: "Sign out",
  sidebarsubscriptiondashboard: "My Subscription",
  sidebarrentalsdashboard: "My Rentals",
  redeemheader: "Redeem your experience",
  redeemcode: "Access Code",
  homepagebutton: "Go to homepage",
  activatingAccount: "Account is being activated",
  accountactivated: "Your account has been activated",
  sessionoraccountactivated:
    "This session has already expired or account has already been activated",
  otherLoginMethods: "Other Login Methods",
  signUpText: "Not registered yet?",
  signUpLink: "Register",
  creditCardIcon: "Credit card icon",
  windowsMixedReality: "Windows mixed reality",
  pico: "Pico VR",
  meta: "Meta VR",
  placeholder: "Placeholder",
  vive: "Vive",
  steamVR: "Steam VR",
  apple: "Apple VR",
  stockImage: "Stock image",
  rentalPoster: "Rental poster",
  subImg: "Subscription image",
  arrowLeft: "Arrow left",
  arrowRight: "Arrow right",
  checkmark: "Checkmark",
  search: "Search",
};
