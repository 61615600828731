import { setPurchaseToken, removePurchaseToken } from "../actions/purchaseTokens";

const getInitialState = () => {
    const storedToken = localStorage.getItem("purchaseToken");
    return {
        purchaseToken: storedToken || null,
    };
};

const purchaseTokenReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case setPurchaseToken:
            return {
                ...state,
                purchaseToken: action.payload,
            };
        case removePurchaseToken:
            return {
                ...state,
                purchaseToken: null,
            };
        default:
            return state;
    }
};

export default purchaseTokenReducer;
