import React from "react";
import SubLoadingAnimation from "../LoadingAnimation/SubLoadingAnimation";
import "./SubGlobalLoading.scss";

const SubGlobalLoading = () => {
  return (
    <div
      data-testid="container-global-loading"
      className="global-loading-container"
    >
      <div className="global-loading-content">
        <SubLoadingAnimation />
      </div>
    </div>
  );
};

export default SubGlobalLoading;
