const CONFIG = {
  enableLanguageSwitch: true, // if disabled, the navbar option will disappear and the app will be forced in EN
  enableManagePaymentMethods: true, // for enabling the page
  enablePaymentProfile: true, // for enabling both adding and editing a payment profile
  enableCreditCardInformation: true, // can no longer follow through with adding a credit card if disabled
  enableCart: true, // for enabling the page
  profile: {
    enableUserProfile: true, // for enabling the page
    enableEditProfile: true, // if disabled, the button "Edit" will disappear in /profile
    enableChangePassword: true, // if disabled, the button "Change password" will disappear in /profile
    enableRemoveAccount: true, // if disabled, the button "Remove account" will disappear in /profile
    enableUnlinkFacebook: true, // if disabled, the button "Unlink Facebook" will disppear in /profile
     enableUnlinkGoogle: true, // if disabled, the button "Unlink Google" will disppear in /profile
  },
  enableRedeem: true, // for enabling the page
  enableSubscriptionDashboard: true, // for enabling the page
  enableSubscription: true, // for enabling the page. If disabled, the button "Subscribe" in sub dashboard will lead to 404. Same result for clicking "Subscribe" in the store.
  enableRentalDashboard: true, // for enabling the page
  enableLogin: true, // for enabling the page
  enableRegister: true, // for enabling the page
  enableForgotPassword: true, // for enabling the page
  enableActivation: true, // for enabling the page
  enableNewPassword: true, // for enabling the page,
  footer: {
    enablePrivacy: true, // for enabling the page,
    enableTerms: true, // for enabling the page,
    enableLegalNotice: true, // for enabling the page,
    enableJobs: true, // for enabling the page,
    enableHelpCenter: true, // for enabling the page,
    enableFAQ: true, // for enabling the page,
    enableContact: true, // for enabling the page,
  },
  store: {
    enableStore: true, // for enabling the page
    enableWatchPreview: true, // if disabled, the button "Watch Preview" will disappear in /webstore
    enableAddingAndRemovingItemsInCart: true, // if disabled, both "Adding to cart" and "Remove from cart" buttons will disappear in /webstore
  },
  COGNITO: {
    domain: `https://sociallogin.bsomewhere.com/login?client_id=7egpu855sb38t2caiaekh7tt65&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_ENVIRONMENT}`,
    },
    
};

export default CONFIG;
