export const selectedTileContent = {
  addToCart: "Add to cart",
  removeFromCart: "Remove from cart",
  watchLength: "Watch Length",
  rentalPeriod:
    "Rental Period is 30 days to begin watching, 48 hours to finish watching once started",
  alreadySubscribed: "Already Subscribed",
  forFullLib: "For full library access",
  watchPreview: "Watch Preview",
};
