import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes";
import SubCreditCardDisplay from "../Common/CreditCardDisplay/SubCreditCardDisplay";
import { translations } from "../../translations/texts";
import creditCardType from "credit-card-type";
import { useEffect } from "react";
import { getPaymentProfiles } from "../../store/actions/user";
import { useDispatch } from "react-redux";
import { removeError, setError } from "../../store/actions/errors";

const SubPaymentMethods = ({ selectedCardIndex, changeSelectedCard }) => {
	const navigate = useNavigate();

    const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const cardsArr = useSelector((state) => state.user.paymentMethods.creditCards)

	const goToAddPaymentProfile = () => {
		navigate(INTERNAL_ROUTES.PAYMENT.ADD_PAYMENT_PROFILE);
	};

	const dispatch = useDispatch()

	useEffect(() => {
		Promise.all([getPaymentProfiles()])
			.catch((error) => {
				dispatch(setError(error));
			})
			.finally(() => {
			});

		return () => {
			dispatch(removeError);
		};
	}, []);

	return (
		<div data-testid="container-payment-methods" className="cart-page-payment-methods" id="paymentMethods">
			<h3>{translations[selectedLanguage].cart.paymentMethods}</h3>
			{cardsArr.length > 0 ? (
				cardsArr.map((profileObj, index) => {
					const isSelected = selectedCardIndex === index;

					return (
						<div key={index} className={`cart-page-select-payment-method ${isSelected ? "--selected" : ""}`}>
							<SubCreditCardDisplay cardObj={profileObj}  />
							<button
								aria-label={isSelected
									? translations[selectedLanguage].common.isSelected
									: translations[selectedLanguage].common.select}
								className="cart-page-select-button"
								onClick={() => changeSelectedCard(index)}
								id="addCard"
							>
								{isSelected
                            ? translations[selectedLanguage].common.isSelected
                            : translations[selectedLanguage].common.select}
							</button>
						</div>
					);
				})
			) : (
				<div>{translations[selectedLanguage].cart.userHasNoCreditCards}</div>
			)}

			<p className="cart-page-add-new-payment-link" onClick={goToAddPaymentProfile}>
				{translations[selectedLanguage].cart.addNewCreditCard}
			</p>
		</div>
	);
};

export default SubPaymentMethods;
// import React from "react";
// import { EXTERNAL_URLS } from "../../../constants/constants";
// import { formatString, getLang } from "../../../translations/texts";
// import { translations } from "../../../translations/texts";
// import { useSelector } from "react-redux";
// import "react-datepicker/dist/react-datepicker.css";
// import "./SubCreditCardDisplay.scss";
// import creditCardType from "credit-card-type";

// const SubCreditCardDisplay = ({ cardObj }) => {
//   const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

//   const getCardTypeStr = (number) => {
//     const cardType = creditCardType(number)[0];
//     return cardType ? cardType.type : translations[selectedLanguage].creditCardDisplay.unknownCardType;
//   };

//   getCardTypeStr(cardObj?.maskedPan)

//   const getCardTypeIconSrc = (type) => {
//     switch (type) {
//       case 'visa':
//         return EXTERNAL_URLS.VISA_CARD_IMAGE;
//       case 'mastercard':
//         return EXTERNAL_URLS.MASTER_CARD_IMAGE;
//       default:
//         return translations[selectedLanguage].creditCardDisplay.unknownCardType;
//     }
//   };

//   return (
//     <div
//       data-testid="container-credit-card-display"
//       className="credit-cart-display"
//     >
//       <div>
//         <img
//           className="credit-card-icon"
//           src={getCardTypeIconSrc(getCardTypeStr(cardObj?.maskedPan))}
//           alt={translations[selectedLanguage].common.creditCardIcon}
//         />
//       </div>
//       <div>
//         <div className="credit-card-text">
//           {formatString(
//             translations[selectedLanguage].creditCardDisplay.cardInfo,
//             getCardTypeStr(cardObj?.maskedPan),
//             cardObj?.maskedPan.slice(-4)
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SubCreditCardDisplay;
