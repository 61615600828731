import React from "react";
import { FaArrowDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getEpisodesInCategory } from "../../js/storeParsing/nomadsDbParser.js";
import { removeError, setError } from "../../store/actions/errors";
import { getStoreData } from "../../store/actions/webstore";
import CarouselContainerStorePage from "./CarouselContainer/SubCarouselContainer.jsx";
import "./SmartWebStore.scss";
import SubContentAnimation from "../Common/Animation/SubContentAnimation.jsx";
import { useState } from "react";
import { useEffect } from "react";
import SubTextInput from "../Common/Form/TextInput/TextInput.jsx";
import { BiSearchAlt2 } from "react-icons/bi";
import SubDropdownInput from "../Common/Form/DropdownInput/DropdownInput.jsx";
import { SORT } from "../../constants/constants.js";
import { LANG_ABREVIATION } from "../../constants/language.js";
import { getLocaleProp } from "../../utilities/strings.js";
import { translations } from "../../translations/texts.js";
import keyWordData from "./keyWordsMockData.js"



const SmartWebStore = () => {
  const categories = useSelector((state) => state.nomadsDb.categories);
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  

  const [componentLoading, setComponentLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sortChoice, setSortChoice] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    getStoreData()
      .catch((error) => {
        dispatch(setError(error));
      })
      .finally(() => {
        setComponentLoading(false);
      });

    return () => {
      dispatch(removeError);
    };
  }, []);

  const handleUserInput = (e) => {
    setSearch(e.target.value);
  };

  const itemFilter = (obj, item) => {
    if (!obj[item]) {
      return false;
    } else if (Array.isArray(obj[item])) {
      return obj[item].some(subItem => {
        if (typeof subItem === 'string') {
          return subItem.toLowerCase().includes(search.toLowerCase());
        }
        return false;
      });
    } else {
      return obj[item]?.toLowerCase().includes(search.toLowerCase());
    }
  };

  const searchFilter = (array) => {
    if (search) {
      return array?.filter(
        (episodeObj) =>
          (itemFilter(episodeObj, "introduction") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.EN) ||
          (itemFilter(episodeObj, "introduction_de") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.DE) ||
          (itemFilter(episodeObj, "introduction_fr") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.FR) ||
          (itemFilter(episodeObj, "introduction_sp") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.SP) ||
          (itemFilter(episodeObj, "location") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.EN) ||
          (itemFilter(episodeObj, "location_de") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.DE) ||
          (itemFilter(episodeObj, "location_fr") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.FR) ||
          (itemFilter(episodeObj, "location_sp") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.SP) ||
          (itemFilter(episodeObj, "name") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.EN) ||
          (itemFilter(episodeObj, "name_de") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.DE) ||
          (itemFilter(episodeObj, "name_fr") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.FR) ||
          (itemFilter(episodeObj, "name_sp") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.SP) ||
          (itemFilter(episodeObj, "subname") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.EN) ||
          (itemFilter(episodeObj, "subname_de") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.DE) ||
          (itemFilter(episodeObj, "subname_fr") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.FR) ||
          (itemFilter(episodeObj, "subname_sp") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.SP) ||
          (itemFilter(episodeObj, "keywords") &&
            selectedLanguage.toUpperCase() === LANG_ABREVIATION.EN)
      );
    } else {
      return array;
    }
  };

  const selectSort = (e) => {
    setSortChoice(e.target.value);
  };

  const renderCarousels = () => {
    if (categories.length) {
      const categoriesCopy = Array.from(categories);

      switch (sortChoice) {
        case SORT.ALPHA_ASC: {
          categoriesCopy.sort((a, b) =>
            (a[getLocaleProp("category")] || "").localeCompare(
              b[getLocaleProp("category")] || ""
            )
          );
          break;
        }

        case SORT.ALPHA_DSC: {
          categoriesCopy.sort((a, b) =>
            (b[getLocaleProp("category")] || "").localeCompare(
              a[getLocaleProp("category")] || ""
            )
          );
          break;
        }

        case SORT.NUMBER_OF_EPISODES_DSC: {
          categoriesCopy.sort((a, b) => {
            const episodesInCategoryA = getEpisodesInCategory(a.id);
            const episodesInCategoryB = getEpisodesInCategory(b.id);

            return episodesInCategoryB.length - episodesInCategoryA.length;
          });

          break;
        }

        case SORT.NUMBER_OF_EPISODES_ASC: {
          categoriesCopy.sort((a, b) => {
            const episodesInCategoryA = getEpisodesInCategory(a.id);
            const episodesInCategoryB = getEpisodesInCategory(b.id);

            return episodesInCategoryA.length - episodesInCategoryB.length;
          });

          break;
        }
      }

      return categoriesCopy.map((categoryObj) => {
        const episodesInCategoryArr = getEpisodesInCategory(categoryObj.id);
        const episodesWithKeywords = episodesInCategoryArr.map((episode) => {
          const id = episode.id;
          if (keyWordData[id]) {
            episode = {
              ...episode,
              keywords: keyWordData[id].keywords,
            };
          }
          return episode;
        });
        const searchResult = searchFilter(episodesWithKeywords);

        if (searchResult.length) {
          return (
            <CarouselContainerStorePage
              episodesInCategoryArr={searchResult}
              categoryObj={categoryObj}
              key={categoryObj.id}
              
              searchFilter={searchFilter}
            />
          );
        }
      });
    }
  };

  if (componentLoading) {
    return <SubContentAnimation />;
  }

  return (
    <section data-testid="container-web-store" className="info-container" id="webStore">
      <div className="info-inner-container nomads-originals">
        <div className="info-header">
          <div className="info-scroll-arrow">
            <FaArrowDown />
          </div>
        </div>
        <div className="web-store-search-wrap">
          <BiSearchAlt2 className="web-store-search-icon" />
          <div className="web-store-search-input-wrap">
            {" "}
            <SubTextInput
              value={search}
              onChange={handleUserInput}
              name="search"
              placeholder={translations[selectedLanguage].common.search}
            />
          </div>
          <SubDropdownInput
            id="web-store-category-sort-input"
            name="category-sort"
            onChange={selectSort}
            value={sortChoice}
            placeholder={translations[selectedLanguage].webStore.sortCategoriesBy}
            options={[
              {
                value: SORT.ALPHA_ASC,
                label: translations[selectedLanguage].webStore.alphaAsc,
              },
              {
                value: SORT.ALPHA_DSC,
                label: translations[selectedLanguage].webStore.alphaDsc,
              },
              {
                value: SORT.NUMBER_OF_EPISODES_DSC,
                label: translations[selectedLanguage].webStore.mostEps,
              },
              {
                value: SORT.NUMBER_OF_EPISODES_ASC,
                label: translations[selectedLanguage].webStore.leastEps,
              },
            ]}
          />
        </div>
        <div className="info-content" />
        {renderCarousels()}
      </div>
    </section>
  );
};

export default SmartWebStore;
