import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes.js";
import fetchRequests from "../../requests/index";
import { removeError, setError } from "../../store/actions/errors";
import SubTextInput from "../Common/Form/TextInput/TextInput.jsx";
import SubLoadingButton from "../Common/Loading/LoadingButton/SubLoadingButton.jsx";
import "./SmartRedeemExperience.scss";
import { translations } from "../../translations/texts.js";


const SmartRedeemExperience = () => {
  const [redeemCode, setRedeemCode] = useState("");
  const [redeemCodeErrorMessage, setRedeemCodeErrorMessage] = useState("");
  const [redeemLoading, setRedeemLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const isLoggedIn = useSelector((state) => !!state.user.userData.id);

  const dispatch = useDispatch();

  const redeemCodeChangeHandler = (e) => {
    setRedeemCode(e.target.value);
  };

  const validate = () => {
    const valid = validateRedeemCode();

    if (valid) {
      setRedeemCodeErrorMessage("");
    }

    return valid;
  };

  const validateRedeemCode = () => {
    let valid = true;

    if (!redeemCode) {
      setRedeemCodeErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    }

    return valid;
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const valid = validate();

    if (valid) {
      setRedeemLoading(true);
      fetchRequests.general
        .redeem(redeemCode)
        .then(() => {
          setSuccessMessage(translations[selectedLanguage].common.successRedeem);
          setRedeemLoading(false)
        })
        .catch((error) => {
          setSuccessMessage("");
          setRedeemLoading(false)
          dispatch(setError(error));
        });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(removeError);
    };
  }, []);

  if (!isLoggedIn) {
    return <Navigate to={INTERNAL_ROUTES.USER.SIGN_IN} replace />;
  }

  return (
    <section
      data-testid="container-redeem-experience"
      className="form-container"
      id="redeem"
    >
      <div className="form-card">
        <h2>{translations[selectedLanguage].common.redeemheader}</h2>
        <form onSubmit={submitHandler}>
          <SubTextInput
            placeholder={translations[selectedLanguage].common.redeemcode}
            onChange={redeemCodeChangeHandler}
            value={redeemCode}
            id="redeem-code"
            errorMessage={redeemCodeErrorMessage}
          />
          <SubLoadingButton
            isLoading={redeemLoading}
            type="submit"
            label={translations[selectedLanguage].common.submitButton}
          />
          {successMessage ? (
            <p className="redeem-success-message">{successMessage}</p>
          ) : null}
        </form>
      </div>
    </section>
  );
};

export default SmartRedeemExperience;
