import { LANG_ABREVIATION } from "../constants/language";
import { en } from "./lang.en";
import { fr } from "./lang.fr";

export const translations = { en, fr };

export const formatString = (stringToReplace, ...args) => {
  let string;

  for (let i = 0; i < args.length; i++) {
    string = (i === 0 ? stringToReplace : string).replace(`{${i}}`, args[i]);
  }

  return string;
};

export const getLang = () => {
  const lang = localStorage.getItem("lang") || LANG_ABREVIATION.EN;
  return lang.toLowerCase();
};
