import { createReducer } from "@reduxjs/toolkit";
import { actionTypes } from "../../constants/actionTypes";

const initialState = {
  userData: {},
  subscriptionAccount: {},
  paymentMethods: {
    creditCards: [],
  },
  paymentAddress: {}
};

const userReducer = createReducer(initialState, {
  [actionTypes.SIGN_IN]: (state, action) => {
    state.userData = { ...state.userData, ...action.payload };

    if (action.payload.account) {
      state.subscriptionAccount = action.payload.account;
    }
  },
  [actionTypes.UPDATE_USER]: (state, action) => {
    state.userData = { ...state.userData, ...action.payload };
  },
  [actionTypes.SIGN_OUT]: (state) => {
    state.userData = {};
  },
  [actionTypes.UPDATE_PASSWORD]: (state, action) => {
    state.password = action.payload.newPassword;
  },
  [actionTypes.GET_CREDIT_CARDS]: (state, action) => {
    state.paymentMethods = {
      creditCards: action.payload,
    };
  },
  [actionTypes.PAYMENT_PROFILE]: (state, action) => {
    state.paymentAddress = action.payload;
  },
});

export default userReducer;
