import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../../../constants/internalRoutes";
import SubTextInput from "../../../Common/Form/TextInput/TextInput.jsx";
import { translations } from "../../../../translations/texts";
import "react-datepicker/dist/react-datepicker.css";
import "./PayProfileForm1.scss";

const PayProfileForm1 = ({
  changeAddressLine1Input,
  changeAddressLine2Input,
  addressLine1Input,
  addressLine2Input,
  addressLine1ErrorMessage,
  addressLine2ErrorMessage,
}) => {
  const isLoggedIn = useSelector((state) => !!state.user.userData.id);
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  if (!isLoggedIn) {
    return <Navigate to={INTERNAL_ROUTES.HOME} replace />;
  }

  return (
    <div
      data-testid="container-pay-profile-form-1"
      className="pay-profile-form-1-wrap"
    >
      <SubTextInput
        name="addressLine1Input"
        placeholder={translations[selectedLanguage].payProfileForm1.addressLine1}
        onChange={changeAddressLine1Input}
        value={addressLine1Input}
        id="pay-profile-form-1-address-line-1"
        errorMessage={addressLine1ErrorMessage}
      />
      <SubTextInput
        name="addressLine2Input"
        placeholder={translations[selectedLanguage].payProfileForm1.addressLine2}
        onChange={changeAddressLine2Input}
        value={addressLine2Input}
        id="pay-profile-form-1-address-line-2"
        errorMessage={addressLine2ErrorMessage}
      />
    </div>
  );
};

export default PayProfileForm1;
