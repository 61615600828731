import axios from "axios";
import { getToken } from "../utilities/cookie";
import { errorHandler } from "../utilities/errors";
import { getHeader } from "../utilities/token";

const general = {
  redeem: async (giftCode, token = getToken()) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/videos/redeemgift`,
        {
          giftCode,
        },
        getHeader(token)
      )
      .catch(errorHandler);
  },

  giftRental: async (emailTo, productIds, dataKey, token = getToken()) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/videos/buysendgiftcard`,
        {
          emailTo: emailTo,
          vidSelection: productIds,
          dataKey: dataKey,
        },
        getHeader(token)
      )
      .catch(errorHandler);
  },
};

export default general;
