import axios from "axios";
import { errorHandler } from "../../utilities/errors";
import { getHeader } from "../../utilities/token";
import { getToken } from "../../utilities/cookie";

const moneris = {
  addToken: async (token) => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/dev/vaultaddtoken`,
        getHeader(token)
      )
      .catch(errorHandler);
  },

  buyRentals: async (token, data) => {
    return await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/purchase/buyrentals`,
      data,
      getHeader(token)
    ).catch(errorHandler);
  },

  addCard: async (token, data) => {
    return await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/purchase/addcard`,
      data,
      getHeader(token)
    ).catch(errorHandler);
  },

  deleteCard: async (token, data) => {
    return await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/purchase/deletecard`,
      {
        dataKey: data
      },
      getHeader(token)
    ).catch(errorHandler);
  },
  

  getUserCards: async (token = getToken()) => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/purchase/getusercards`,
        getHeader(token)
      )
      .catch(errorHandler);
  },

  purchaseWithVault: async (token) => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/dev/purchasewithvault`,
        getHeader(token)
      )
      .catch(errorHandler);
  },
};

export default moneris;
