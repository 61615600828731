export const allCountryCodes = {
  en: {
  "Afghanistan": "AF",
  "Albania": "AL",
  "Algeria": "DZ",
  "Andorra": "AD",
  "Angola": "AO",
  "Antigua and Barbuda": "AG",
  "Argentina": "AR",
  "Armenia": "AM",
  "Australia": "AU",
  "Austria": "AT",
  "Azerbaijan": "AZ",
  "Bahamas": "BS",
  "Bahrain": "BH",
  "Bangladesh": "BD",
  "Barbados": "BB",
  "Belarus": "BY",
  "Belgium": "BE",
  "Belize": "BZ",
  "Benin": "BJ",
  "Bhutan": "BT",
  "Bolivia": "BO",
  "Bosnia and Herzegovina": "BA",
  "Botswana": "BW",
  "Brazil": "BR",
  "Brunei": "BN",
  "Bulgaria": "BG",
  "Burkina Faso": "BF",
  "Burundi": "BI",
  "Cabo Verde": "CV",
  "Cambodia": "KH",
  "Cameroon": "CM",
  "Canada": "CA",
  "Central African Republic": "CF",
  "Chad": "TD",
  "Chile": "CL",
  "China": "CN",
  "Colombia": "CO",
  "Comoros": "KM",
  "Congo (Congo-Brazzaville)": "CG",
  "Costa Rica": "CR",
  "Croatia": "HR",
  "Cuba": "CU",
  "Cyprus": "CY",
  "Czechia (Czech Republic)": "CZ",
  "Denmark": "DK",
  "Djibouti": "DJ",
  "Dominica": "DM",
  "Dominican Republic": "DO",
  "Ecuador": "EC",
  "Egypt": "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  "Eritrea": "ER",
  "Estonia": "EE",
  "Eswatini": "SZ",
  "Ethiopia": "ET",
  "Fiji": "FJ",
  "Finland": "FI",
  "France": "FR",
  "Gabon": "GA",
  "Gambia": "GM",
  "Georgia": "GE",
  "Germany": "DE",
  "Ghana": "GH",
  "Greece": "GR",
  "Grenada": "GD",
  "Guatemala": "GT",
  "Guinea": "GN",
  "Guinea-Bissau": "GW",
  "Guyana": "GY",
  "Haiti": "HT",
  "Holy See": "VA",
  "Honduras": "HN",
  "Hungary": "HU",
  "Iceland": "IS",
  "India": "IN",
  "Indonesia": "ID",
  "Iran": "IR",
  "Iraq": "IQ",
  "Ireland": "IE",
  "Israel": "IL",
  "Italy": "IT",
  "Jamaica": "JM",
  "Japan": "JP",
  "Jordan": "JO",
  "Kazakhstan": "KZ",
  "Kenya": "KE",
  "Kiribati": "KI",
  "Korea, North": "KP",
  "Korea, South": "KR",
  "Kosovo": "XK",
  "Kuwait": "KW",
  "Kyrgyzstan": "KG",
  "Laos": "LA",
  "Latvia": "LV",
  "Lebanon": "LB",
  "Lesotho": "LS",
  "Liberia": "LR",
  "Libya": "LY",
  "Liechtenstein": "LI",
  "Lithuania": "LT",
  "Luxembourg": "LU",
  "Madagascar": "MG",
  "Malawi": "MW",
  "Malaysia": "MY",
  "Maldives": "MV",
  "Mali": "ML",
  "Malta": "MT",
  "Marshall Islands": "MH",
  "Mauritania": "MR",
  "Mauritius": "MU",
  "Mexico": "MX",
  "Micronesia": "FM",
  "Moldova": "MD",
  "Monaco": "MC",
  "Mongolia": "MN",
  "Montenegro": "ME",
  "Morocco": "MA",
  "Mozambique": "MZ",
  "Myanmar (formerly Burma)": "MM",
  "Namibia": "NA",
  "Nauru": "NR",
  "Nepal": "NP",
  "Netherlands": "NL",
  "New Zealand": "NZ",
  "Nicaragua": "NI",
  "Niger": "NE",
  "Nigeria": "NG",
  "North Macedonia (formerly Macedonia)": "MK",
  "Norway": "NO",
  "Oman": "OM",
  "Pakistan": "PK",
  "Palau": "PW",
  "Palestine State": "PS",
  "Panama": "PA",
  "Papua New Guinea": "PG",
  "Paraguay": "PY",
  "Peru": "PE",
  "Philippines": "PH",
  "Poland": "PL",
  "Portugal": "PT",
  "Qatar": "QA",
  "Romania": "RO",
  "Russia": "RU",
  "Rwanda": "RW",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Vincent and the Grenadines": "VC",
  "Samoa": "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA",
  "Senegal": "SN",
  "Serbia": "RS",
  "Seychelles": "SC",
  "Sierra Leone": "SL",
  "Singapore": "SG",
  "Slovakia": "SK",
  "Slovenia": "SI",
  "Solomon Islands": "SB",
  "Somalia": "SO",
  "South Africa": "ZA",
  "South Sudan": "SS",
  "Spain": "ES",
  "Sri Lanka": "LK",
  "Sudan": "SD",
  "Suriname": "SR",
  "Sweden": "SE",
  "Switzerland": "CH",
  "Syria": "SY",
  "Taiwan": "TW",
  "Tajikistan": "TJ",
  "Tanzania": "TZ",
  "Thailand": "TH",
  "Timor-Leste": "TL",
  "Togo": "TG",
  "Tonga": "TO",
  "Trinidad and Tobago": "TT",
  "Tunisia": "TN",
  "Turkey": "TR",
  "Turkmenistan": "TM",
  "Tuvalu": "TV",
  "Uganda": "UG",
  "Ukraine": "UA",
  "United Arab Emirates": "AE",
  "United Kingdom": "GB",
  "United States of America": "US",
  "Uruguay": "UY",
  "Uzbekistan": "UZ",
  "Vanuatu": "VU",
  "Venezuela": "VE",
  "Vietnam": "VN",
  "Yemen": "YE",
  "Zambia": "ZM",
  "Zimbabwe": "ZW"
  },
  fr: {
    "Afghanistan": "AF",
    "Albanie": "AL",
    "Algérie": "DZ",
    "Andorre": "AD",
    "Angola": "AO",
    "Antigua-et-Barbuda": "AG",
    "Argentine": "AR",
    "Arménie": "AM",
    "Australie": "AU",
    "Autriche": "AT",
    "Azerbaïdjan": "AZ",
    "Bahamas": "BS",
    "Bahreïn": "BH",
    "Bangladesh": "BD",
    "Barbade": "BB",
    "Bélarus": "BY",
    "Belgique": "BE",
    "Belize": "BZ",
    "Bénin": "BJ",
    "Bhoutan": "BT",
    "Bolivie": "BO",
    "Bosnie-Herzégovine": "BA",
    "Botswana": "BW",
    "Brésil": "BR",
    "Brunéi": "BN",
    "Bulgarie": "BG",
    "Burkina Faso": "BF",
    "Burundi": "BI",
    "Cap-Vert": "CV",
    "Cambodge": "KH",
    "Cameroun": "CM",
    "Canada": "CA",
    "République centrafricaine": "CF",
    "Tchad": "TD",
    "Chili": "CL",
    "Chine": "CN",
    "Colombie": "CO",
    "Comores": "KM",
    "Congo (Congo-Brazzaville)": "CG",
    "Costa Rica": "CR",
    "Croatie": "HR",
    "Cuba": "CU",
    "Chypre": "CY",
    "Tchéquie (République tchèque)": "CZ",
    "Danemark": "DK",
    "Djibouti": "DJ",
    "Dominique": "DM",
    "République dominicaine": "DO",
    "Équateur": "EC",
    "Égypte": "EG",
    "Salvador": "SV",
    "Guinée équatoriale": "GQ",
    "Érythrée": "ER",
    "Estonie": "EE",
    "Eswatini": "SZ",
    "Éthiopie": "ET",
    "Fidji": "FJ",
    "Finlande": "FI",
    "France": "FR",
    "Gabon": "GA",
    "Gambie": "GM",
    "Géorgie": "GE",
    "Allemagne": "DE",
    "Ghana": "GH",
    "Grèce": "GR",
    "Grenade": "GD",
    "Guatemala": "GT",
    "Guinée": "GN",
    "Guinée-Bissau": "GW",
    "Guyana": "GY",
    "Haïti": "HT",
    "Saint-Siège": "VA",
    "Honduras": "HN",
    "Hongrie": "HU",
    "Islande": "IS",
    "Inde": "IN",
    "Indonésie": "ID",
    "Iran": "IR",
    "Irak": "IQ",
    "Irlande": "IE",
    "Israël": "IL",
    "Italie": "IT",
    "Jamaïque": "JM",
    "Japon": "JP",
    "Jordanie": "JO",
    "Kazakhstan": "KZ",
    "Kenya": "KE",
    "Kiribati": "KI",
    "Corée du Nord": "KP",
    "Corée du Sud": "KR",
    "Kosovo": "XK",
    "Koweït": "KW",
    "Kirghizistan": "KG",
    "Laos": "LA",
    "Lettonie": "LV",
    "Liban": "LB",
    "Lesotho": "LS",
    "Libéria": "LR",
    "Libye": "LY",
    "Liechtenstein": "LI",
    "Lituanie": "LT",
    "Luxembourg": "LU",
    "Madagascar": "MG",
    "Malawi": "MW",
    "Malaisie": "MY",
    "Maldives": "MV",
    "Mali": "ML",
    "Malte": "MT",
    "Îles Marshall": "MH",
    "Mauritanie": "MR",
    "Maurice": "MU",
    "Mexique": "MX",
    "Micronésie": "FM",
    "Moldavie": "MD",
    "Monaco": "MC",
    "Mongolie": "MN",
    "Monténégro": "ME",
    "Maroc": "MA",
    "Mozambique": "MZ",
    "Myanmar (anciennement Birmanie)": "MM",
    "Namibie": "NA",
    "Nauru": "NR",
    "Népal": "NP",
    "Pays-Bas": "NL",
    "Nouvelle-Zélande": "NZ",
    "Nicaragua": "NI",
    "Niger": "NE",
    "Nigéria": "NG",
    "Macédoine du Nord (anciennement Macédoine)": "MK",
    "Norvège": "NO",
    "Oman": "OM",
    "Pakistan": "PK",
    "Palaos": "PW",
    "État de Palestine": "PS",
    "Panama": "PA",
    "Papouasie-Nouvelle-Guinée": "PG",
    "Paraguay": "PY",
    "Pérou": "PE",
    "Philippines": "PH",
    "Pologne": "PL",
    "Portugal": "PT",
    "Qatar": "QA",
    "Roumanie": "RO",
    "Russie": "RU",
    "Rwanda": "RW",
    "Saint-Kitts-et-Nevis": "KN",
    "Sainte-Lucie": "LC",
    "Saint-Vincent-et-les-Grenadines": "VC",
    "Samoa": "WS",
    "Saint-Marin": "SM",
    "Sao Tomé-et-Principe": "ST",
    "Arabie saoudite": "SA",
    "Sénégal": "SN",
    "Serbie": "RS",
    "Seychelles": "SC",
    "Sierra Leone": "SL",
    "Singapour": "SG",
    "Slovaquie": "SK",
    "Slovénie": "SI",
    "Îles Salomon": "SB",
    "Somalie": "SO",
    "Afrique du Sud": "ZA",
    "Soudan du Sud": "SS",
    "Espagne": "ES",
    "Sri Lanka": "LK",
    "Soudan": "SD",
    "Suriname": "SR",
    "Suède": "SE",
    "Suisse": "CH",
    "Syrie": "SY",
    "Taïwan": "TW",
    "Tadjikistan": "TJ",
    "Tanzanie": "TZ",
    "Thaïlande": "TH",
    "Timor-Leste": "TL",
    "Togo": "TG",
    "Tonga": "TO",
    "Trinité-et-Tobago": "TT",
    "Tunisie": "TN",
    "Turquie": "TR",
    "Turkménistan": "TM",
    "Tuvalu": "TV",
    "Ouganda": "UG",
    "Ukraine": "UA",
    "Émirats arabes unis": "AE",
    "Royaume-Uni": "GB",
    "États-Unis d'Amérique": "US",
    "Uruguay": "UY",
    "Ouzbékistan": "UZ",
    "Vanuatu": "VU",
    "Venezuela": "VE",
    "Viêt Nam": "VN",
    "Yémen": "YE",
    "Zambie": "ZM",
    "Zimbabwe": "ZW"
  }

};

