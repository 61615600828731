import { createReducer } from "@reduxjs/toolkit";
import { actionTypes } from "../../constants/actionTypes";
import { getGDPRCookie } from "../../utilities/cookie";

// Define initial state

const gdprConsent = getGDPRCookie("gdprConsent");

let window;

if (gdprConsent === "true") {
    window = false

} else {
    window = true
}

const initialState = {
    windowPopUp: window,
    cookieSettings: {
        initialCookies: false,
    }
};

export const addFunctionalCookies = () => ({ type: actionTypes.ADD_FUNCTIONAL_COOKIES });
export const removeFunctionalCookies = () => ({ type: actionTypes.REMOVE_FUNCTIONAL_COOKIES });
export const openWindow = () => ({ type: actionTypes.OPEN_WINDOW });
export const closeWindow = () => ({ type: actionTypes.CLOSE_WINDOW });

const CookiesManagerReducer = createReducer(initialState, {
    [actionTypes.ADD_FUNCTIONAL_COOKIES]: (state) => {
        state.cookieSettings.initialCookies = true;
    },
    [actionTypes.REMOVE_FUNCTIONAL_COOKIES]: (state) => {
        state.cookieSettings.initialCookies = false;
    },
    [actionTypes.OPEN_WINDOW]: (state) => {
        state.windowPopUp = true;
    },
    [actionTypes.CLOSE_WINDOW]: (state) => {
        state.windowPopUp = false;
    },
});

export default CookiesManagerReducer;
