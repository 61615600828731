import React from "react";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import {FaXTwitter} from 'react-icons/fa6';
import { EXTERNAL_URLS } from "../../constants/constants";
import "./SubSocialMedias.scss";

const SubSocialMedias = () => {
  return (
    <div
      data-testid="container-social-medias"
      className="wrap-social-media footer-container "
    >
      <div className="footer-container-inner">
        <div className="footer-parent-icon-container bottom-border">
          <div className="footer-single-icon-container">
            <a
              target="_blank"
              href={EXTERNAL_URLS.FACEBOOK}
              rel="noopener noreferrer"
            >
              <FaFacebookSquare />
            </a>
          </div>
          <div className="footer-single-icon-container">
            <a
              target="_blank"
              href={EXTERNAL_URLS.INSTAGRAM}
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
          <div className="footer-single-icon-container">
            <a
              target="_blank"
              href={EXTERNAL_URLS.TWITTER}
              rel="noopener noreferrer"
            >
              <FaXTwitter />
            </a>
          </div>
          <div className="footer-single-icon-container">
            <a
              target="_blank"
              href={EXTERNAL_URLS.LINKEDIN}
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
          </div>
          <div className="footer-single-icon-container">
            <a
              target="_blank"
              href={EXTERNAL_URLS.YOUTUBE}
              rel="noopener noreferrer"
            >
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubSocialMedias;
