import { LANG_ABREVIATION } from "../constants/language";
import { common as commonFR } from "../translations/common/common.lang.fr";
import { common as commonEN } from "../translations/common/common.lang.en";
import store  from "../store/store.js";

const lang = localStorage.getItem("lang");

const getLabel = (key) => {
  return lang === LANG_ABREVIATION.FR ? commonFR[key] : commonEN[key];
};

export const errorHandler = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response?.data?.message || error.response?.data?.msg) {
      const message =
        error.response?.data?.message || error.response?.data?.msg;

      let translatedMessage;

      if (typeof message === "string") {
        translatedMessage = message;
      } else {
        const selectedLanguage = store.getState().language.selectedLanguage;
        translatedMessage = selectedLanguage === "fr" ? message.fr : message.en;
      }

      return Promise.reject(`${getLabel("error")}: ${translatedMessage}`);
    } else {
      return Promise.reject(`${getLabel("error")}: ${error.message}`);
    }
  } else if (error.request) {
    // The request was made but no response was received
    return Promise.reject(getLabel("errorNoResponseFromServer"));
  } else {
    // Something happened in setting up the request that triggered an error
    return Promise.reject(getLabel("errorWrongRequestSetup"));
  }
};
