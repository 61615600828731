import React from "react";
import SubCompatibilities from "./Compatibilities/SubCompatibilities";
import SubFAQ from "./FAQ/SubFAQ";
import SubPresentation from "./Presentation/SubPresentation";
import SubSliders from "./Sliders/SubSliders";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./SmartHomepage.scss";


const SmartHomepage = () => {
  return (
    <section data-testid="container-homepage">
      <div className="homepage-content-container">
        <div className="homepage-big-card">
          <SubPresentation  />
        </div>
      </div>
      <div className="home-info-wrapper">
        <SubCompatibilities  />
        <SubSliders  />
      </div>
      <SubFAQ  />
    </section>
  );
};

export default SmartHomepage;
